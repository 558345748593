import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { Card } from "components/common";
import ScatterPlot from "components/common/Charts/ScatterPlot/Scatterplot";
import SampleFilter from "components/common/Charts/SampleFilter";
import DownloadCSVButton from "components/common/DownloadCSVButton/DownloadCSVButton";

const VJRecombination = ({
  sampleData,
  visualization_id,
  description,
  setIsLoading,
}) => {
  const [data, setData] = useState([]);
  const [activeSample, setActiveSample] = useState({});

  useEffect(() => {
    if (!!activeSample) {
      setData(activeSample.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSample]);

  const generateHighchartsConfig = (vjData) => {
    const Xcategories = [
      ...new Set(
        vjData
          .sort((a, b) => {
            return a.v_id - b.v_id;
          })
          .map((m) => m.v_call)
      ),
    ];
    const Ycategories = [
      ...new Set(
        vjData
          .sort((a, b) => {
            return a.j_id - b.j_id;
          })
          .map((m) => m.j_call)
      ),
    ];
    const hcConfig = {
      title: "V-J Recombination",
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: "J Gene",
        },
        labels: {
          format: "{value}",
        },
        min: 0,
        max: Ycategories.length - 1,
        categories: Ycategories,
      },
      xAxis: {
        title: {
          text: "V Gene",
        },
        labels: {
          format: "{value}",
        },
        categories: Xcategories,
      },
      plotOptions: {
        bubble: {
          tooltip: {
            headerFormat: "<b>{point.z}</b><br>",
            pointFormat:
              "<b>{point.z}</b><br><b>V gene</b>: {point.v_call}<br> <b>J Gene</b>: {point.j_call}",
          },
        },
      },
      series: [
        {
          maxSize: "10%",
          data: vjData.map((d) => {
            return {
              x: parseInt(d.v_id),
              y: parseInt(d.j_id),
              z: parseInt(d.size),
              v_call: d.v_call,
              j_call: d.j_call,
            };
          }),
        },
      ],
    };
    return hcConfig;
  };

  return (
    <Row>
      <Col>
        <Card
          title="V-J Recombination"
          description={description}
          actions={
            !!data &&
            !!data.length && (
              <DownloadCSVButton
                data={data}
                filename={`${visualization_id}_vjrecombination_${activeSample["sample_id"]}_${activeSample["lane"]}_${activeSample["experiment_id"]}`}
              />
            )
          }
        >
          <SampleFilter setSample={setActiveSample} samples={sampleData} />

          <Row>
            <Col>
              {!!data && (
                <ScatterPlot
                  config={generateHighchartsConfig(data)}
                ></ScatterPlot>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default VJRecombination;
