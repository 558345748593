import { useMemo } from "react";

import useApi from "./useApi";

export default function useAthenaApi() {
  const api = useApi();
  return useMemo(
    () => ({
      get: (queryExecutionId) => {
        return api.call({
          method: "GET",
          url: `athena/${queryExecutionId}/`,
        });
      },
      post: (condition = "and", filters = {}, limit = null) =>
        api.call({
          method: "POST",
          url: `athena/`,
          data: { condition, filters: JSON.stringify(filters), limit },
        }),
    }),
    [api]
  );
}
