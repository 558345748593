import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { Form, InputGroup } from "react-bootstrap";
import { GrSearch } from "react-icons/gr";

export default function TableFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup>
      <InputGroup.Text>
        <GrSearch />
      </InputGroup.Text>
      <Form.Control
        type="search"
        placeholder="Enter to search"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      ></Form.Control>
    </InputGroup>
  );
}
