import { OverlayTrigger, Tooltip as BsTooltip } from "react-bootstrap";

export default function Tooltips({ children, title }) {
  return (
    <OverlayTrigger overlay={<BsTooltip>{title}</BsTooltip>}>
      {({ ref, ...triggerHandler }) => (
        <span {...triggerHandler} ref={ref}>
          {children}
        </span>
      )}
    </OverlayTrigger>
  );
}
