import { useAsyncDebounce } from "react-table";
import { Form, InputGroup } from "react-bootstrap";
import { GrSearch } from "react-icons/gr";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import { PIPELINES } from "config";
import { useUserApi } from "apis";
import { useIsMounted } from "hooks";

const TableFilters = ({ filters, setFilters, setErrors }) => {
  const isMounted = useIsMounted();
  const userApi = useUserApi();
  const [search, setSearch] = useState(filters["identifier_contains"]);
  const [users, setUsers] = useState([]);

  const onChange = useAsyncDebounce((key, value) => {
    const newFilters = { ...filters };
    value ? (newFilters[key] = value) : delete newFilters[key];
    setFilters({ ...newFilters });
  }, 500);

  const resetFilters = () => {
    setFilters({});
    setSearch(null);
  };

  useEffect(() => {
    userApi
      .list()
      .then((response) => isMounted() && setUsers(response.data))
      .catch((error) => isMounted() && setErrors(error.response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pipelineOptions = Object.keys(PIPELINES).map((pipeline) => {
    return (
      <option key={pipeline} value={pipeline}>
        {PIPELINES[pipeline].config.displayName}
      </option>
    );
  });

  const statusOptions = [
    "CREATED",
    "STARTING",
    "STARTED",
    "SUBMITTED",
    "RUNNING",
    "ERROR",
    "FAILED",
    "COMPLETED",
  ].map((status) => {
    return (
      <option key={status} value={status}>
        {status}
      </option>
    );
  });

  return (
    <Row className="mb-3">
      <Col xs="12" xxl="6" className="mb-3 mb-xxl-0">
        <InputGroup>
          <InputGroup.Text>
            <GrSearch />
          </InputGroup.Text>
          <Form.Control
            type="search"
            placeholder="Search experiment"
            value={search || ""}
            onChange={(e) => {
              setSearch(e.target.value);
              onChange("identifier_contains", e.target.value);
            }}
          ></Form.Control>
        </InputGroup>
      </Col>

      <Col>
        <Form.Select
          value={filters.user || ""}
          onChange={(e) => onChange("user", e.target.value)}
          className="filter-user"
        >
          {!filters.user && (
            <option key="" value="">
              User
            </option>
          )}

          {users.map((user) => (
            <option key={user.email} value={user.email}>
              {user.email}
            </option>
          ))}
        </Form.Select>
      </Col>

      <Col>
        <Form.Select
          value={filters.pipeline || ""}
          onChange={(e) => onChange("pipeline", e.target.value)}
          className="filter-pipeline"
        >
          {!filters.pipeline && (
            <option key="" value="">
              Pipeline
            </option>
          )}

          {pipelineOptions}
        </Form.Select>
      </Col>

      <Col>
        <Form.Select
          value={filters.status_pipeline || ""}
          onChange={(e) => onChange("status_pipeline", e.target.value)}
          className="filter-status-pipeline"
        >
          {!filters.status_pipeline && (
            <option key="" value="">
              Status
            </option>
          )}

          {statusOptions}
        </Form.Select>
      </Col>

      <Col xs="auto">
        <Button
          onClick={resetFilters}
          variant="outline-secondary"
          className="d-flex align-items-center reset-filters-btn"
        >
          Reset Filters
        </Button>
      </Col>
    </Row>
  );
};

export default TableFilters;
