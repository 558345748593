import { Button, Modal } from "react-bootstrap";
import { GrStatusCritical } from "react-icons/gr";
import ErrorMessages from "../ErrorMessages";

import "./ErrorModal.scss";

const ErrorModal = ({ title = null, size = null, onClose, errors }) => {
  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      className="error-modal"
      size={size}
      scrollable
    >
      <Modal.Header className="justify-content-center bg-danger">
        <Modal.Title>
          <GrStatusCritical stroke="white" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="text-center mt-2 mb-3">Oops!</h6>
        <p className="text-center">
          {title ? title : "Following errors occured:"}
        </p>
        <ErrorMessages errors={errors} position="center"></ErrorMessages>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} variant="danger">
          Dismiss
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
