import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

import { useUserApi } from "apis";
import { sidebarCollapsedContext, UserContext } from "contexts";
import User from "models/User";
import Experiments from "components/Experiments";
import Pipelines from "components/Pipelines";
import Samples from "components/Samples";
import Experiment from "components/Experiment";
import SampleBrowser from "components/SampleBrowser";
import AppLoader from "components/AppLoader";
import LogoutComponent from "components/logout";
import ErrorPage from "components/ErrorPage";
import Visualization from "components/Visualization";
import Visualizations from "components/Visualizations/Visualizations";
import SequenceBrowser from "components/SequenceBrowser";
import Genedata from "components/Genedata";

function App() {
  const { accounts } = useMsal();
  const userApi = useUserApi();
  const history = createBrowserHistory();
  const [user, setUser] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  const [loginFailed, setLoginFailed] = useState(false);

  if (path) {
    history.replace(path);
  }

  useEffect(() => {
    if (accounts.length) {
      let firstName = null;
      let lastName = null;
      let avatar = null;

      userApi
        .getMetaData()
        .then((userMetaDataresponse) => {
          firstName = userMetaDataresponse.data.givenName;
          lastName = userMetaDataresponse.data.surname;
        })
        .catch(() => {
          try {
            [firstName, lastName] = accounts[0].name.split(" ");
          } catch {}
        })
        .then(userApi.getProfilePicture)
        .then((profilePictureResponse) => {
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(profilePictureResponse.data);
          avatar = blobUrl;
        })
        // Ignore profile picture error responses
        .catch(() => {})
        .then(userApi.get)
        .then((response) => {
          const userInfo = response.data;
          const user = new User({
            username: userInfo.email,
            email: userInfo.email,
            isAdmin: userInfo.is_staff,
            canWrite: userInfo.can_write,
            firstName,
            lastName,
            avatar,
          });
          setUser(user);
          setIsLoggedIn(true);
        })
        .catch(() => {
          setLoginFailed(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  let appContext = <AppLoader />;

  if (loginFailed) {
    appContext = <ErrorPage />;
  } else if (isLoggedIn) {
    appContext = (
      <UserContext.Provider value={user}>
        <sidebarCollapsedContext.Provider
          value={[sidebarCollapsed, setSidebarCollapsed]}
        >
          <BrowserRouter>
            <Routes>
              <Route path="" element={<Navigate to="experiments/" />} />
              <Route path="experiments/" element={<Experiments />} />
              <Route path="experiments/:id/" element={<Experiment />} />
              <Route path="pipelines/" element={<Pipelines />} />
              <Route path="upload/" element={<Samples history={history} />} />
              <Route path="sample-browser/" element={<SampleBrowser />} />
              <Route path="visualizations/" element={<Visualizations />} />
              <Route path="visualizations/:id/">
                <Route
                  path="amino-acid-stack-columns/"
                  element={<Visualization chart="AASC" />}
                />
                <Route
                  path="repertoire-overlap/"
                  element={<Visualization chart="RO" />}
                />
                <Route
                  path="cdr3-radial-tree/"
                  element={<Visualization chart="CDR3RT" />}
                />
                <Route
                  path="cdr3-radial-network/"
                  element={<Visualization chart="CDR3RN" />}
                />
                <Route
                  path="vj-recombination/"
                  element={<Visualization chart="VJR" />}
                />
                <Route
                  path="cdrh3-overlap/"
                  element={<Visualization chart="CDRH3O" />}
                />
              </Route>
              <Route path="sequence-browser/" element={<SequenceBrowser />} />
              <Route path="genedata/" element={<Genedata />} />
              <Route path="logout/" element={<LogoutComponent />} />
              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </BrowserRouter>
        </sidebarCollapsedContext.Provider>
      </UserContext.Provider>
    );
  }

  return appContext;
}

export default App;
