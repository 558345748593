import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GrCircleInformation } from "react-icons/gr";

const FieldDescription = ({ description }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={(props) => (
        <Tooltip {...props} className="description-tooltip">
          {description}
        </Tooltip>
      )}
    >
      <div className="d-flex justify-content-center align-items-center">
        <GrCircleInformation className="ms-2" />
      </div>
    </OverlayTrigger>
  );
};

export default FieldDescription;
