import Spinner from "../common/Spinner";

import "./AppLoader.scss";

export default function AppLoader(props) {
  return (
    <div>
      <div className="loader-backdrop"></div>
      <div className="loader-container d-flex flex-column justify-content-center">
        <Spinner
          size="lg"
          className="text-center"
          message={props.message ? props.message : "Loading..."}
        />
      </div>
    </div>
  );
}
