import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as d3 from "d3";

import { useGenedataApi } from "apis";
import { getTsvData } from "apis/tsvFileService";
import MainWrapper from "components/Main";
import { useIsMounted } from "hooks";
import { Card, ErrorModal } from "components/common";
import { references_species } from "config/common";
import Table from "./table";
import Hitlists from "./Hitlists";

import "./Genedata.scss";

export default function Genedata() {
  const genedataApi = useGenedataApi();
  const isMounted = useIsMounted();

  const [errors, setErrors] = useState(null);
  const [hitlists, setHitlists] = useState([]);
  const [selectedHitlist, setSelectedHitlist] = useState("");
  const [selectedSpecies, setSelectedSpecies] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chart, setChart] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [csvUrl, setCsvUrl] = useState(null);
  const [pngUrl, setPngUrl] = useState(null);
  const [showHitlists, setShowHitlists] = useState(false);

  const pollGenedataResults = (identifier) => {
    if (!isMounted()) return;

    genedataApi
      .get(identifier)
      .then((response) => {
        if (!isMounted()) return;

        const data = response.data;
        if (data.status === "SUCCEEDED") {
          if (isMounted()) {
            setPngUrl(data.tree_table_url);
            setCsvUrl(data.table_url);
          }

          Promise.all([
            fetch(response.data.tree_url)
              .then((response) => response.text())
              .then((svg) => isMounted() && setChart(svg)),
            getTsvData(response.data.table_url).then(
              (data) => isMounted() && setTableData(data)
            ),
          ])
            .catch(
              () =>
                isMounted() &&
                setErrors({
                  error:
                    "Something went wrong, please contact your administrator.",
                })
            )
            .finally(() => isMounted() && setIsLoading(false));
        } else if (data.status === "RUNNING") {
          setTimeout(() => {
            pollGenedataResults(identifier);
          }, 5000);
        } else {
          setIsLoading(false);
          isMounted() &&
            setErrors({
              error: "Something went wrong, please contact your administrator.",
            });
        }
      })
      .catch((error) => isMounted() && setErrors(error.response.data));
  };

  const getGenedataResults = () => {
    setChart(null);
    setTableData([]);
    setPngUrl(null);
    setCsvUrl(null);
    setIsLoading(true);

    genedataApi
      .post(selectedHitlist, selectedSpecies)
      .then((response) => pollGenedataResults(response.data.identifier))
      .catch((error) => isMounted() && setErrors(error.response.data));
  };

  useEffect(() => {
    setIsLoading(true);

    genedataApi
      .getHitlist()
      .then((response) => {
        let data = [];
        try {
          data = d3.tsvParse(response.data);
        } catch (error) {
          throw new Error("Invalid hitlist file");
        }
        setHitlists(data);
      })
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainWrapper title="Genedata" contentClass="genedata" isLoading={isLoading}>
      <Card title="Genedata">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            getGenedataResults();
          }}
        >
          <Row className="align-items-center">
            <Col xs="12" xl="auto">
              <Row className="align-items-center">
                <Col>
                  <Form.Label className="mb-0">Hitlist:</Form.Label>
                  {selectedHitlist}
                </Col>
                <Col>
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowHitlists(true)}
                  >
                    Choose
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs="12" xl="auto" className="mt-2 mt-xl-0">
              <Row className="align-items-center">
                <Col xs="3" xl="auto">
                  <Form.Label className="mb-0">Reference Species:</Form.Label>
                </Col>
                <Col xs="auto">
                  <Form.Select
                    className="reference-species"
                    value={selectedSpecies}
                    onChange={(e) => setSelectedSpecies(e.target.value)}
                  >
                    <option value="">Choose species...</option>
                    {references_species.choices.map((choice) => (
                      <option key={choice.value} value={choice.value}>
                        {choice.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col className="mt-2 mt-xl-0">
              <Button
                type="submit"
                className="get-results"
                variant="outline-secondary"
                disabled={!selectedHitlist || !selectedSpecies}
              >
                Get Results
              </Button>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-end">
            <Col xs="auto">
              <Button
                variant="outline-secondary"
                className="me-3"
                onClick={() => {
                  window.open(csvUrl, "_blank");
                }}
                disabled={!csvUrl}
              >
                Download CSV
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  window.open(pngUrl, "_blank");
                }}
                disabled={!pngUrl}
              >
                Download PNG
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="12" xl="4">
              <h5 className="d-flex justify-content-center mb-4">
                Phylogenetic Tree
              </h5>
              <div
                className="chart-container"
                dangerouslySetInnerHTML={{ __html: chart }}
              />
            </Col>
            <Col xs="12" xl="8" className="table-container">
              <h5 className="table-title mb-4">Table Data</h5>
              <Table tableData={tableData}></Table>
            </Col>
          </Row>
        </Form>
      </Card>
      {!!errors && (
        <ErrorModal
          errors={errors}
          onClose={() => setErrors(null)}
        ></ErrorModal>
      )}
      {!!showHitlists && (
        <Hitlists
          hitlists={hitlists}
          onCancel={() => setShowHitlists(false)}
          onSubmit={(hitlist) => {
            setSelectedHitlist(hitlist);
            setShowHitlists(false);
          }}
        ></Hitlists>
      )}
    </MainWrapper>
  );
}
