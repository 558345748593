import { useMemo } from "react";
import { DefaultModal, Table } from "components/common";
import { getDatetimeFormatted } from "utils";

export default function Hitlists({ hitlists, onCancel, onSubmit }) {
  const columns = useMemo(() => {
    if (!hitlists.length) return [];

    const columns = hitlists.columns.map((column) => ({
      Header: column,
      accessor: column,
      style: { overflow: "visible" },
      Cell: ({ value }) => {
        if (!value) return "";

        if (["Registration Date", "Last Modified Date"].includes(column)) {
          return getDatetimeFormatted(value);
        }

        return value;
      },
    }));

    return columns;
  }, [hitlists]);

  const data = useMemo(() => {
    if (!hitlists.length) return [];

    return [...hitlists];
  }, [hitlists]);

  return (
    <DefaultModal
      dialogClassName="genedata-hitlists"
      title="Genedata Hitlists"
      onClose={onCancel}
      submitText="Close"
    >
      <Table
        size="sm"
        columns={columns}
        defaultPageSize={10}
        data={data}
        enableRowClick={true}
        onRowClick={(hitlist) => onSubmit(hitlist["ID"])}
      ></Table>
    </DefaultModal>
  );
}
