import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  GrBarChart,
  GrCatalog,
  GrDocumentUpload,
  GrFormNext,
  GrFormPrevious,
  GrLogout,
  GrTable,
  GrTest,
  GrDatabase,
  GrLink,
} from "react-icons/gr";

import { useIsMounted, useUser } from "hooks";
import { useUserApi } from "apis";
import { ErrorModal, IconButton } from "components/common";

import Avatar from "./Avatar";

import Logo from "../../media/omniab.png";
import SmallLogo from "../../media/omniab_small.png";

import "./Sidebar.scss";

const MENU_ITEMS = [
  {
    name: "Experiments",
    link: "/experiments/",
    icon: <GrTest />,
  },
  {
    name: "Pipelines",
    link: "/pipelines/",
    icon: <GrCatalog />,
  },
  {
    name: "Upload",
    link: "/upload/",
    icon: <GrDocumentUpload />,
  },
  {
    name: "Sample Browser",
    link: "/sample-browser/",
    icon: <GrTable />,
  },
  {
    name: "Visualizations",
    link: "/visualizations/",
    icon: <GrBarChart />,
  },
  {
    name: "Sequence Browser",
    link: "/sequence-browser/",
    icon: <GrDatabase />,
  },
  {
    name: "Genedata",
    link: "/genedata/",
    icon: <GrLink />,
  },
];

const MenuItem = ({ menuItem, collapsed }) => (
  <li>
    <NavLink
      to={menuItem.link}
      className="side-nav-link link-dark d-flex align-items-center"
    >
      {menuItem.icon}
      {!collapsed && <span>{menuItem.name}</span>}
    </NavLink>
  </li>
);

const Sidebar = ({ sidebarCollapsed, toggleSidebarCollapse }) => {
  const isMounted = useIsMounted();
  const menuItems = [];
  const userApi = useUserApi();
  const user = useUser();
  const [errors, setErrors] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  MENU_ITEMS.forEach((menuItem) => {
    if (sidebarCollapsed) {
      menuItems.push(
        <OverlayTrigger
          key={menuItem.link}
          placement="right"
          overlay={<Tooltip>{menuItem.name}</Tooltip>}
        >
          {({ ref, ...triggerHandler }) => (
            <span ref={ref} {...triggerHandler}>
              <MenuItem
                menuItem={menuItem}
                collapsed={sidebarCollapsed}
              ></MenuItem>
            </span>
          )}
        </OverlayTrigger>
      );
    } else {
      menuItems.push(
        <MenuItem
          key={menuItem.link}
          menuItem={menuItem}
          collapsed={sidebarCollapsed}
        ></MenuItem>
      );
    }
  });

  function handleLogout() {
    userApi.logout().catch((error) => {
      if (isMounted()) {
        setErrors(error.response.data);
        setShowErrors(true);
      }
    });
  }

  return (
    <div
      className={`sidebar-wrapper ${
        sidebarCollapsed ? "sidebar-collapsed" : ""
      }`}
    >
      <div className="sidebar-toggle">
        <button
          className="sidebar-toggle-btn"
          onClick={(e) => {
            e.preventDefault();
            toggleSidebarCollapse();
          }}
        >
          <div className="sidebar-toggle-hitbox"></div>
          <span>{sidebarCollapsed ? <GrFormNext /> : <GrFormPrevious />}</span>
        </button>
      </div>
      <NavLink to="/" className="logo-container text-center d-flex">
        <span className="logo">
          <Image fluid src={Logo} />
        </span>

        <span className="logo-small">
          <Image fluid src={SmallLogo} />
        </span>
      </NavLink>

      <div className="sidebar-main d-flex flex-column justify-content-between">
        <div className="sidebar-navigation">
          <hr className="divider" />
          <ul className="side-nav">
            <li className="side-nav-title">Navigation</li>
            {menuItems}
          </ul>
        </div>
        <div>
          <hr className="divider my-2"></hr>
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <Avatar
                user={user}
                dropdownEnabled={sidebarCollapsed}
                handleLogout={handleLogout}
              ></Avatar>
            </div>

            {!sidebarCollapsed && (
              <div className="d-flex justify-content-between w-100 align-items-center">
                <div className="small ms-3">
                  {user.firstName ? (
                    <>
                      <span className="fw-bold text-break">
                        {user.firstName}
                      </span>
                      <span className="d-block text-break">
                        {user.lastName}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="fw-bold text-break">
                        {user.email.split("@")[0]}
                      </span>
                      <span className="d-block text-break">
                        {user.email.substr(
                          user.email.indexOf("@"),
                          user.email.length
                        )}
                      </span>
                    </>
                  )}
                </div>
                <IconButton
                  title="Log Out"
                  icon={<GrLogout />}
                  onClick={handleLogout}
                ></IconButton>
              </div>
            )}
          </div>
        </div>
      </div>

      {showErrors && (
        <ErrorModal
          errors={errors}
          onClose={() => {
            setErrors(null);
            setShowErrors(false);
          }}
        />
      )}
    </div>
  );
};

export default Sidebar;
