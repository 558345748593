import { useMemo } from "react";
import useApi from "./useApi";

const useStorageApi = () => {
  const api = useApi();

  return useMemo(
    () => ({
      list: (type, path, recursive = false) => {
        let uri = `storage/${type}/`;

        if (path) {
          uri = `${uri}${path}/`;
        }
        if (recursive) {
          uri = `${uri}?recursive=true`;
        }

        return api.call({ method: "get", url: uri, data: {} });
      },
      createFolder: ({ type, path, filename }) => {
        const data = {
          type,
          path,
          filename,
          size: 0,
        };
        return api.call({
          method: "post",
          url: `storage/${type}/`,
          data: data,
        });
      },
      delete: (type, path, filename) => {
        return api.call({
          method: "delete",
          url: `storage/${type}/${path}${filename}/`,
          data: {},
        });
      },
      deleteAll: (type, objects) => {
        const all_requests = objects.map((object) => {
          return {
            method: "delete",
            url: `storage/${type}/${object.path}${object.filename}/`,
            data: {},
          };
        });
        return api.callMultiple(all_requests);
      },
      startUpload: ({ type, path, filename, size }) => {
        const data = {
          path,
          filename,
          size,
        };
        return api.call({
          method: "post",
          url: `storage/${type}/`,
          data: data,
        });
      },
      completeMultiPartUpload: (type, path, filename, uploadId) => {
        const data = {
          upload_id: uploadId,
        };
        return api.call({
          method: "patch",
          url: `storage/${type}/${path}${filename}/`,
          data: data,
        });
      },
      uploadByUrl: ({ type, path, filename, url }) => {
        const data = {
          path,
          filename,
          url,
        };
        return api.call({
          method: "post",
          url: `storage/${type}/`,
          data: data,
        });
      },
      getDownloadUrl: ({ type, path, filename }) => {
        return api.call({
          method: "get",
          url: `storage/${type}/${path}${filename}/?download=true`,
        });
      },
      getPreviewUrl: ({ type, path, filename }) => {
        return api.call({
          method: "get",
          url: `storage/${type}/${path}${filename}/`,
        });
      },
    }),
    [api]
  );
};

export default useStorageApi;
