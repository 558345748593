import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GrRefresh } from "react-icons/gr";

import { useExperimentApi } from "apis";
import { useIsMounted, useUser } from "hooks";
import {
  IconButton,
  Card,
  Loader,
  NewExperimentModal,
  ErrorModal,
  DeleteArchiveExperiment,
} from "components/common";
import MainWrapper from "components/Main";
import ExperimentsTable from "./ExperimentsTable";
import TableFilters from "./TableFilters";

import "./Experiments.scss";

const Experiments = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const experimentApi = useExperimentApi();
  const user = useUser();
  const tableRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [experiments, setExperiments] = useState([]);
  const [selectedExperiment, setSelectedExperiment] = useState(null);
  const [showDeleteExperiment, setShowDeleteExperiment] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [showNewExperimentModal, setShowNewExperimentModal] = useState(false);
  const [headerActions, setHeaderActions] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filters, setFilters] = useState({});

  const getExperiments = useCallback(
    (page, pageSize, sortBy) => {
      setIsLoading(true);

      if (!page || !pageSize || !sortBy) {
        page = tableRef.current.getPage();
        pageSize = tableRef.current.getPageSize();
        sortBy = tableRef.current.getSortBy();
      }

      experimentApi
        .list(page, pageSize, filters, sortBy)
        .then((response) => {
          if (isMounted()) {
            setExperiments(response.data.results);
            setPageCount(Math.ceil(response.data.count / pageSize));
            setTotalRowCount(response.data.count);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            tableRef.current.resetPage();
          } else {
            setErrors(error.response.data);
            setShowErrors(true);
          }
        })
        .finally(() => isMounted() && setIsLoading(false));
    },
    [experimentApi, isMounted, filters]
  );

  const handleExperimentClicked = (experiment) => {
    if (
      !user.isAdmin &&
      experiment.user !== user.username &&
      experiment.status_pipeline === "CREATED"
    ) {
      return;
    }
    navigate(`/experiments/${experiment.identifier}/`);
  };

  useEffect(() => {
    if (user.canWrite) {
      setHeaderActions([
        {
          name: "New Experiment",
          icon: null,
          onClick: () => {
            setShowNewExperimentModal(true);
          },
        },
      ]);
    }
  }, [user]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getExperiments, [filters]);

  return (
    <MainWrapper
      title="Experiments"
      headerActions={headerActions}
      contentClass="experiments"
    >
      <Row>
        <Col>
          <Card
            title="Experiments"
            actions={
              <IconButton
                title="Refresh"
                icon={<GrRefresh />}
                onClick={getExperiments}
              ></IconButton>
            }
          >
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setErrors={(errors) => {
                setErrors(errors);
                setShowErrors(true);
              }}
            ></TableFilters>

            <ExperimentsTable
              data={experiments}
              onDelete={(experiment) => {
                setSelectedExperiment(experiment);
                setShowDeleteExperiment(true);
              }}
              handleExperimentClicked={handleExperimentClicked}
              getExperiments={getExperiments}
              tableRef={tableRef}
              pageCount={pageCount}
              totalRowCount={totalRowCount}
            />
            <Loader isLoading={isLoading} topSpinner />
          </Card>
        </Col>
      </Row>

      {showDeleteExperiment && (
        <DeleteArchiveExperiment
          experiment={selectedExperiment}
          onCancel={() => {
            setShowDeleteExperiment(false);
            setSelectedExperiment(null);
          }}
          onDeleted={() => {
            setShowDeleteExperiment(false);
            setSelectedExperiment(null);
            getExperiments();
          }}
        />
      )}

      {showNewExperimentModal && (
        <NewExperimentModal
          onClose={() => {
            setShowNewExperimentModal(false);
          }}
        ></NewExperimentModal>
      )}

      {showErrors && (
        <ErrorModal
          errors={errors}
          onClose={() => {
            setErrors(null);
            setShowErrors(false);
          }}
        ></ErrorModal>
      )}
    </MainWrapper>
  );
};

export default Experiments;
