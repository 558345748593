const BulkAIRRDescrShort = () => {
  return (
    <p>
      The bulk BCR (immune repertoire) sequencing pipeline is a bioinformatics
      workflow used to characterize the complementary determining region (CDR)
      of B-cell receptors (BCRs). You get insights on the quality of your data,
      overview of the clonotype contents, somatic hypermutation rates, amino
      acid properties and gene usage.
    </p>
  );
};

const BulkAIRROverview = () => {
  return (
    <div>
      <p>
        The bulk BCR (immune repertoire) sequencing pipeline is a bioinformatics
        workflow used to characterize the complementary determining region (CDR)
        of B-cell receptors (BCRs). You get insights on the quality of your
        data, overview of the clonotype contents, somatic hypermutation rates,
        amino acid properties and gene usage.
      </p>
      <p>
        The workflow processes raw data from FASTQ input files, aligns the
        sequences to the germline database and then proceeds to the immuno
        profiling of the samples. The pre-processing workflow processes the raw
        sequence data until the sequences are aligned against the germline
        reference of choice. The post-processing workflow provides a set of
        analyses and matrices to provide basic characteristics and insights on
        the immune repertoire.For both workflows the results are made available
        via two reports (html or pdf format), and the data is provided in the
        standardized AIRR tsv format to perform further downstream analyses of
        interest.
      </p>
    </div>
  );
};

const BulkAIRRDescr = () => {
  return (
    <div>
      <div>
        <p>
          The bulk BCR (immune repertoire) sequencing pipeline is a
          bioinformatics workflow used to characterize the complementary
          determining region (CDR) of B-cell receptors (BCRs). You get insights
          on the quality of your data, overview of the clonotype contents,
          somatic hypermutation rates, amino acid properties and gene usage.
        </p>
        <p>
          The workflow processes raw data from FASTQ input files, aligns the
          sequences to the germline database and then proceeds to the immuno
          profiling of the samples. The pre-processing workflow processes the
          raw sequence data until the sequences are aligned against the germline
          reference of choice. The post-processing workflow provides a set of
          analyses and matrices to provide basic characteristics and insights on
          the immune repertoire.For both workflows the results are made
          available via two reports (html or pdf format), and the data is
          provided in the standardized AIRR tsv format to perform further
          downstream analyses of interest.
        </p>
      </div>
      <div>
        <h4>Processing steps</h4>
        <ul>
          <li>
            <span>1</span>
            <div>
              <h5>Input</h5>
              <p>
                Paired-end raw FASTQ files (compressed), R1 and R2 primer
                sequences in fasta format
              </p>
            </div>
          </li>
          <li>
            <span>2</span>
            <div>
              <h5>Sequence QC</h5>
              <p>
                Quality assessment of the sequencing files and removal of
                low-quality reads
              </p>
            </div>
          </li>
          <li>
            <span>3</span>
            <div>
              <h5>Primer Masking</h5>
              <p>The PCR primers are identified and masked</p>
            </div>
          </li>
          <li>
            <span>4</span>
            <div>
              <h5>Generation of UMI consensus sequences</h5>
              <p>
                If UMIs were used, this step clusters all reads per UMI and
                builds a consensus sequence that has minimal amplification
                errors
              </p>
            </div>
          </li>
          <li>
            <span>5</span>
            <div>
              <h5>Assembly</h5>
              <p>
                For paired-end sequencing the cognate reads of the two files are
                assembled into a single sequence
              </p>
            </div>
          </li>
          <li>
            <span>6</span>
            <div>
              <h5>Duplicate Removal and Filtering</h5>
              <p>
                Collapsing of duplicate nucleotide sequences to unique sequences
                with at least 2 contributing sequences are retained
              </p>
            </div>
          </li>
          <li>
            <span>7</span>
            <div>
              <h5>Alignment</h5>
            </div>
          </li>
          <li>
            <span>8</span>
            <div>
              <h5>Clonal Assignment</h5>
              <p>Unsupervised spectral clustering approach</p>
            </div>
          </li>
          <li>
            <span>9</span>
            <div>
              <h5>Germline Reconstruction</h5>
              <p>Reconstructs the germline V(D)J sequence</p>
            </div>
          </li>
          <li>
            <span>10</span>
            <div>
              <h5>Amino Acid Properties</h5>
              <p>
                Analyzes the physicochemical properties of Ig amino acid
                sequences
              </p>
            </div>
          </li>
          <li>
            <span>11</span>
            <div>
              <h5>Lineage Reconstruction</h5>
              <p>Maximum parsimony with PHYLIP package</p>
            </div>
          </li>
          <li>
            <span>12</span>
            <div>
              <h5>Phylogenetic Analysis</h5>
              <p>Builds phylogenetic trees with IgPhyML</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { BulkAIRRDescrShort, BulkAIRRDescr, BulkAIRROverview };
