import { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import Logo from "../../media/omniab.png";
import "./Logout.scss";

const LogoutComponent = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <div className="logout-backdrop">
      <Card className="logout-container">
        <Card.Body>
          <Card.Img variant="top" src={Logo} />
          <p className="mb-0">You are now logged out!</p>
          <Button variant="primary" href="/">
            Return to Home screen
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LogoutComponent;
