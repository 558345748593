export default class User {
  username = null;
  email = null;
  firstName = null;
  lastName = null;
  isAdmin = false;
  canWrite = false;
  avatar = null;

  constructor(args) {
    Object.assign(this, args);
  }
}
