import { useMemo } from "react";
import { GrCheckmark, GrDocument, GrFolder } from "react-icons/gr";

import { Table } from "components/common";

export default function AddGenedataHitlistsTable({
  data,
  setSelectedRows,
  tableRef,
  onNavigate,
  isAdded,
  showHitlists,
}) {
  const columns = useMemo(
    () => {
      if (!showHitlists) {
        return [
          {
            Header: "Project ID",
            accessor: "project_id",
            id: "project_id",
            Cell: ({ row }) => (
              <>
                <GrFolder className="me-2" />
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    onNavigate(row.original.project_id);
                  }}
                >
                  {row.original.project_id}
                </a>
              </>
            ),
          },
          {
            Header: "Project Name",
            accessor: "project_name",
            id: "project_name",
          },
        ];
      } else {
        return [
          {
            Header: "Hitlist ID",
            accessor: "hitlist_id",
            Cell: ({ row }) => (
              <span>
                <GrDocument className="me-2" />
                {row.original.hitlist_id}
              </span>
            ),
          },
          {
            Header: "Hitlist Name",
            accessor: "hitlist_name",
          },
          {
            Header: "Added",
            id: "added",
            accessor: null,
            Cell: ({ row }) => (isAdded(row.original) ? <GrCheckmark /> : null),
          },
        ];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showHitlists]
  );
  return (
    <Table
      ref={tableRef}
      columns={columns}
      defaultPageSize={10}
      data={useMemo(() => data, [data])}
      enableRowSelection={showHitlists}
      setSelectedRows={setSelectedRows}
      isSelectable={(hitlist) => !isAdded(hitlist)}
      noPagination={showHitlists}
      enableConditionalSelect={showHitlists}
      enableSelectOnClick={showHitlists}
    ></Table>
  );
}
