import queryString from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { GrRefresh, GrTrash } from "react-icons/gr";
import { BsFolderPlus } from "react-icons/bs";

import {
  Card,
  ErrorModal,
  IconButton,
  Loader,
  BreadCrumbs,
} from "components/common";
import MainWrapper from "components/Main";
import SamplesTable from "./SamplesTable";
import { useStorageApi } from "apis";
import CreateFolder from "./CreateFolder";
import { useIsMounted, useUser } from "hooks";
import ConfirmDelete from "./ConfirmDelete";
import Upload from "./Upload";
import UploadByUrl from "./UploadByUrl";

import "./Samples.scss";

const Samples = ({ history }) => {
  const tableRef = useRef(null);
  const isMounted = useIsMounted();
  const user = useUser();
  const storageApi = useStorageApi();
  const [curPath, setCurPath] = useState("");
  const [items, setItems] = useState([]);
  const [headerActions, setHeaderActions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState(null);
  const [showUploadByUrl, setShowUploadByUrl] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  const { search } = useLocation();
  let { path } = queryString.parse(search);

  const getItems = useCallback(() => {
    setIsLoading(true);
    storageApi
      .list("input", curPath)
      .then((response) => isMounted() && setItems(response.data))
      .catch((error) => isMounted() && setAndShowErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
  }, [curPath, isMounted, storageApi]);

  const handleNavigate = (path) => {
    history.push({
      pathName: "/upload/",
      search: path ? `?path=${path}` : "",
    });
    setCurPath(path);
  };

  const setAndShowErrors = (errors) => {
    setErrors(errors);
    setShowErrors(true);
  };

  useEffect(getItems, [isMounted, curPath, getItems]);
  useEffect(() => {
    setCurPath(path || "");
  }, [path]);

  const deleteSelectedItems = () => {
    setIsDeleting(true);
    setDeleteErrors(null);
    storageApi
      .deleteAll("input", selectedItems)
      .then(() => {
        tableRef.current.resetSelection();
        setShowConfirmDelete(false);
      })
      .catch((error) => setDeleteErrors(error.response.data))
      .finally(() => {
        getItems();
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    user.canWrite &&
      setHeaderActions([
        {
          name: "Upload by URL",
          icon: "",
          onClick: () => {
            setShowUploadByUrl(true);
          },
        },
        {
          name: "Upload",
          component: (
            <Upload
              curPath={curPath}
              existingFiles={items}
              getItems={getItems}
              type="input"
              variant="light"
            />
          ),
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPath, items]);

  return (
    <MainWrapper
      title="Sample Upload"
      headerActions={headerActions}
      contentClass="samples"
    >
      <Card
        title={
          <BreadCrumbs
            path={curPath}
            onCrumbClicked={handleNavigate}
          ></BreadCrumbs>
        }
        actions={
          <>
            {user.canWrite && (
              <IconButton
                title="Create Folder"
                className="add-folder-btn"
                icon={<BsFolderPlus />}
                onClick={() => {
                  setShowCreateFolder(true);
                }}
              ></IconButton>
            )}
            {user.isAdmin && !!selectedItems.length && (
              <IconButton
                title="Delete"
                icon={<GrTrash />}
                notification={selectedItems.length}
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              ></IconButton>
            )}
            <IconButton
              title="Refresh"
              icon={<GrRefresh />}
              onClick={getItems}
            ></IconButton>
          </>
        }
      >
        <SamplesTable
          tableRef={tableRef}
          data={items}
          onNavigate={handleNavigate}
          setSelectedRows={(changedSelection) =>
            changedSelection.length !== selectedItems.length &&
            setSelectedItems(changedSelection)
          }
          setErrors={setShowErrors}
        />
        <Loader isLoading={isLoading} topSpinner />
      </Card>

      {showCreateFolder && (
        <CreateFolder
          curPath={curPath}
          onCancel={() => {
            setShowCreateFolder(false);
          }}
          onCreated={() => {
            setShowCreateFolder(false);
            getItems();
          }}
        ></CreateFolder>
      )}

      {showConfirmDelete && (
        <ConfirmDelete
          items={selectedItems}
          isDeleting={isDeleting}
          onCancel={() => {
            setShowConfirmDelete(false);
          }}
          onConfirm={() => {
            deleteSelectedItems();
          }}
          errors={deleteErrors}
        ></ConfirmDelete>
      )}

      {showUploadByUrl && (
        <UploadByUrl
          type="input"
          curPath={curPath}
          getItems={getItems}
          onClose={() => {
            setShowUploadByUrl(false);
          }}
        ></UploadByUrl>
      )}

      {showErrors && (
        <ErrorModal
          errors={errors}
          onClose={() => {
            setErrors(null);
            setShowErrors(false);
          }}
        ></ErrorModal>
      )}
    </MainWrapper>
  );
};

export default Samples;
