import { Modal, Button, ListGroup } from "react-bootstrap";

import { ErrorMessages, ButtonWithSpinner } from "../common";

const ConfirmDelete = ({ items, isDeleting, onCancel, onConfirm, errors }) => {
  return (
    <Modal
      show
      animation={false}
      onHide={onCancel}
      backdrop="static"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete the following items?</p>

        <ListGroup variant="flush">
          {items.map((object) => (
            <ListGroup.Item key={object.filename}>
              {object.filename}
            </ListGroup.Item>
          ))}
        </ListGroup>

        <ErrorMessages errors={errors} className="mt-3"></ErrorMessages>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={onCancel} disabled={isDeleting}>
          Cancel
        </Button>
        <ButtonWithSpinner
          type="submit"
          block={false}
          onClick={(e) => {
            e.preventDefault();
            onConfirm();
          }}
          isLoading={isDeleting}
        >
          Confirm
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDelete;
