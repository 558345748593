import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RemovableBadge, Tooltip } from "components/common";
import { DropdownList } from "react-widgets";

const FILTER_FIELDS = [
  { key: "sequence", label: "Sequence", uppercase: true },
  { key: "cdr3_aa", label: "CDR3 AA", uppercase: true },
  { key: "sample_id", label: "Sample ID", uppercase: false },
  { key: "experiment_id", label: "Experiment ID", uppercase: false },
];

const IN_FILTERS = ["sequence", "cdr3_aa"];

const TableFilters = ({
  samples,
  getSequences,
  exportToCsv,
  resetSequences,
  curFilters,
  setCurFilters,
}) => {
  const [selectedFilterKey, setSelectedFilterKey] = useState("");
  const [selectedFilterValue, setSelectedFilterValue] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("equals");
  const [filterBadges, setFilterBadges] = useState([]);
  const [curInputField, setCurInputField] = useState(null);

  const reset = () => {
    setCurFilters([]);
    setFilterBadges([]);
    resetSequences();
  };

  const resetSelectedFilter = () => {
    setSelectedFilterKey("");
    setSelectedFilterValue("");
    setSelectedOperator("equals");
  };

  const addFilter = () => {
    if (!selectedFilterKey || !selectedFilterValue || !selectedOperator) {
      return;
    }

    setCurFilters([
      ...curFilters,
      {
        id: crypto.randomUUID(),
        key: selectedFilterKey,
        operator: selectedOperator,
        value: selectedFilterValue,
      },
    ]);

    resetSelectedFilter();
  };

  const removeFilter = useCallback(
    (id) => {
      const newFilters = curFilters.filter((filter) => filter.id !== id);

      if (newFilters.length) {
        setCurFilters(newFilters);
      } else {
        resetSequences();
        setCurFilters([]);
      }
    },
    [curFilters, resetSequences, setCurFilters]
  );

  useEffect(
    () =>
      setFilterBadges(
        curFilters.map(({ id, key, operator, value }) => {
          const filterField = FILTER_FIELDS.find((field) => field.key === key);

          value = operator === "in" ? "given list" : value;
          operator = operator === "exclude" ? "excludes" : operator;

          return (
            <RemovableBadge
              key={id}
              className="me-2"
              onRemove={() => removeFilter(id)}
            >
              {`${filterField.label} ${operator} ${value}`}
            </RemovableBadge>
          );
        })
      ),
    [curFilters, setCurFilters, removeFilter]
  );

  useEffect(() => {
    setSelectedFilterValue("");
  }, [selectedFilterKey, selectedOperator]);

  useEffect(() => {
    if (
      ["experiment_id", "sample_id"].includes(selectedFilterKey) &&
      ["equals", "exclude"].includes(selectedOperator)
    ) {
      const values = [
        // @ts-ignore
        ...new Set(
          samples.map((sample) =>
            selectedFilterKey === "experiment_id"
              ? sample.experiment.identifier
              : `${sample.sample_id}_${sample.lane}`
          )
        ),
      ];
      setCurInputField(
        <DropdownList
          value={selectedFilterValue}
          placeholder="Enter Value"
          data={values}
          disabled={!selectedFilterKey}
          onChange={(value) => setSelectedFilterValue(value)}
        ></DropdownList>
      );
    } else {
      setCurInputField(
        <Form.Control
          disabled={!selectedFilterKey}
          placeholder="Enter Value"
          value={selectedFilterValue}
          onChange={(e) => {
            const filter = FILTER_FIELDS.find(
              (field) => field.key === selectedFilterKey
            );
            setSelectedFilterValue(
              filter.uppercase ? e.target.value.toUpperCase() : e.target.value
            );
          }}
        ></Form.Control>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterKey, selectedOperator, selectedFilterValue]);

  return (
    <div className="table-filters">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          addFilter();
        }}
      >
        <Row>
          <Col xs="auto">
            <Form.Select
              value={selectedFilterKey}
              className="select-field"
              onChange={(e) => setSelectedFilterKey(e.target.value)}
            >
              {!selectedFilterKey && (
                <option key="" value="">
                  Select field...
                </option>
              )}

              {FILTER_FIELDS.map((field) => (
                <option key={field.key} value={field.key}>
                  {field.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Form.Select
              disabled={!selectedFilterKey}
              value={selectedOperator}
              className="select-operator"
              onChange={(e) => setSelectedOperator(e.target.value)}
            >
              <option key="equals" value="equals">
                Equals
              </option>
              <option key="contains" value="contains">
                Contains
              </option>
              <option key="exclude" value="exclude">
                Excludes
              </option>

              {IN_FILTERS.includes(selectedFilterKey) && (
                <option key="in" value="in">
                  In
                </option>
              )}
            </Form.Select>
          </Col>
          <Col className="pe-0">{curInputField}</Col>
          <Col xs="auto">
            <Button
              type="submit"
              variant="outline-secondary"
              className="add-filter-btn"
              onClick={addFilter}
            >
              Add Filter
            </Button>
          </Col>
        </Row>
      </Form>

      <Row className="mt-2 align-items-center">
        <Col>
          {!!curFilters.length && (
            <>
              {filterBadges}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  reset();
                }}
              >
                Reset Filters
              </a>
            </>
          )}
        </Col>
        <Col xs="auto" className="align-right pe-0">
          <Tooltip title="Maximum 2500 results">
            <Button
              variant="outline-secondary"
              className="live-check-btn"
              onClick={() => getSequences()}
              disabled={!curFilters.length}
            >
              Live Search
            </Button>
          </Tooltip>
        </Col>

        <Col xs="auto" className="align-right">
          <Tooltip title="Unlimited amount of results">
            <Button
              variant="outline-secondary"
              className="export-to-csv-btn"
              onClick={exportToCsv}
              disabled={!curFilters.length}
            >
              Export to CSV
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};

export default TableFilters;
