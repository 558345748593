import { useEffect, useRef, useState } from "react";

import { Card, ErrorModal, Loader } from "components/common";
import MainWrapper from "components/Main";
import SequenceDetails from "./SequenceDetails";
import SequencesTable from "./SequencesTable";
import TableFilters from "./TableFilters";
import { useIsMounted } from "hooks";
import { useAthenaApi, useVisualizationApi } from "apis";
import { convertToSequenceDict, parseFilters } from "./utils";
import MassSequenceCheck from "./MassSequenceCheck";
import FilteringCondition from "./FilteringCondition";

import "./SequenceBrowser.scss";

const SequenceBrowser = () => {
  const tableRef = useRef();
  const visualizationApi = useVisualizationApi();
  const athenaApi = useAthenaApi();
  const isMounted = useIsMounted();
  const [sequences, setSequences] = useState([]);
  const [samples, setSamples] = useState([]);
  const [errors, setErrors] = useState(null);
  const [showSequenceDetails, setShowSequenceDetails] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [curCondition, setCurCondition] = useState("and");
  const [curFilters, setCurFilters] = useState([]);
  const [showMassSequenceCheck, setShowMassSequenceCheck] = useState(false);

  const initQuery = (filters = null, limit = null) => {
    filters = filters || curFilters;
    return athenaApi
      .post(curCondition, parseFilters(filters), limit)
      .then((response) => response.data.query_execution_id);
  };

  const getSequences = (filters = null) => {
    setIsLoading(true);

    initQuery(filters, 2500)
      .then(getQueryResults)
      .then((queryResult) => convertToSequenceDict(queryResult.results))
      .then((sequences) => isMounted() && setSequences(sequences))
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
  };

  const getQueryResults = (queryExecutionId) =>
    athenaApi.get(queryExecutionId).then((response) => {
      if (["FAILED", "CANCELLED"].includes(response.data.status)) {
        return Promise.reject({
          response: {
            data: {
              error: "Something went wrong, please contact your administrator.",
            },
          },
        });
      } else if (response.data.status !== "SUCCEEDED") {
        const promise = new Promise((resolve) =>
          setTimeout(() => resolve(getQueryResults(queryExecutionId)), 1000)
        );
        return promise;
      }

      return response.data;
    });

  const exportToCsv = () => {
    setIsLoading(true);
    initQuery()
      .then(getQueryResults)
      .then((queryResult) => {
        window.open(queryResult.csv, "_blank");
      })
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    visualizationApi
      .listSamples(null, null)
      .then((response) => isMounted() && setSamples(response.data))
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainWrapper
      title="Sequence Browser"
      contentClass="sequence-browser"
      headerActions={[
        {
          name: "Mass Sequence Check",
          icon: null,
          onClick: () => setShowMassSequenceCheck(true),
          disabled: isLoading,
        },
      ]}
    >
      <Card title="Sequences">
        <FilteringCondition
          curCondition={curCondition}
          setCurCondition={setCurCondition}
        ></FilteringCondition>
        <div className="mt-4">
          <TableFilters
            samples={samples}
            getSequences={getSequences}
            exportToCsv={exportToCsv}
            resetSequences={() => setSequences([])}
            curCondition={curCondition}
            setCurCondition={setCurCondition}
            curFilters={curFilters}
            setCurFilters={setCurFilters}
          ></TableFilters>
        </div>

        <div className="mt-4">
          <SequencesTable
            tableRef={tableRef}
            data={sequences}
            onRowClick={(sequence) => {
              setShowSequenceDetails(true);
              setSelectedSequence(sequence);
            }}
          ></SequencesTable>
        </div>
        <Loader isLoading={isLoading} topSpinner></Loader>
      </Card>
      {showSequenceDetails && (
        <SequenceDetails
          sequence={selectedSequence}
          onClose={() => {
            setShowSequenceDetails(false);
            setSelectedSequence(null);
          }}
          samples={samples}
        ></SequenceDetails>
      )}
      {showMassSequenceCheck && (
        <MassSequenceCheck
          onCancel={() => setShowMassSequenceCheck(false)}
          onSubmit={(filters) => {
            setShowMassSequenceCheck(false);
            setCurFilters(filters);
            getSequences(filters);
          }}
          samples={samples}
        />
      )}
      {!!errors && (
        <ErrorModal
          errors={errors}
          onClose={() => setErrors(null)}
        ></ErrorModal>
      )}
    </MainWrapper>
  );
};

export default SequenceBrowser;
