import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { DropdownList } from "react-widgets";

export default function SampleFilter({ setSample, samples }) {
  const [sampleId, setSampleId] = useState("");
  const [fieldData, setFieldData] = useState({});

  useEffect(() => {
    const firstSample = samples[0];
    setSample(firstSample);
    const sample_id = `${firstSample.experiment_id}-${firstSample.sample_id}-${firstSample.lane}`;
    setSampleId(sample_id);
    const initfieldData = samples.map((sd) => {
      return {
        id: `${sd.experiment_id}-${sd.sample_id}-${sd.lane}`,
        label: `${sd.sample_id} - ${sd.lane} (${sd.experiment_id})`,
        sample: sd,
      };
    });
    setFieldData(initfieldData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col md="6" lg="6" xxl="3" className="mb-1">
      {!!fieldData && !!sampleId && (
        <>
          <label>
            <b>Sample Id</b>
          </label>
          <DropdownList
            data={fieldData}
            dataKey="id"
            textField="label"
            defaultValue={sampleId}
            onSelect={(e) => {
              setSample(e.sample);
            }}
          />
        </>
      )}
    </Col>
  );
}
