import { useCallback, useEffect, useRef, useState } from "react";
import { useIsMounted } from "hooks";
import { useExperimentApi, useStorageApi } from "apis";
import {
  BreadCrumbs,
  DefaultModal,
  ErrorMessages,
  Loader,
} from "components/common";
import AddFilesTable from "./Table";

import "./addOutputFiles.scss";

const EXPERIMENT_ORDERING = [{ id: "updated", desc: true }];
const EXPERIMENT_FILTERS = {
  status_pipeline: "COMPLETED",
  pipeline: "bulkAIRR,scAIRR",
  skip_downstream_analysis: false,
};

const PLATFORM_MAP = {
  bulkAIRR: "bulk",
  scAIRR: "xPloration",
};

export default function AddOutputFiles({
  title,
  addFiles,
  addedFiles,
  onFilesAdded,
  onClose,
  allowedExtensions = [],
  enableMultiSelect = true,
  curPath,
  setCurPath,
}) {
  const tableRef = useRef();
  const storageApi = useStorageApi();
  const experimentApi = useExperimentApi();
  const isMounted = useIsMounted();

  const [experiments, setExperiments] = useState([]);
  const [curItems, setCurItems] = useState([]);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showExperiments, setShowExperiments] = useState(false);

  const getItems = useCallback(() => {
    setCurItems([]);
    if (curPath) {
      setShowExperiments(false);
      setIsLoading(true);
      storageApi
        .list("output", `${curPath}/Results/`)
        .then((response) => isMounted() && setCurItems(response.data))
        .finally(() => isMounted() && setIsLoading(false));
    } else {
      setShowExperiments(true);
      setCurItems(experiments);
    }
  }, [curPath, experiments, isMounted, storageApi]);

  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();

      setIsAdding(true);

      const curExperiment = experiments.find(
        (experiment) => experiment.identifier === curPath
      );

      addFiles(filesToAdd, PLATFORM_MAP[curExperiment.pipeline])
        .catch((error) => isMounted() && setErrors(error.response.data))
        .then(onFilesAdded)
        .then(onClose)
        .finally(() => isMounted() && setIsAdding(false));
    },
    [
      addFiles,
      curPath,
      experiments,
      filesToAdd,
      isMounted,
      onClose,
      onFilesAdded,
    ]
  );

  const isAdded = (fileToCheck) =>
    addedFiles.some(
      (file) =>
        file.path === fileToCheck.path && file.filename === fileToCheck.filename
    );

  const isSelectable = (fileToCheck) => {
    if (!("path" in fileToCheck) || fileToCheck.is_folder) {
      return false;
    }

    return (
      allowedExtensions.some((ext) => fileToCheck.filename.endsWith(ext)) &&
      !isAdded(fileToCheck)
    );
  };

  const handleNavigate = (path) => {
    setCurPath(path);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getItems, [curPath, experiments]);

  useEffect(() => {
    setIsLoading(true);
    experimentApi
      .list(null, null, EXPERIMENT_FILTERS, EXPERIMENT_ORDERING)
      .then((response) => isMounted() && setExperiments(response.data))
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultModal
      dialogClassName="add-output-files-modal"
      title={title}
      isLoading={isAdding}
      onSubmit={handleAdd}
      submitDisabled={!filesToAdd.length}
      submitText={enableMultiSelect ? "Add" : "Select"}
      onClose={onClose}
      hasCancel={true}
      size="lg"
      scrollable
    >
      <div className="mb-3">
        <BreadCrumbs
          path={curPath}
          onCrumbClicked={handleNavigate}
        ></BreadCrumbs>
      </div>
      <AddFilesTable
        tableRef={tableRef}
        data={curItems}
        onNavigate={handleNavigate}
        setSelectedRows={(changedSelection) => setFilesToAdd(changedSelection)}
        isAdded={isAdded}
        isSelectable={isSelectable}
        enableMultiSelect={enableMultiSelect}
        showExperiments={showExperiments}
      ></AddFilesTable>
      <Loader isLoading={isLoading}></Loader>
      <ErrorMessages errors={errors} className="mt-4"></ErrorMessages>
    </DefaultModal>
  );
}
