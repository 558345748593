import { useState } from "react";
import { Row, Col } from "react-bootstrap";

import { PIPELINES } from "config";
import MainWrapper from "components/Main";
import { NewExperimentModal } from "components/common";
import PipelineInfoModal from "./PipelineInfoModal";
import PipelineCard from "./PipelineCard";

const Pipelines = () => {
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [showNewExperimentModal, setShowNewExperimentModal] = useState(false);
  const [showPipelineInfoModal, setShowPipelineInfoModal] = useState(false);

  return (
    <MainWrapper title="Pipelines">
      <Row sm="1" lg="2" xxl="3">
        {Object.keys(PIPELINES).map((pipeline) => (
          <Col className="mb-3" key={pipeline}>
            <PipelineCard
              pipeline={PIPELINES[pipeline].config.displayName}
              onLearnMore={() => {
                setSelectedPipeline(pipeline);
                setShowPipelineInfoModal(true);
              }}
              onStartExperiment={() => {
                setSelectedPipeline(pipeline);
                setShowNewExperimentModal(true);
              }}
            >
              {PIPELINES[pipeline].descrShort}
            </PipelineCard>
          </Col>
        ))}
      </Row>

      {showNewExperimentModal && (
        <NewExperimentModal
          defaultPipeline={selectedPipeline}
          onClose={() => setShowNewExperimentModal(false)}
        ></NewExperimentModal>
      )}

      {showPipelineInfoModal && (
        <PipelineInfoModal
          title={PIPELINES[selectedPipeline].config.displayName}
          onClose={() => {
            setShowPipelineInfoModal(false);
            setSelectedPipeline(null);
          }}
        >
          {PIPELINES[selectedPipeline].descr}
        </PipelineInfoModal>
      )}
    </MainWrapper>
  );
};

export default Pipelines;
