import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import DependencyWheel from "highcharts/modules/dependency-wheel";
import sankey from "highcharts/modules/sankey";
import { useEffect, useState } from "react";

import "../charts.scss";

exporting(Highcharts);
sankey(Highcharts);
DependencyWheel(Highcharts);

const DependencyWheelChart = ({ config }) => {
  const [options, setOptions] = useState({});

  const fetchHeatmapData = () => {
    const options = {
      title: {
        text: config.title,
      },
      accessibility: {
        point: {
          valueDescriptionFormat:
            "{index}. From {point.from} to {point.to}: {point.weight}.",
        },
      },
      series: [
        {
          keys: ["from", "to", "weight"],
          data: config.seriesData,
          type: "dependencywheel",
          name: config.title,
          tooltip: {
            nodeFormatter: function () {
              return `${this.name}: <b>${Math.round(100 * this.sum) / 100}</b>`;
            },
          },
          dataLabels: {
            color: "#333",
            style: {
              textOutline: "none",
            },
            textPath: {
              enabled: true,
              attributes: {
                dy: 5,
              },
            },
            distance: 10,
          },
          size: "95%",
        },
      ],
      credits: {
        enabled: false,
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    fetchHeatmapData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      loader={true}
      containerProps={{ className: "full-width-chart-container" }}
    />
  );
};

export default DependencyWheelChart;
