function convertToSequenceDict(data) {
  const columns = data.shift()["Data"].map((column) => column["VarCharValue"]);

  return data.map((data) => {
    const sequenceData = {};
    data["Data"].forEach((column, index) => {
      sequenceData[columns[index]] = column["VarCharValue"];
    });
    return sequenceData;
  });
}

function getIdsFromSequencePath(path) {
  const pieces = path.split("//")[1].split("/");
  const experimentId = pieces[1];
  const filename = pieces[2].slice(0, pieces[2].indexOf(".orc"));

  let sampleId, lane;
  if (filename.includes("_")) {
    sampleId = filename.slice(0, filename.lastIndexOf("_"));
    lane = filename.slice(filename.lastIndexOf("_") + 1);
  } else {
    sampleId = filename;
  }

  return {
    experimentId,
    sampleId,
    lane,
  };
}

function parseFilters(filters) {
  return filters.map((filter) => {
    if (filter.operator === "in") {
      return {
        key: `${filter.key}__in`,
        value: filter.value.split(","),
      };
    } else if (filter.operator === "equals") {
      return {
        key: filter.key,
        value: filter.value,
      };
    } else {
      return {
        key: `${filter.key}__${filter.operator}`,
        value: filter.value,
      };
    }
  });
}

export { convertToSequenceDict, getIdsFromSequencePath, parseFilters };
