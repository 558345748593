import { useEffect, useState, useRef, useCallback } from "react";
import { GrRefresh } from "react-icons/gr";

import { Card, ErrorModal, IconButton, Loader } from "components/common";
import MainWrapper from "components/Main";
import { useIsMounted, useUser } from "hooks";
import TableFilters from "./TableFilters";
import SamplesTable from "./SamplesTable";
import { useStorageApi, useVisualizationApi } from "apis";
import CreateVisualization from "./CreateVisualization";
import SelectedSamples from "./SelectedSamples";

import "./SampleBrowser.scss";

const isIdentical = (sampleA, sampleB) => {
  return (
    sampleA.sample_id === sampleB.sample_id &&
    sampleA.experiment.identifier === sampleB.experiment.identifier &&
    sampleA.lane === sampleB.lane &&
    sampleA.experiment.pipeline === sampleB.experiment.pipeline
  );
};

const SampleBrowser = () => {
  const isMounted = useIsMounted();
  const tableRef = useRef();
  const user = useUser();
  const visualizationApi = useVisualizationApi();
  const storageApi = useStorageApi();
  const [isLoading, setIsLoading] = useState(false);
  const [samples, setSamples] = useState([]);
  const [selectedSamples, setSelectedSamples] = useState([]);
  const [filters, setFilters] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [sampleCount, setSampleCount] = useState(0);
  const [errors, setErrors] = useState(null);
  const [showCreateVisualization, setShowCreateVisualization] = useState(false);

  const getSamples = useCallback(
    (page, pageSize, sortBy) => {
      setIsLoading(true);

      if (!page || !pageSize || !sortBy) {
        page = tableRef.current.getPage();
        pageSize = tableRef.current.getPageSize();
        sortBy = tableRef.current.getSortBy();
      }

      visualizationApi
        .listSamples(page, pageSize, filters, sortBy)
        .then((response) => {
          if (isMounted()) {
            setSamples(response.data.results);
            setPageCount(Math.ceil(response.data.count / pageSize));
            setSampleCount(response.data.count);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            tableRef.current.resetPage();
          } else {
            setErrors(error.response.data);
          }
        })
        .finally(() => isMounted() && setIsLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const handleRemoveSelectedSample = (sample) => {
    setSelectedSamples((previousSamples) =>
      previousSamples.filter(
        (selectedSample) => !isIdentical(sample, selectedSample)
      )
    );
  };

  const handleToggleRowSelected = (row, selected) => {
    if (selected && !isSampleSelected(row.original)) {
      setSelectedSamples((previousSamples) => [
        ...previousSamples,
        row.original,
      ]);
    } else if (!selected) {
      handleRemoveSelectedSample(row.original);
    }
    row.toggleRowSelected(selected);
  };

  const isSampleSelected = (sampleToCheck) => {
    return !!selectedSamples.find((selectedSample) =>
      isIdentical(selectedSample, sampleToCheck)
    );
  };

  const handleDownload = (sample) => {
    let filename;

    if (sample.lane) {
      filename = `${sample.sample_id}_${sample.lane}.tsv`;
    } else {
      filename = `${sample.sample_id}.tsv`;
    }
    storageApi
      .getDownloadUrl({
        type: "output",
        path: `${sample.experiment.identifier}/Results/`,
        filename: filename,
      })
      .then((response) => window.open(response.data.url, "_blank"))
      .catch((error) => isMounted() && setErrors(error.response.data));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getSamples, [filters]);

  return (
    <MainWrapper title="Sample Browser" contentClass="sample-browser">
      <Card
        title="Samples"
        actions={
          <IconButton
            title="Refresh"
            icon={<GrRefresh />}
            onClick={getSamples}
          ></IconButton>
        }
      >
        <div>
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            setErrors={setErrors}
          ></TableFilters>
        </div>

        {!!selectedSamples.length && (
          <div className="mt-4">
            <h6>Selected Samples</h6>
            <SelectedSamples
              samples={selectedSamples}
              onClick={() => setShowCreateVisualization(true)}
              onClearSelection={() => setSelectedSamples([])}
              onRemoveSelectedSample={handleRemoveSelectedSample}
            ></SelectedSamples>
          </div>
        )}
        <div className="mt-4">
          <SamplesTable
            samples={samples}
            getSamples={getSamples}
            pageCount={pageCount}
            totalRowCount={sampleCount}
            tableRef={tableRef}
            isSampleSelected={isSampleSelected}
            handleToggleRowSelected={handleToggleRowSelected}
            onDownload={handleDownload}
            selectedSamples={selectedSamples}
            enableRowSelection={user.canWrite}
          ></SamplesTable>
        </div>
        <Loader isLoading={isLoading} topSpinner />
      </Card>

      {showCreateVisualization && (
        <CreateVisualization
          samples={selectedSamples}
          onClose={() => setShowCreateVisualization(false)}
        ></CreateVisualization>
      )}
      {!!errors && (
        <ErrorModal
          errors={errors}
          onClose={() => setErrors(null)}
        ></ErrorModal>
      )}
    </MainWrapper>
  );
};

export default SampleBrowser;
