import { IconButton } from "components/common";
import StatusBadge from "components/common/StatusBadge";
import SubTable from "components/common/Table/SubTable";
import { CHARTS } from "config";
import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { GrBarChart } from "react-icons/gr";
import { getDatetimeFormatted } from "utils";

export default function ChartTable({ chartGroup, onNavigate }) {
  const columns = useMemo(
    () => [
      {
        Header: "Chart Type",
        accessor: "chart_type",
        Cell: ({ value }) => value && CHARTS[value].title,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <OverlayTrigger
              placement="top"
              overlay={(props) => <Tooltip {...props}>{value}</Tooltip>}
            >
              <span>
                <StatusBadge status={value} />
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        Header: "Updated",
        accessor: "updated",
        Cell: ({ value }) => value && getDatetimeFormatted(value),
      },

      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          if (row.original.status !== "COMPLETED") {
            return null;
          }
          return (
            <>
              <IconButton
                title="View Chart"
                icon={<GrBarChart />}
                onClick={() => {
                  onNavigate(
                    `/visualizations/${chartGroup.identifier}/${
                      CHARTS[row.original.chart_type].path
                    }`
                  );
                }}
              ></IconButton>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartGroup]
  );

  return <SubTable columns={columns} data={chartGroup.charts}></SubTable>;
}
