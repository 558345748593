import { Card, Loader } from "components/common";
import Heatmap from "components/common/Charts/Heatmap/Heatmap";
import DownloadCSVButton from "components/common/DownloadCSVButton/DownloadCSVButton";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const SharedClonotypesHeatmap = ({
  sampleData,
  visualization_id,
  description,
}) => {
  const [hcConfig, setHcConfig] = useState({});
  const [samples, setSamples] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const generateHighchartsConfig = () => {
    const config = {
      title: "Repertoire overlap",
      xAxis: {
        categories: samples,
        title: { text: "Samples" },
      },

      yAxis: {
        categories: samples,
        title: { text: "Samples" },
        reversed: true,
        labels: {
          format: "{text}",
        },
      },
      series: [
        {
          borderWidth: 2,
          borderColor: "#FFFFFF",
          nullColor: "#bebfc2",
          data: data,
          tooltip: {
            pointFormatter: function () {
              return `<b>${samples[this.y]}</b> overlap in <b>${
                samples[this.x]
              }</b>: <b>${this.value} %</b> <br> <b>${
                this.absoluteValue
              }</b> clonotypes on a total of <b>${this.total}</b> clonotypes.`;
            },
            enabled: true,
            headerFormat: "",
            nullFormat: "<b>N/A<b>",
          },
          dataLabels: {
            formatter: function () {
              if (this.point.value !== null) {
                return `${this.point.value} %`;
              }
              return "N/A";
            },
            enabled: true,
            color: "#000000",
          },
        },
      ],
    };
    setHcConfig(config);
  };

  const fetchSamplesData = (overlapSamples) => {
    const sample_ids = Object.keys(overlapSamples);
    setSamples(sample_ids);
    const overlapData = [];
    for (let xSample of sample_ids) {
      for (let ySample of sample_ids) {
        if (ySample === xSample) {
          overlapData.push({
            ySample,
            xSample,
            y: sample_ids.indexOf(ySample),
            x: sample_ids.indexOf(xSample),
            value: null,
            total: null,
            absoluteValue: null,
          });
        } else {
          const totalyCount = overlapSamples[ySample].length;
          const absoluteValue = overlapSamples[ySample].filter((value) =>
            overlapSamples[xSample].includes(value)
          ).length;
          overlapData.push({
            ySample,
            xSample,
            y: sample_ids.indexOf(ySample),
            x: sample_ids.indexOf(xSample),
            total: totalyCount,
            absoluteValue: absoluteValue,
            value: Math.round((absoluteValue * 10000) / totalyCount) / 100,
          });
        }
      }
    }
    setData(overlapData);
  };

  useEffect(() => {
    if (!!sampleData.length) {
      const allSamples = {};
      sampleData.forEach((sd) => {
        allSamples[sd.sample_id] = [...new Set(sd.data.map((x) => x.cdr3_aa))];
      });
      fetchSamplesData(allSamples);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      generateHighchartsConfig();
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const getCSVData = () => {
    return data.map((m) => {
      return {
        sample: m.ySample,
        overlap_sample: m.xSample,
        percentage_overlap: m.value ? m.value : 0,
        absolute_value: m.absoluteValue ? m.absoluteValue : 0,
        total_cloonotypes: m.total ? m.total : 0,
      };
    });
  };

  return (
    <Row>
      <Col lg="12" xxl="12">
        <Card
          title="Shared Clonotypes"
          description={description}
          actions={
            !!data.length && (
              <DownloadCSVButton
                data={getCSVData()}
                filename={`${visualization_id}_clonotypes_heatmap`}
              />
            )
          }
        >
          <Heatmap config={hcConfig}></Heatmap>

          <Loader isLoading={isLoading}></Loader>
        </Card>
      </Col>
    </Row>
  );
};

export default SharedClonotypesHeatmap;
