import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { GrFormDown, GrFormUp, GrSearch } from "react-icons/gr";
import { useAsyncDebounce } from "react-table";
import { Multiselect } from "react-widgets";
import DatePicker from "react-widgets/DatePicker";

import { useMetadataApi } from "apis";
import { PIPELINES } from "config";
import { useIsMounted } from "hooks";

const getUTCDate = (date, end = false) => {
  let utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getMonth(),
    date.getDate()
  );

  utcDate = new Date(utcDate);

  if (end) {
    utcDate.setUTCHours(23, 59, 59, 999);
  }

  return utcDate.toISOString();
};

const TableFilters = ({ filters, setFilters, setErrors }) => {
  const metadataApi = useMetadataApi();
  const isMounted = useIsMounted();
  const [metadataFieldValues, setMetadataFieldValues] = useState({});
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [searchedExperimentId, setSearchedExperimentId] = useState(
    filters.experiment_id
  );
  const [searchedSampleId, setSearchedSampleId] = useState(filters.sample_id);
  const [searchedAnimalId, setSearchedAnimalId] = useState(filters.animal_id);
  const [pipelineOptions, setPipelineOptions] = useState([]);

  const [completedAfter, setCompletedAfter] = useState(
    filters.completed_after ? new Date(filters.completed_after) : null
  );
  const [completedBefore, setCompletedBefore] = useState(
    filters.completed_before ? new Date(filters.completed_before) : null
  );

  const resetFilters = () => {
    setFilters({});
    setSearchedExperimentId(null);
    setSearchedSampleId(null);
    setSearchedAnimalId(null);
    setCompletedAfter(null);
    setCompletedBefore(null);
  };

  const toggleShowAllFilters = () => {
    // TODO, if set to false, remove filter values from object
    setShowMoreFilters(!showMoreFilters);
  };

  const onChange = (key, value) => {
    if (Array.isArray(value) && !value.length) {
      value = null;
    }

    const newFilters = { ...filters };
    value ? (newFilters[key] = value) : delete newFilters[key];
    setFilters({ ...newFilters });
  };
  const onChangeDebounced = useAsyncDebounce(onChange, 500);

  useEffect(() => {
    metadataApi
      .get()
      .then((response) => isMounted() && setMetadataFieldValues(response.data))
      .catch((error) => isMounted() && setErrors(error.response.data));

    setPipelineOptions(
      Object.values(PIPELINES)
        .filter((pipeline) => pipeline.name !== "integration")
        .map((pipeline) => ({
          key: pipeline.name,
          label: pipeline.config.displayName,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col>
        <Row className="row-cols-1 row-cols-lg-2 row-cols-xxl-4">
          <Col>
            <InputGroup>
              <InputGroup.Text>
                <GrSearch />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Search experiment"
                value={searchedExperimentId || ""}
                onChange={(e) => {
                  setSearchedExperimentId(e.target.value);
                  onChangeDebounced("experiment_id", e.target.value);
                }}
              ></Form.Control>
            </InputGroup>
          </Col>
          <Col className="mt-2 mt-lg-0">
            <InputGroup>
              <InputGroup.Text>
                <GrSearch />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Search sample ID"
                value={searchedSampleId || ""}
                onChange={(e) => {
                  setSearchedSampleId(e.target.value);
                  onChangeDebounced("sample_id", e.target.value);
                }}
              ></Form.Control>
            </InputGroup>
          </Col>
          <Col className="mt-2 mt-xxl-0">
            <Multiselect
              value={filters.pipeline || []}
              placeholder="Choose Pipeline"
              data={pipelineOptions}
              dataKey="key"
              textField="label"
              onChange={(values) =>
                onChange(
                  "pipeline",
                  values.map((value) => value.key)
                )
              }
            ></Multiselect>
          </Col>

          <Col className="mt-2 mt-xxl-0">
            <InputGroup>
              <InputGroup.Text>
                <GrSearch />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Search Animal ID"
                value={searchedAnimalId || ""}
                onChange={(e) => {
                  setSearchedAnimalId(e.target.value);
                  onChangeDebounced("animal_id", e.target.value);
                }}
              ></Form.Control>
            </InputGroup>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="col-12 col-lg-6 col-xxl">
            <DatePicker
              placeholder="Start Date"
              value={completedAfter}
              onChange={(changedDate) => {
                setCompletedAfter(changedDate);
                onChangeDebounced(
                  "completed_after",
                  changedDate ? getUTCDate(changedDate) : null
                );
              }}
            />
          </Col>

          <Col className="mt-2 mt-lg-0 col-12 col-lg-6 col-xxl">
            <DatePicker
              placeholder="End Date"
              value={completedBefore}
              onChange={(changedDate) => {
                setCompletedBefore(changedDate);
                onChangeDebounced(
                  "completed_before",
                  changedDate ? getUTCDate(changedDate, true) : null
                );
              }}
            />
          </Col>

          <Col className="mt-2 mt-xxl-0 col-12 col-lg-4 col-xxl">
            <Multiselect
              value={filters.target || []}
              placeholder="Choose Target"
              data={metadataFieldValues.target}
              onChange={(values) => onChange("target", values)}
            ></Multiselect>
          </Col>

          <Col className="mt-2 mt-xxl-0 col-12 col-lg-4 col-xxl">
            <Multiselect
              value={filters.genotype || []}
              placeholder="Choose Genotype"
              data={metadataFieldValues.genotype}
              onChange={(values) => onChange("genotype", values)}
            ></Multiselect>
          </Col>
          <Col className="mt-2 mt-xxl-0 col-12 col-lg-4 col-xxl">
            <Multiselect
              value={filters.animal_species || []}
              placeholder="Choose Animal Species"
              data={metadataFieldValues.animal_species}
              onChange={(values) => onChange("animal_species", values)}
            ></Multiselect>
          </Col>
        </Row>

        {showMoreFilters && (
          <Row className="mt-2 row-cols-1 row-cols-lg-3">
            <Col>
              <Multiselect
                value={filters.client || []}
                placeholder="Client"
                data={metadataFieldValues.client}
                onChange={(values) => onChange("client", values)}
              ></Multiselect>
            </Col>
            <Col className="mt-2 mt-lg-0">
              <Multiselect
                value={filters.project || []}
                placeholder="Choose Project"
                data={metadataFieldValues.project}
                onChange={(values) => onChange("project", values)}
              ></Multiselect>
            </Col>
            <Col className="mt-2 mt-lg-0">
              <Multiselect
                value={filters.ab_clone_format || []}
                placeholder="Choose Ab Clone Format"
                data={metadataFieldValues.ab_clone_format}
                onChange={(values) => onChange("ab_clone_format", values)}
              ></Multiselect>
            </Col>
          </Row>
        )}
      </Col>

      <Col className="order-first order-xxl-last mb-3 mb-xxl-0" xxl="auto">
        <Row>
          <Col>
            <Row className="justify-content-center justify-content-md-end">
              <Col xs="auto">
                <Button
                  onClick={resetFilters}
                  variant="outline-secondary"
                  className="d-flex align-items-center  reset-filters-btn"
                >
                  Reset Filters
                </Button>
              </Col>
              <Col className="d-flex d-xxl-none align-items-center" xs="auto">
                <a
                  className="text-decoration-none more-filters-btn"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleShowAllFilters();
                  }}
                >
                  <div className="d-flex align-items-center">
                    {showMoreFilters ? (
                      <GrFormUp className="me-1" />
                    ) : (
                      <GrFormDown className="me-1" />
                    )}
                    <span>More Filters</span>
                  </div>
                </a>
              </Col>
            </Row>
            <Row className="d-none d-xxl-flex">
              <Col className="d-flex align-items-center justify-content-center mt-2">
                <a
                  className="text-decoration-none more-filters-btn"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleShowAllFilters();
                  }}
                >
                  <div className="d-flex align-items-center">
                    {showMoreFilters ? (
                      <GrFormUp className="me-1" />
                    ) : (
                      <GrFormDown className="me-1" />
                    )}
                    <span>More Filters</span>
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TableFilters;
