import { PIPELINES } from "config";
import Details from "./Details";
import Metadata from "./Metadata";
import OutputFiles from "./OutputFiles";
import Params from "./Params";
import Samples from "./Samples";
import SupportingFiles from "./SupportingFiles";
import GeneDataHitlists from "./GeneDataHitlists";
import { CompactView, ExtendedView } from "./Views";

const ExperimentDetails = ({
  experiment,
  getExperiment,
  samples,
  isSamplesLoading,
  supportingFiles,
  isSupportingFilesLoading,
  setErrors,
  metadataEnabled,
}) => {
  const extendedView =
    PIPELINES[experiment.pipeline].config.extendedView.detail;
  const showSupportingFiles =
    !!PIPELINES[experiment.pipeline].config.supportingFiles.length;
  const showGeneDataHitlists = PIPELINES[experiment.pipeline].config.geneData;

  const getMetadataComponent = () => {
    return <Metadata experiment={experiment}></Metadata>;
  };

  const getParamsComponent = () => {
    return <Params experiment={experiment}></Params>;
  };

  const getSamplesComponent = () => {
    return (
      <Samples
        experiment={experiment}
        samples={samples}
        isLoading={isSamplesLoading}
      ></Samples>
    );
  };

  const getDetailsComponent = () => {
    return (
      <Details experiment={experiment} getExperiment={getExperiment}></Details>
    );
  };

  const getOutputFilesComponent = () => {
    return (
      <OutputFiles experiment={experiment} setErrors={setErrors}></OutputFiles>
    );
  };

  const getSupportingFilesComponent = () => {
    return (
      <SupportingFiles
        experiment={experiment}
        supportingFiles={supportingFiles}
        isLoading={isSupportingFilesLoading}
      ></SupportingFiles>
    );
  };

  const getGenedataHitlistComponent = () => {
    return <GeneDataHitlists experiment={experiment} />;
  };

  return (
    <>
      {extendedView ? (
        <ExtendedView
          metadataEnabled={metadataEnabled}
          Metadata={getMetadataComponent()}
          Details={getDetailsComponent()}
          Samples={getSamplesComponent()}
          OutputFiles={getOutputFilesComponent()}
          SupportingFiles={getSupportingFilesComponent()}
          Params={getParamsComponent()}
        ></ExtendedView>
      ) : (
        <CompactView
          metadataEnabled={metadataEnabled}
          Metadata={getMetadataComponent()}
          Details={getDetailsComponent()}
          Samples={getSamplesComponent()}
          OutputFiles={getOutputFilesComponent()}
          Params={getParamsComponent()}
          showSupportingFiles={showSupportingFiles}
          SupportingFiles={getSupportingFilesComponent()}
          showGeneDataHitlists={showGeneDataHitlists}
          GeneDataHitlists={getGenedataHitlistComponent()}
        ></CompactView>
      )}
    </>
  );
};

export default ExperimentDetails;
