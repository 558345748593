import { references_species, skip_downstream_analysis } from "config/common";

const scAIRRConfig = {
  displayName: "Single-Cell BCR",
  params: [
    skip_downstream_analysis(
      `If “Yes” the pipeline will run post-processing steps which includes clonal assignment,
        germline reconstruction, amino acid properties analysis, lineage reconstruction and phylogenetic analysis.
        If “No”, pipeline will only run pre-processing steps.`
    ),
    references_species,
    {
      key: "preprocessing.coord",
      label: "Format of sequence identifier",
      type: "select",
      choices: [
        { value: "sra", name: "SRA" },
        { value: "illumina", name: "Illumina" },
      ],
      defaultValue: "illumina",
      description:
        "Format of sequence identifier which defines shared coordinate information across mate pairs",
    },
    {
      key: "postprocessing.create_lineage_trees.enabled",
      label: "Create Lineage Trees",
      type: "select",
      defaultValue: false,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description: "Whether to run Phylip dnapars lineage reconstruction.",
    },
    {
      key: "postprocessing.build_trees.enabled",
      label: "Build Trees",
      type: "select",
      defaultValue: false,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description:
        "Whether the pipeline will run the IgPhyML phylogenetic tree construction.",
    },
    {
      key: "preprocessing.split_seq.max_count",
      label: "Dupcount",
      type: "range",
      min: 2,
      max: 10,
      defaultValue: 2,
      advanced: true,
      description: `Group sequences by dupcount value`,
    },
    {
      key: "postprocessing.clonal_grouping.method",
      label: "Clones Method",
      type: "select",
      advanced: true,
      defaultValue: "define_clones",
      choices: [
        { value: "define_clones", name: "Define Clones" },
        { value: "spectral_clones", name: "Spectral Clones" },
      ],
      description: `Selects define clones or spectral clones for clonal assignment.`,
    },
    {
      key: "postprocessing.repertoire_overlap.enabled",
      label: "Repertoire Overlap",
      type: "select",
      defaultValue: true,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description: "Whether to analyse the overlap between repertoires.",
    },
    {
      key: "postprocessing.add_vis.filter_cdr_1_2",
      label: "Filter CDRs",
      type: "select",
      advanced: true,
      defaultValue: true,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description: `If true (yes) CDR1, CDR2 and CDR3 columns will be filterd for NA's. If false (no)
        only the CDR3 column will be filtered. Bovine tends to have NA's for CDR1 and CDR2 which could
        reduce the number of sequences for visualizations.`,
    },
    {
      key: "filter_VH",
      label: "VH Filtering",
      type: "select",
      defaultValue: true,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description:
        "Whether to select only sequences that are not annotated as IGHV3-CH.",
    },
    {
      key: "f_ratio_cutoff",
      label: "F Ratio Cutoff",
      type: "range",
      min: 0,
      max: 100,
      defaultValue: 0,
      description:
        "Cutoff for highlighting wells according to ratio of two highest dupcounts.",
    },
    {
      key: "seq_type",
      hidden: true,
      defaultValue: "xPloration",
    },
    {
      key: "single_chain",
      hidden: true,
      defaultValue: "both",
    },
    {
      key: "receptor",
      hidden: true,
      defaultValue: "bcr",
    },
    {
      key: "preprocessing.masking_primers.maxerror",
      hidden: true,
      defaultValue: 0.2,
    },
    {
      key: "preprocessing.build_consensus.maxerror",
      hidden: true,
      defaultValue: 0.3,
    },
    {
      key: "preprocessing.datatype",
      hidden: true,
      defaultValue: "illumina_umi",
    },
  ],
  samples: {
    allowedExtensions: ["fastq.gz"],
    allowedFields: ["lane", "readtype", "sample_id", "animal_id", "genotype"],
  },
  supportingFiles: [
    {
      param: "c_primer_fasta",
      label: "C_primer",
      allowedExtension: "fasta",
      description:
        "A fasta file containing primer sequences that will match to R1 reads.",
    },
    {
      param: "v_primer_fasta",
      label: "V_primer",
      allowedExtension: "fasta",
      description:
        "A fasta file containing primer sequences that will match to R2 reads.",
    },
    {
      param: "well_layout",
      label: "Well Layout",
      allowedExtension: "csv",
      description: "A csv file with well and barcode information.",
    },
    {
      param: "plate_number",
      label: "Plate Numbers",
      allowedExtension: "csv",
      description: "A csv file with sample name and plate number information",
    },
  ],
  pipelineVersions: ["v1.1", "v1.0", "master"],
  populatesSequenceDb: true,
  extendedView: {
    detail: true,
    configure: true,
  },
};

export default scAIRRConfig;
