import { useStorageApi } from "apis";
import { DefaultModal, ErrorMessages } from "components/common";
import { useIsMounted } from "hooks";
import { useState } from "react";
import { Form } from "react-bootstrap";

const CreateFolder = ({ curPath, onCancel, onCreated }) => {
  const isMounted = useIsMounted();
  const storageApi = useStorageApi();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});

  const handleCreateFolder = (e) => {
    e.preventDefault();
    setIsLoading(true);
    storageApi
      .createFolder({
        type: "input",
        path: curPath,
        filename: `${name}/`,
      })
      .then(onCreated)
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
  };

  return (
    <DefaultModal
      title="Create Folder"
      isLoading={isLoading}
      onClose={onCancel}
      onSubmit={handleCreateFolder}
      hasCancel={true}
    >
      <Form.Group controlId="createForm.foldername">
        <Form.Label>Folder name</Form.Label>
        <Form.Control
          placeholder="Folder name"
          required
          value={name}
          name="identifier"
          onChange={(e) => {
            setName(e.target.value);
            setErrors({});
          }}
          isInvalid={!!errors.name}
        />
        {errors.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <ErrorMessages
        errors={errors}
        keysToIgnore={["name"]}
        className="mt-2 align-items-center"
        position="start"
      ></ErrorMessages>
    </DefaultModal>
  );
};

export default CreateFolder;
