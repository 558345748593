import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Highcharts from "highcharts";

import MainWrapper from "components/Main";
import { useStorageApi, useVisualizationApi } from "apis";
import { getTsvData } from "apis/tsvFileService";
import AminoAcidStackColumns from "./AminoAcidStackColumns/AminoAcidStackColumns";
import VJRecombination from "./VJRecombination/VJRecombination";
import PlotlyWrapper from "components/common/Charts/PlotlyWrapper/PlotlyWrapper";
import { useIsMounted } from "hooks";
import { ErrorModal } from "components/common";
import { sidebarCollapsedContext } from "contexts";
import SharedClonotypesHeatmap from "./SharedClonotypesHeatmap/SharedClonotypesHeatmap";
import CDRH3Overlap from "./CDRH3Overlap/CDRH3Overlap";
import { CHARTS } from "config";

const Visualization = ({ chart }) => {
  const isMounted = useIsMounted();
  const storageApi = useStorageApi();
  const visualizationsApi = useVisualizationApi();
  const [sidebarCollapsed] = useContext(sidebarCollapsedContext);
  const [sampleData, setSampleData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const { id } = useParams();

  const getSampleData = (uriObject, chartMetaData) => {
    const fileExtension = chartMetaData.tsv_suffix.split(".").at(-1);

    if (fileExtension === "tsv") {
      return storageApi
        .getDownloadUrl(uriObject)
        .then((resp) => {
          return getTsvData(resp.data.url);
        })
        .catch(() => isMounted() && setShowError(true))
        .finally(() => isMounted() && setIsLoading(false));
    } else {
      return storageApi
        .getPreviewUrl(uriObject)
        .then((resp) =>
          fetch(resp.data.url).then((resp) => {
            return resp.url;
          })
        )
        .catch(() => isMounted() && setShowError(true));
    }
  };

  const getData = (samples) => {
    const promises = samples.map((sample) => {
      let filename;
      if (sample.lane) {
        filename = `${sample.experiment}_${sample.sample_id}_${sample.lane}_${CHARTS[chart].tsv_suffix}`;
      } else {
        filename = `${sample.experiment}_${sample.sample_id}_${CHARTS[chart].tsv_suffix}`;
      }
      const uriObject = {
        type: "visualizations",
        path: `${id}/`,
        filename: filename,
      };
      return getSampleData(uriObject, CHARTS[chart]).then((response) => {
        return {
          sample_id: sample.sample_id,
          experiment_id: sample.experiment,
          lane: sample.lane,
          data: response,
          uriObject: uriObject,
        };
      });
    });

    Promise.all(promises)
      .then((results) => isMounted() && setSampleData(results))
      .catch(() => isMounted() && setShowError(true))
      .finally(() => isMounted() && setIsLoading(false));
  };

  const getChart = () => {
    switch (chart) {
      case "AASC":
        return (
          <AminoAcidStackColumns
            sampleData={sampleData}
            visualization_id={id}
            description={CHARTS[chart].description}
            setIsLoading={setIsLoading}
          ></AminoAcidStackColumns>
        );
      case "VJR":
        return (
          <VJRecombination
            sampleData={sampleData}
            visualization_id={id}
            description={CHARTS[chart].description}
            setIsLoading={setIsLoading}
          ></VJRecombination>
        );
      case "RO":
        return (
          <SharedClonotypesHeatmap
            sampleData={sampleData}
            visualization_id={id}
            description={CHARTS[chart].description}
          ></SharedClonotypesHeatmap>
        );
      case "CDRH3O":
        return (
          <CDRH3Overlap
            sampleData={sampleData}
            visualization_id={id}
            description={CHARTS[chart].description}
            setIsLoading={setIsLoading}
          ></CDRH3Overlap>
        );
      case "CDR3RT":
      case "CDR3RN":
        return (
          <PlotlyWrapper
            sampleData={sampleData}
            title={CHARTS[chart].title}
            description={CHARTS[chart].description}
            setIsLoading={setIsLoading}
          ></PlotlyWrapper>
        );
      default:
        return;
    }
  };

  useEffect(() => {
    resizeCharts();
  }, [sidebarCollapsed]);

  const resizeCharts = () => {
    for (let hc of Highcharts.charts) {
      if (!!hc) {
        hc.setSize(hc.renderTo.clientWidth, hc.renderTo.clientHeight, false);
        hc.reflow();
      }
    }
  };

  useEffect(() => {
    visualizationsApi.getChart(id).then((response) => {
      getData(response.data.samples);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainWrapper title={`${id} - ${CHARTS[chart].title}`} isLoading={isLoading}>
      {!!Object.keys(sampleData).length && getChart()}
      {showError && (
        <ErrorModal
          errors={{
            error: [
              "Something went wrong while fetching TSV files,",
              "please contact your administrator.",
            ],
          }}
          onClose={() => setShowError(false)}
        ></ErrorModal>
      )}
    </MainWrapper>
  );
};

export default Visualization;
