import { references_species, skip_downstream_analysis } from "config/common";

const config = {
  displayName: "Full Pass Low-throughput BCR",
  params: [
    skip_downstream_analysis(
      `If “Yes” the pipeline will run post-processing steps which includes clonal assignment,
        germline reconstruction, amino acid properties analysis and lineage reconstruction.
        If “No”, pipeline will only run pre-processing steps.`
    ),
    references_species,
    {
      key: "single_chain",
      label: "Chain Type",
      type: "select",
      defaultValue: "both",
      choices: [
        { value: "light", name: "Light" },
        { value: "heavy", name: "Heavy" },
        { value: "both", name: "Both" },
      ],
      description: `Whether only heavy/light chains are present or both in the data.
        When set to heavy/light/both a set of special parameters (i.e. combine average asymmetric distances or use single linkage for hierarchical clustering) is used to define clones.`,
    },
    {
      key: "assemble_maxerror",
      label: "Assemble Max Error Rate",
      type: "range",
      min: 0,
      max: 1,
      step: 0.1,
      defaultValue: 0.3,
      description: "Max error rate allowed for first assemble step.",
    },
    {
      key: "second_assemble_maxerror",
      label: "Assemble Max Error Rate",
      type: "range",
      min: 0,
      max: 1,
      step: 0.1,
      defaultValue: 0.5,
      description: "Max error rate allowed for second assemble step.",
    },
    {
      key: "preprocessing.masking_primers.maxerror",
      label: "Primer Masking Max Error Rate",
      type: "range",
      min: 0,
      max: 1,
      step: 0.1,
      defaultValue: 0.5,
      description: "Max error rate allowed for primer masking step.",
    },
    {
      key: "n_primers",
      label: "Number of Primers",
      type: "range",
      min: 1,
      max: 10,
      step: 1,
      defaultValue: 3,
      description: "Number of primers used for sanger sequencing.",
    },
    {
      key: "postprocessing.clonal_grouping.method",
      label: "Clones Method",
      type: "select",
      advanced: true,
      defaultValue: "define_clones",
      choices: [
        { value: "define_clones", name: "Define Clones" },
        { value: "spectral_clones", name: "Spectral Clones" },
      ],
      description: `Selects define clones or spectral clones for clonal assignment.`,
    },
    {
      key: "seq_type",
      hidden: true,
      defaultValue: "lt_full_pass",
    },
    {
      key: "receptor",
      hidden: true,
      defaultValue: "bcr",
    },
  ],
  samples: {
    allowedExtensions: ["ab1"],
    allowedFields: ["sample_id", "animal_id", "genotype"],
  },
  supportingFiles: [
    {
      param: "reverse_fc_fasta",
      label: "Reverse Fc",
      allowedExtension: ".fasta",
      description: "Reverse Fc file",
    },
    {
      param: "lc_fr1_fasta",
      label: "LC_FR1",
      allowedExtension: ".fasta",
      description: "LC FR1 file",
    },
  ],
  pipelineVersions: ["v1.1", "v1.0", "master"],
  populatesSequenceDb: true,
  extendedView: {
    detail: false,
    configure: false,
  },
};

export default config;
