import { Badge } from "react-bootstrap";
import { GrClose } from "react-icons/gr";

import "./RemovableBadge.scss";

const RemovableBadge = ({
  className,
  children,
  onRemove,
  bg = "primary",
  pill = false,
  text = null,
}) => {
  return (
    <Badge
      bg={bg}
      pill={pill}
      text={text}
      className={`removable-badge ${className}`}
    >
      {children}
      <GrClose onClick={onRemove}></GrClose>
    </Badge>
  );
};

export default RemovableBadge;
