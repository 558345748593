import * as d3 from "d3";

export async function getTsvData(uri) {
  let validColumns = [];
  return d3
    .tsv(uri, (d, idx, columns) => {
      if (validColumns.length === 0) {
        validColumns = columns.filter(Boolean);
      }
      const newRow = {};
      for (let vc of validColumns) {
        newRow[vc] = d[vc];
      }
      return newRow;
    })
    .then((response) => {
      response.columns = validColumns;
      return response;
    });
}
