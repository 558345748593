import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { GrFormDown, GrFormUp } from "react-icons/gr";
import { get, update } from "lodash";

import { useExperimentApi } from "apis";
import { Card } from "components/common";
import { useIsMounted } from "hooks";
import Param from "./Param";
import { PIPELINES } from "../../../../config";

import "./Setup.scss";

const Setup = forwardRef(
  ({ experiment, getExperiment, samples, setErrors, extendedView }, ref) => {
    const formRef = useRef();
    const isMounted = useIsMounted();
    const experimentApi = useExperimentApi();
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [params, setParams] = useState({});
    const [fields, setFields] = useState([]);
    const [advancedFields, setAdvancedFields] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pipelineVersion, setPipelineVersion] = useState(
      experiment.pipeline_version
    );
    const [skipUniquenessCheck, setSkipUniquenessCheck] = useState(
      experiment.skip_uniqueness_check
    );

    useImperativeHandle(ref, () => ({
      validate: () => formRef.current.reportValidity(),
    }));

    const updateExperiment = useCallback(
      (changes) =>
        experimentApi.update(experiment.identifier, changes).catch((error) => {
          isMounted() && setErrors(error.response.data);
          // Reset form with correct values from api
          getExperiment();
          return Promise.reject(error);
        }),
      [
        experiment.identifier,
        experimentApi,
        getExperiment,
        isMounted,
        setErrors,
      ]
    );

    const handleChange = (param, value) => {
      setIsUpdating(true);

      const updatedParams = update(params, param, () => value);
      setParams(() => updatedParams);

      updateExperiment({
        setup_params: updatedParams,
      }).finally(() => isMounted() && setIsUpdating(false));
    };

    // Update Param fields everytime param values change so ensure correct values are shown
    // Both ui and api can update this param object
    useEffect(() => {
      if (!Object.keys(params).length) {
        return;
      }

      const newFields = [];
      const newAdvancedFields = [];

      PIPELINES[experiment.pipeline].config.params.forEach((paramConfig) => {
        if (paramConfig.hidden) {
          return;
        }

        let value = get(params, paramConfig.key);

        const field = (
          <Col key={paramConfig.key}>
            <Param
              value={value}
              paramConfig={paramConfig}
              onChange={handleChange}
              className="mt-2"
            ></Param>
          </Col>
        );

        paramConfig.advanced
          ? newAdvancedFields.push(field)
          : newFields.push(field);
      });

      setFields(newFields);
      setAdvancedFields(newAdvancedFields);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, samples]);

    useEffect(() => {
      setParams(experiment.setup_params);
      setPipelineVersion(experiment.pipeline_version);
      setSkipUniquenessCheck(experiment.skip_uniqueness_check);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experiment]);

    return (
      <Card title="Setup" className="h-100 setup" isLoading={isUpdating}>
        {!!fields.length && (
          <Form ref={formRef}>
            <Row xs={1} xl={extendedView ? 3 : 1}>
              {PIPELINES[experiment.pipeline].config.populatesSequenceDb && (
                <Param
                  value={skipUniquenessCheck}
                  paramConfig={{
                    key: "skip_uniqueness_check",
                    label: "Skip Uniqueness Check",
                    description: `Whether or not the sequences from this experiment will
                    be checked for duplicates in the sequence database`,
                    type: "select",
                    defaultChoice: false,
                    choices: [
                      { value: true, name: "Yes" },
                      { value: false, name: "No" },
                    ],
                  }}
                  onChange={(_, value) => {
                    setSkipUniquenessCheck(value);
                    setIsUpdating(true);
                    updateExperiment({
                      skip_uniqueness_check: value,
                    }).finally(() => isMounted() && setIsUpdating(false));
                  }}
                ></Param>
              )}
              {fields}
            </Row>

            <Row className="justify-content-center mt-3">
              <Col className="col-auto">
                <a
                  className="advanced-settings"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAdvanced(!showAdvanced);
                  }}
                >
                  <div className="d-flex align-items-center">
                    {showAdvanced ? (
                      <GrFormUp className="me-1" />
                    ) : (
                      <GrFormDown className="me-1" />
                    )}
                    Advanced Settings
                  </div>
                </a>
              </Col>
            </Row>

            {showAdvanced && (
              <Row xs={1} xl={extendedView ? 3 : 1}>
                {advancedFields}
                <Col>
                  <Param
                    value={pipelineVersion}
                    className="mt-2"
                    paramConfig={{
                      key: "pipeline_version",
                      label: "Pipeline Version",
                      type: "select",
                      choices:
                        PIPELINES[experiment.pipeline].config.pipelineVersions,
                    }}
                    onChange={(_, value) => {
                      setPipelineVersion(value);
                      setIsUpdating(true);
                      updateExperiment({
                        pipeline_version: value,
                      }).finally(() => isMounted() && setIsUpdating(false));
                    }}
                  ></Param>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Card>
    );
  }
);

export default Setup;
