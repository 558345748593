import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

import { DefaultModal, ErrorMessages } from "components/common";
import { useIsMounted } from "hooks";
import { useExperimentApi } from "apis";
import { PIPELINES } from "config";
import { update } from "lodash";

const NewExperimentModal = ({ defaultPipeline = "", onClose }) => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const experimentApi = useExperimentApi();

  const [isLoading, setIsLoading] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [pipeline, setPipeline] = useState(defaultPipeline);
  const [version, setVersion] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    const defaultParams = {};

    PIPELINES[pipeline].config.params.forEach((param) => {
      update(defaultParams, param.key, () => param.defaultValue);
    });

    experimentApi
      .create(identifier, pipeline, version, defaultParams)
      .then((response) => {
        onClose();
        navigate(`/experiments/${response.data.identifier}/`);
      })
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
  };

  const pipelineOptions = Object.keys(PIPELINES).map((pipeline) => {
    return (
      <option key={pipeline} value={pipeline}>
        {PIPELINES[pipeline].config.displayName}
      </option>
    );
  });

  !defaultPipeline &&
    pipelineOptions.unshift(
      <option key="choose" value="">
        Choose pipeline...
      </option>
    );

  useEffect(() => {
    if (pipeline) {
      setVersion(
        process.env.REACT_APP_ENVIRONMENT === "PROD"
          ? PIPELINES[pipeline].config.pipelineVersions[0]
          : "master"
      );
    }
  }, [pipeline]);

  return (
    <DefaultModal
      title="New Experiment"
      hasCancel
      onClose={onClose}
      onSubmit={handleSubmit}
      submitText="Create"
      isLoading={isLoading}
      size="md"
    >
      <div className="mb-2">
        <Form.Group className="mb-3" controlId="createForm.pipeline">
          <Form.Label>Pipeline</Form.Label>
          <Form.Select
            required
            value={pipeline}
            name="pipeline"
            onChange={(e) => {
              setPipeline(e.target.value);
              setErrors({});
            }}
            isInvalid={!!errors.pipeline}
          >
            {pipelineOptions}
          </Form.Select>
          {errors.pipeline && (
            <Form.Control.Feedback type="invalid">
              {errors.pipeline}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group controlId="createForm.identifier">
          <Form.Label>Identifier</Form.Label>
          <Form.Control
            placeholder="Identifier"
            required
            pattern="^[a-zA-Z0-9.()_-]+$"
            value={identifier}
            name="identifier"
            onChange={(e) => {
              setIdentifier(e.target.value);
              setErrors({});
            }}
            isInvalid={!!errors.identifier}
          />
          {errors.identifier && (
            <Form.Control.Feedback type="invalid">
              {errors.identifier}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <ErrorMessages
          errors={errors}
          keysToIgnore={["identifier", "pipeline"]}
          className="mt-3 align-items-center"
        ></ErrorMessages>
      </div>
    </DefaultModal>
  );
};

export default NewExperimentModal;
