import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";

const NumberInput = ({
  className = null,
  size = null,
  name,
  label = null,
  onChange,
  apiValue,
  min,
  max,
}) => {
  const [value, setValue] = useState(apiValue);

  const handleChange = (e) => {
    const changedValue = parseInt(e.target.value);
    if (isNaN(changedValue)) {
      setValue("");
      return;
    } else if (changedValue < min || changedValue > max) {
      return;
    }
    setValue(changedValue);
    handleChangeDebounced(name, changedValue);
  };

  const handleChangeDebounced = useAsyncDebounce(
    (name, value) => onChange(name, value),
    250
  );

  useEffect(() => !!apiValue && setValue(apiValue), [apiValue]);

  return (
    <Form.Group className={className}>
      {!!label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type="number"
        name={name}
        size={size}
        value={value}
        min={min}
        max={max}
        onChange={handleChange}
      ></Form.Control>
    </Form.Group>
  );
};

export default NumberInput;
