import { useMemo } from "react";

import { SimpleTable } from "../common";

export default function Table({ tableData }) {
  const columns = useMemo(() => {
    if (!tableData.length) return [];

    const columns = tableData.columns.map((column) => ({
      Header: column,
      accessor: column,
      Cell: ({ row }) => row.original[column],
      style: { overflow: "visible" },
    }));

    return columns;
  }, [tableData]);

  const data = useMemo(() => {
    if (!tableData.length) return [];

    return [...tableData];
  }, [tableData]);

  return (
    <SimpleTable
      columns={columns}
      data={data}
      size="sm"
      emptyMsg="Filter hitlist/species to get results from Genedata"
    ></SimpleTable>
  );
}
