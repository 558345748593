import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { ProgressBar } from "react-bootstrap";
import StatusBadge from "../common/StatusBadge";

const ExperimentStatus = ({ experiment }) => {
  const component = ["STARTING", "STARTED", "SUBMITTED", "RUNNING"].includes(
    experiment.status_pipeline
  ) ? (
    <ProgressBar
      now={experiment.status_progress}
      label={`${experiment.status_progress}%`}
    />
  ) : (
    <StatusBadge status={experiment.status_pipeline} />
  );

  return experiment.status_message ? (
    <OverlayTrigger
      placement="top"
      overlay={(props) => (
        <Tooltip {...props}>{experiment.status_message}</Tooltip>
      )}
    >
      <span>{component}</span>
    </OverlayTrigger>
  ) : (
    component
  );
};

export default ExperimentStatus;
