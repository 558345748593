import { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { GrAdd } from "react-icons/gr";

import { useSamplesApi } from "apis";
import { useIsMounted } from "hooks";
import { PIPELINES } from "config";
import { Card, Loader } from "../../../common";
import AddInputFiles from "../AddInputFiles";
import SamplesTable from "./SamplesTable";
import AddOutputFiles from "../AddOutputFiles";

const Samples = ({
  experiment,
  samples,
  getSamples,
  isLoading,
  setIsLoading,
  setErrors,
  curPath,
  setCurPath,
  metadataFieldValues,
}) => {
  const isMounted = useIsMounted();
  const samplesApi = useSamplesApi();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showAddFiles, setShowAddFiles] = useState(false);

  const handleDelete = (sample) => {
    setIsLoading(true);

    samplesApi
      .delete(sample.id)
      .catch((error) => isMounted() && setErrors(error.response.data))
      .then(getSamples)
      .finally(() => isMounted() && setIsLoading(false));
  };

  const handleSampleChange = useCallback(
    (sample, field, value) => {
      setIsUpdating(true);

      samplesApi
        .update(sample.id, { [field]: value })
        .catch((error) => {
          isMounted() && setErrors(error.response.data);
          getSamples(false);
        })
        .finally(() => isMounted() && setIsUpdating(false));
    },
    [getSamples, isMounted, samplesApi, setErrors]
  );

  const addSamples = (filesToAdd, platform = null) => {
    return Promise.all(
      filesToAdd.map((file) =>
        samplesApi.post(
          experiment,
          file.path,
          file.filename,
          file.type,
          platform
        )
      )
    );
  };

  const AddFiles = useCallback(
    (props) =>
      experiment.pipeline === "integration" ? (
        <AddOutputFiles {...props} />
      ) : (
        <AddInputFiles {...props} />
      ),
    [experiment.pipeline]
  );

  return (
    <Card
      title="Samples"
      className="add-samples h-100"
      isLoading={isUpdating}
      actions={
        <Button
          onClick={() => {
            setShowAddFiles(true);
          }}
          size="sm"
          className="d-flex align-items-center gr-add"
        >
          <GrAdd className="me-1" />
          Add
        </Button>
      }
    >
      <SamplesTable
        experiment={experiment}
        samples={samples}
        metadataFieldValues={metadataFieldValues}
        onDelete={handleDelete}
        onChange={handleSampleChange}
        fields={PIPELINES[experiment.pipeline].config.samples.allowedFields}
      ></SamplesTable>

      {showAddFiles && (
        <AddFiles
          title="Add Samples"
          onFilesAdded={() => getSamples(false)}
          onClose={() => setShowAddFiles(false)}
          addedFiles={samples}
          addFiles={addSamples}
          allowedExtensions={
            PIPELINES[experiment.pipeline].config.samples.allowedExtensions
          }
          curPath={curPath}
          setCurPath={setCurPath}
        ></AddFiles>
      )}
      <Loader isLoading={isLoading} />
    </Card>
  );
};

export default Samples;
