import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Card, Loader, SimpleTable } from "components/common";
import { PIPELINES } from "config";

const SupportingFiles = ({ experiment, supportingFiles, isLoading }) => {
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        Cell: ({ row }) =>
          PIPELINES[experiment.pipeline].config.supportingFiles.find(
            (config) => config.param === row.original.param
          ).label,
      },
      {
        Header: "Filename",
        accessor: "filename",
        id: "name",
        Cell: ({ row, value }) => (
          <OverlayTrigger
            placement="top"
            overlay={(props) => (
              <Tooltip {...props} className="description-tooltip">
                {row.original.path ? `${row.original.path}${value}` : value}
              </Tooltip>
            )}
          >
            <span>{value}</span>
          </OverlayTrigger>
        ),
      },
    ],
    [experiment]
  );

  const filteredSupportingFiles = supportingFiles.filter((file) =>
    PIPELINES[experiment.pipeline].config.supportingFiles.some(
      (configFile) => configFile.param === file.param
    )
  );

  return (
    <Card title="Supporting Files" className="supporting-files">
      <SimpleTable
        columns={columns}
        data={filteredSupportingFiles}
      ></SimpleTable>
      <Loader isLoading={isLoading} />
    </Card>
  );
};

export default SupportingFiles;
