import { Col, Row } from "react-bootstrap";

export const ExtendedView = ({
  metadataEnabled,
  Metadata,
  Details,
  Samples,
  SupportingFiles,
  OutputFiles,
  Params,
}) => {
  return (
    <>
      <Row>
        <Col xl="9">
          <Row xs="1">
            <Col
              md="6"
              xl={metadataEnabled ? "8" : "12"}
              className="mt-4 mt-xxl-0"
            >
              {Details}
            </Col>
            {metadataEnabled && (
              <Col md="6" className="mt-4 mt-xxl-0" xl="4">
                {Metadata}
              </Col>
            )}
            <Col className="mt-4">{Samples}</Col>
            <Col className="mt-4">{OutputFiles}</Col>
          </Row>
        </Col>

        <Col xl="3">
          <Row>
            <Col xl="12" className="mt-4 mt-xxl-0">
              {Params}
            </Col>
          </Row>
          {!!SupportingFiles.props.supportingFiles.length && (
            <Row>
              <Col className="mt-4">{SupportingFiles}</Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export const CompactView = ({
  metadataEnabled,
  Metadata,
  Details,
  Samples,
  OutputFiles,
  Params,
  SupportingFiles,
  showSupportingFiles,
  showGeneDataHitlists,
  GeneDataHitlists,
}) => {
  return (
    <>
      <Row>
        <Col xl="12">
          <Row xs="1">
            <Col
              md="6"
              xl={metadataEnabled ? "6" : "9"}
              className="mt-4 mt-xxl-0"
            >
              {Details}
            </Col>
            {metadataEnabled && (
              <Col md="6" className="mt-4 mt-xxl-0" xl="3">
                {Metadata}
              </Col>
            )}
            <Col xl="3" className="mt-4 mt-xxl-0">
              {Params}
            </Col>
          </Row>
          <Row>
            <Col xl="6" className="mt-4">
              {OutputFiles}
            </Col>
            <Col xl="6" className="mt-4">
              <Row>
                <Col xs="12">{Samples}</Col>
                {showSupportingFiles && <Col xs="12">{SupportingFiles}</Col>}
              </Row>
              <Row>
                {showGeneDataHitlists && (
                  <Col xs="12" className="mt-4">
                    {GeneDataHitlists}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
