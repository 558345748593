import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";

import FieldDescription from "./FieldDescription";

const RangeInput = ({
  className,
  min,
  max,
  step = 1,
  onChange,
  label,
  name,
  apiValue,
  description = null,
}) => {
  const [value, setValue] = useState(apiValue);
  const handleChange = (e) => {
    let changedValueNumber = parseFloat(e.target.value);

    if (isNaN(changedValueNumber)) {
      setValue("");
      return;
    }

    if (changedValueNumber < min || changedValueNumber > max) {
      return;
    }

    setValue(e.target.value);
    handleChangeDebounced(name, changedValueNumber);
  };

  const handleChangeDebounced = useAsyncDebounce(
    (name, value) => onChange(name, value),
    500
  );

  useEffect(() => !!apiValue && setValue(apiValue), [apiValue]);

  return (
    <Form.Group className={className}>
      <Form.Label>
        <span className="d-flex align-items-center">
          {label}
          {!!description && (
            <FieldDescription description={description}></FieldDescription>
          )}
        </span>
      </Form.Label>
      <Row className="align-items-center">
        <Col>
          <Form.Range
            min={min}
            max={max}
            step={step}
            name={name}
            value={value}
            onChange={handleChange}
          ></Form.Range>
        </Col>
        <Col xs="auto">
          <Form.Control
            type="number"
            value={value}
            size="sm"
            min={min}
            max={max}
            step={step}
            onChange={handleChange}
            className="number-input"
          ></Form.Control>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default RangeInput;
