import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useIsMounted } from "hooks";
import { useExperimentApi } from "apis";
import { Card } from "components/common";
import { SelectInput } from "components/common/FormInputs";
import { METADATA_FIELDS } from "../../../../config";
import { Col, Form, Row } from "react-bootstrap";

const Metadata = forwardRef(
  ({ experiment, getExperiment, metadataFieldValues, setErrors }, ref) => {
    const formRef = useRef();
    const isMounted = useIsMounted();
    const experimentApi = useExperimentApi();
    const [metadata, setMetadata] = useState({});
    const [fields, setFields] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);

    useImperativeHandle(ref, () => ({
      validate: () => formRef.current.reportValidity(),
    }));

    const resetMetadata = () => {
      const defaultMetadata = {};
      METADATA_FIELDS.forEach((field) => {
        defaultMetadata[field.key] = "";
      });

      setMetadata({ ...defaultMetadata, ...experiment.metadata });
    };

    const handleChange = (key, value) => {
      setIsUpdating(true);
      const updatedMetadata = {
        ...metadata,
        [key]: value,
      };

      setMetadata((prevState) => ({ ...prevState, [key]: value }));

      experimentApi
        .update(experiment.identifier, {
          metadata: updatedMetadata,
        })
        .catch((error) => {
          isMounted() && setErrors(error.response.data);
          getExperiment();
        })
        .finally(() => isMounted() && setIsUpdating(false));
    };

    useEffect(() => {
      if (experiment) {
        setFields(
          METADATA_FIELDS.map((field) => (
            <Col key={field.key}>
              <SelectInput
                className="mt-2 form-item"
                name={field.key}
                apiValue={metadata[field.key]}
                label={field.label}
                onChange={handleChange}
                choices={metadataFieldValues[field.key]}
              ></SelectInput>
            </Col>
          ))
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metadataFieldValues, metadata]);

    useEffect(resetMetadata, [experiment]);

    return (
      <Card title="Metadata" className="h-100" isLoading={isUpdating}>
        <Form ref={formRef}>
          <Row xs={1} xl={1}>
            {fields}
          </Row>
        </Form>
      </Card>
    );
  }
);

export default Metadata;
