import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useExperimentApi, useMetadataApi } from "apis";
import { useIsMounted } from "hooks";
import MainWrapper from "components/Main";
import { ErrorModal } from "components/common";

import ConfigureExperiment from "./ConfigureExperiment";
import ExperimentDetails from "./ExperimentDetails";

import "./Experiment.scss";

const Experiment = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const experimentApi = useExperimentApi();
  const metadataApi = useMetadataApi();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSamplesLoading, setIsSamplesLoading] = useState(false);
  const [isSupportingFilesLoading, setIsSupportingFilesLoading] =
    useState(false);
  const [experiment, setExperiment] = useState(null);
  const [samples, setSamples] = useState([]);
  const [supportingFiles, setSupportingFiles] = useState([]);
  const [headerActions, setHeaderActions] = useState([]);
  const [errors, setErrors] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [errorsTitle, setErrorsTitle] = useState(null);
  const [metadataEnabled, setMetadataEnabled] = useState(false);
  const [metadataFieldValues, setMetadataFieldValues] = useState({});

  const setAndShowErrors = (errors, title) => {
    setErrors(errors);
    setErrorsTitle(title);
    setShowErrors(true);
  };

  const resetErrors = () => {
    setErrors(null);
    setErrorsTitle(null);
    setShowErrors(false);
  };

  const getExperiment = useCallback(
    (showSpinner = false) => {
      showSpinner && setIsLoading(true);
      return experimentApi
        .get(id)
        .then((response) => {
          isMounted() && setExperiment(response.data);
          return response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            navigate("/experiments/");
            return;
          }

          isMounted() && setAndShowErrors(error.response.data);
        })
        .finally(() => isMounted() && showSpinner && setIsLoading(false));
    },
    [experimentApi, id, isMounted, navigate, setIsLoading]
  );

  const getSamples = useCallback(
    (showSpinner = true) => {
      showSpinner && setIsSamplesLoading(true);
      return experimentApi
        .getSamples(id)
        .then((response) => {
          isMounted() && setSamples(response.data);
          return response.data;
        })
        .catch((error) => isMounted() && setAndShowErrors(error.response.data))
        .finally(
          () => isMounted() && showSpinner && setIsSamplesLoading(false)
        );
    },
    [experimentApi, id, isMounted]
  );

  const getSupportingFiles = useCallback(
    (showSpinner = true) => {
      showSpinner && setIsSupportingFilesLoading(true);
      return experimentApi
        .getSupportingFiles(id)
        .then((response) => {
          isMounted() && setSupportingFiles(response.data);
          return response.data;
        })
        .catch((error) => isMounted() && setAndShowErrors(error.response.data))
        .finally(
          () => isMounted() && showSpinner && setIsSupportingFilesLoading(false)
        );
    },
    [experimentApi, id, isMounted]
  );

  const getMetadataFieldValues = useCallback(() => {
    metadataApi
      .get()
      .then((response) => {
        if (isMounted()) {
          setMetadataFieldValues(response.data);
          setMetadataEnabled(!!Object.keys(response.data).length);
        }
      })
      .catch((error) => isMounted() && setAndShowErrors(error.response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([getExperiment(), getMetadataFieldValues()]).finally(
      () => isMounted() && setIsLoading(false)
    );
    getMetadataFieldValues();
    getSamples();
    getSupportingFiles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <MainWrapper
      title={!!experiment ? `Experiment -  ${experiment.identifier}` : ""}
      headerActions={headerActions}
      isLoading={isLoading}
      contentClass="experiment"
    >
      {!!experiment && (
        <>
          {experiment.status_pipeline === "CREATED" ? (
            <ConfigureExperiment
              experiment={experiment}
              getExperiment={getExperiment}
              setExperiment={setExperiment}
              samples={samples}
              getSamples={getSamples}
              isSamplesLoading={isSamplesLoading}
              setIsSamplesLoading={setIsSamplesLoading}
              supportingFiles={supportingFiles}
              getSupportingFiles={getSupportingFiles}
              isSupportingFilesLoading={isSupportingFilesLoading}
              setIsSupportingFilesLoading={setIsSupportingFilesLoading}
              setHeaderActions={setHeaderActions}
              setErrors={setAndShowErrors}
              setIsLoading={setIsLoading}
              metadataFieldValues={metadataFieldValues}
              metadataEnabled={metadataEnabled}
            ></ConfigureExperiment>
          ) : (
            <ExperimentDetails
              experiment={experiment}
              getExperiment={getExperiment}
              samples={samples}
              isSamplesLoading={isSamplesLoading}
              supportingFiles={supportingFiles}
              isSupportingFilesLoading={isSupportingFilesLoading}
              setErrors={setAndShowErrors}
              metadataEnabled={metadataEnabled}
            ></ExperimentDetails>
          )}
        </>
      )}

      {showErrors && (
        <ErrorModal
          title={errorsTitle}
          size={errorsTitle ? "lg" : null}
          errors={errors}
          onClose={() => resetErrors()}
        ></ErrorModal>
      )}
    </MainWrapper>
  );
};

export default Experiment;
