import { useMemo } from "react";
import { useStorageApi } from "apis";
import { useIsMounted, useUser } from "hooks";
import PrettyBytes from "pretty-bytes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  GrDocument,
  GrDownload,
  GrFolder,
  GrStatusWarning,
} from "react-icons/gr";
import { getDatetimeFormatted } from "utils";
import { IconButton, Table } from "../common";

const SamplesTable = ({
  data,
  setSelectedRows,
  tableRef,
  onNavigate,
  setErrors,
}) => {
  const user = useUser();
  const storageApi = useStorageApi();
  const isMounted = useIsMounted();

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "filename",
        Cell: ({ row }) =>
          row.original.is_folder ? (
            <>
              <GrFolder className="me-2" />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onNavigate(`${row.original.path}${row.original.filename}`);
                }}
              >
                {row.original.filename}
              </a>
            </>
          ) : (
            <span>
              <GrDocument className="me-2" />
              {row.original.filename}
            </span>
          ),
      },
      {
        Header: "Modified",
        accessor: "last_modified",
        Cell: ({ row, value }) =>
          row.original.is_folder ? null : value && getDatetimeFormatted(value),
      },
      {
        Header: "Size",
        accessor: "size",
        Cell: ({ value }) => (value ? PrettyBytes(value) : null),
      },
      {
        Header: "Is Archived",
        accessor: "is_archived",
        canSort: false,
        disableSort: true,
        Cell: ({ value }) =>
          value ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>File is archived</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <span ref={ref} {...triggerHandler} className="text-warning">
                  <GrStatusWarning />
                </span>
              )}
            </OverlayTrigger>
          ) : null,
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          return (
            !row.original.is_folder &&
            !row.original.is_archived && (
              <IconButton
                title="Download"
                icon={<GrDownload />}
                onClick={() =>
                  storageApi
                    .getDownloadUrl(row.original)
                    .then((response) => {
                      return window.open(response.data.url, "_blank");
                    })
                    .catch(
                      (error) => isMounted() && setErrors(error.response.data)
                    )
                }
              ></IconButton>
            )
          );
        },
      },
    ],
    [onNavigate, isMounted, setErrors, storageApi]
  );

  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={data}
      enableRowSelection={user.isAdmin}
      setSelectedRows={setSelectedRows}
      enableSelectOnClick={user.isAdmin}
      isSelectable={(row) => !row.is_archived}
      noPagination
    />
  );
};

export default SamplesTable;
