import { useRef } from "react";
import { Row, Col } from "react-bootstrap";

export default function ErrorMessages({
  errors,
  keysToIgnore = [],
  position = "end",
  className = "",
}) {
  const ref = useRef(null);
  const errorKeys = errors ? Object.keys(errors) : [];

  if (errorKeys.length) {
    ref.current && ref.current.scrollIntoView();
    const errorMsgs = errorKeys
      .filter((key) => !keysToIgnore.includes(key))
      .map((key) => {
        if (Array.isArray(errors[key])) {
          return errors[key].map((msg, i) => (
            <p key={`error-${key}-${i}`} className="text-danger m-0">
              {msg}
            </p>
          ));
        } else {
          return (
            <p key={`error-${key}`} className="text-danger m-0">
              {errors[key]}
            </p>
          );
        }
      });

    return (
      <Row className={className}>
        <Col className={`d-flex justify-content-${position} text-${position}`}>
          <Row>
            <Col ref={ref}>{errorMsgs}</Col>
          </Row>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
}
