import { Fragment } from "react";
import { GrHome, GrNext } from "react-icons/gr";

import "./BreadCrumbs.scss";

const BreadCrumbs = ({ path, onCrumbClicked }) => {
  let crumbs = [];

  if (path) {
    crumbs = path
      .split("/")
      .filter((part) => !!part)
      .map((part, i, parts) => {
        const curPath = parts.slice(0, i + 1).join("/") + "/";

        return (
          <Fragment key={curPath}>
            <>
              <GrNext className="divider" />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  onCrumbClicked(curPath);
                }}
              >
                {part}
              </a>
            </>
          </Fragment>
        );
      });
  }

  return (
    <span className="breadcrumbs">
      <GrHome
        className="folder-icon"
        onClick={() => {
          onCrumbClicked("");
        }}
      />
      {crumbs}
    </span>
  );
};

export default BreadCrumbs;
