import { Spinner } from "react-bootstrap";
import "./Loader.scss";

const Loader = ({ isLoading, fixed = false, topSpinner = false }) => {
  return isLoading ? (
    <div
      className={`loader ${fixed && "fixed"} ${topSpinner && "top-spinner"}`}
    >
      <Spinner animation="border" />
    </div>
  ) : null;
};

export default Loader;
