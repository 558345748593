import { useExperimentApi } from "apis";
import { useIsMounted } from "hooks";
import { useState } from "react";
import { Form } from "react-bootstrap";
import DefaultModal from "../DefaultModal";
import ErrorMessages from "../ErrorMessages";

const REASONS = [
  "DUPLICATED",
  "WRONG INPUT FILE",
  "WRONG PARAMETERS",
  "FAILED WET LAB EXPERIMENT - BAD RESULTS",
  "MADE IN ERROR",
];

export default function DeleteArchiveExperiment({
  onCancel,
  onDeleted,
  experiment,
}) {
  const isMounted = useIsMounted();
  const experimentApi = useExperimentApi();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [reason, setReason] = useState(REASONS[0]);
  const [description, setDescription] = useState("");

  const isCompletedExperiment = experiment.status_pipeline === "COMPLETED";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const promise = isCompletedExperiment
      ? experimentApi.archive(experiment.identifier, reason, description)
      : experimentApi.delete(experiment.identifier);

    promise
      .then(onDeleted)
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
  };

  return (
    <DefaultModal
      title="Confirm Delete"
      isLoading={isLoading}
      onClose={onCancel}
      onSubmit={handleSubmit}
      hasCancel={true}
    >
      <p>
        Are you sure you want to delete experiment `{experiment.identifier}`?
      </p>

      {isCompletedExperiment && (
        <>
          <Form.Group controlId="reason">
            <Form.Label>Reason</Form.Label>
            <Form.Select
              required
              value={reason}
              name="reason"
              onChange={(e) => {
                setReason(e.target.value);
                setErrors(null);
              }}
            >
              {REASONS.map((reason) => (
                <option key={reason} value={reason}>
                  {reason}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="description" className="my-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              placeholder="description"
              as="textarea"
              required
              maxLength="75"
              value={description}
              name="description"
              onChange={(e) => {
                setDescription(e.target.value);
                setErrors(null);
              }}
            />
          </Form.Group>
        </>
      )}

      <ErrorMessages errors={errors} className="mt-4"></ErrorMessages>
    </DefaultModal>
  );
}
