import { Badge } from "react-bootstrap";

const bgs = {
  primary: [
    "CREATED",
    "STARTING",
    "STARTED",
    "SUBMITTED",
    "RUNNING",
    "IN_PROGRESS",
  ],
  danger: ["ERROR", "FAILED"],
  success: ["COMPLETED"],
};

const StatusBadge = ({ status }) => {
  const getBg = (status) => {
    for (let bg in bgs) {
      if (bgs[bg].includes(status)) {
        return bg;
      }
    }

    return "danger";
  };

  return <Badge bg={getBg(status)}>{status}</Badge>;
};

export default StatusBadge;
