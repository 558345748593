import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

import { DefaultModal, ErrorMessages } from "components/common";
import { useIsMounted } from "hooks";
import { useExperimentApi } from "apis";

const CopyExperiment = ({ experiment, onClose }) => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const experimentApi = useExperimentApi();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [identifier, setIdentifier] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    experimentApi
      .copy(experiment.identifier, identifier)
      .then((response) => {
        onClose();
        navigate(`/experiments/${identifier}/`);
      })
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(() => isMounted() && setIsLoading(false));
  };

  return (
    <DefaultModal
      title="Copy Experiment"
      hasCancel
      onClose={onClose}
      onSubmit={handleSubmit}
      submitText="Copy"
      isLoading={isLoading}
      size="md"
    >
      <div className="mb-2">
        <Form.Group controlId="copyForm.identifier">
          <Form.Label>Identifier</Form.Label>
          <Form.Control
            placeholder="Identifier"
            required
            pattern="^[a-zA-Z0-9.()_-]+$"
            value={identifier}
            name="identifier"
            onChange={(e) => {
              setIdentifier(e.target.value);
              setErrors({});
            }}
            isInvalid={!!errors.identifier}
          />
          {errors.identifier && (
            <Form.Control.Feedback type="invalid">
              {errors.identifier}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <ErrorMessages
          errors={errors}
          keysToIgnore={["identifier"]}
          className="mt-3 align-items-center"
        ></ErrorMessages>
      </div>
    </DefaultModal>
  );
};

export default CopyExperiment;
