import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import heatmap from "highcharts/modules/heatmap";
import { useEffect, useState } from "react";

import "../charts.scss";

exporting(Highcharts);
heatmap(Highcharts);

const Heatmap = ({ config }) => {
  const [options, setOptions] = useState({});

  const fetchHeatmapData = () => {
    const options = {
      chart: {
        type: "heatmap",
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
      },
      title: {
        text: config.title,
      },
      colorAxis: {
        min: 0,
        minColor: "#d4dbfa",
        maxColor: "#01156e",
      },
      legend: {
        align: "right",
        layout: "vertical",
        margin: 0,
        verticalAlign: "top",
        y: 25,
        symbolHeight: 280,
      },
      xAxis: !!config.xAxis
        ? config.xAxis
        : {
            showEmpty: false,
            categories: config.columns,
          },
      yAxis: !!config.yAxis
        ? config.yAxis
        : {
            labels: {
              format: "{text}%",
            },
            title: {
              text: "Percentage",
            },
          },
      series: config.series ? config.series : [],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter: function () {
                    return this.value.charAt(0);
                  },
                },
              },
            },
          },
        ],
      },
      credits: {
        enabled: false,
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    fetchHeatmapData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      loader={true}
      containerProps={{ className: "full-width-chart-container" }}
    />
  );
};

export default Heatmap;
