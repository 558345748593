import { forwardRef, Fragment, useEffect, useImperativeHandle } from "react";
import { Table as BTable } from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table";

import TableFilter from "./TableFilter";
import {
  CellConditionalSelect,
  HeaderConditionalSelect,
} from "./ConditionalSelect";

import "./table.scss";

// This table comes without pagination feature
const TableWithoutPagination = forwardRef(
  (
    {
      columns,
      data,
      enableRowSelection = false,
      setSelectedRows,
      enableRowClick = false,
      onRowClick = null,
      enableSelectOnClick = false,
      enableMultiSelect = true,
      isSelectable = () => true,
    },
    ref
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setGlobalFilter,
      rows,
      selectedFlatRows,
      toggleAllRowsSelected,
      state: { globalFilter },
    } = useTable(
      {
        columns,
        data,
        autoResetGlobalFilter: false,
      },
      useGlobalFilter,
      useSortBy,
      useRowSelect,
      (hooks) => {
        if (enableRowSelection) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: (props) =>
                enableMultiSelect ? (
                  <HeaderConditionalSelect
                    headerProps={props}
                    isSelectable={isSelectable}
                  />
                ) : null,
              Cell: ({ row }) => (
                <CellConditionalSelect
                  row={row}
                  isSelectable={isSelectable}
                  toggleRowSelected={toggleRowSelected}
                />
              ),
            },
            ...columns,
          ]);
        }
      }
    );

    const toggleRowSelected = (row, checked = null) => {
      if (isSelectable(row.original)) {
        !enableMultiSelect && toggleAllRowsSelected(false);
        checked !== null
          ? row.toggleRowSelected(checked)
          : row.toggleRowSelected(!row.isSelected);
      }
    };

    const handleRowClick = (row) => {
      // Do not handle row click if text is selected
      if (window.getSelection().toString().length) {
        return;
      }
      if (enableRowClick) {
        onRowClick(row.original);
      } else if (enableSelectOnClick) {
        toggleRowSelected(row);
      }
    };

    useEffect(() => {
      !!setSelectedRows &&
        setSelectedRows(selectedFlatRows.map((row) => row.original));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlatRows]);

    useImperativeHandle(ref, () => ({
      resetSelection: () => toggleAllRowsSelected(false),
    }));

    return (
      <div className="common-table">
        <div className="d-flex mb-2">
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          ></TableFilter>
        </div>

        <div>
          <BTable
            responsive
            hover={enableRowClick || enableSelectOnClick}
            className="table-centered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`${column.id} ${
                          !column.disableSort && column.canSort && "sortable"
                        } ${
                          column.isSortedDesc
                            ? "desc"
                            : column.isSorted
                            ? "asc"
                            : ""
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {(rows.length &&
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.id}>
                      <tr
                        {...row.getRowProps()}
                        onClick={() => handleRowClick(row)}
                        className={
                          enableRowClick ||
                          (enableSelectOnClick && isSelectable(row.original))
                            ? "clickable"
                            : ""
                        }
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={cell.column.id}
                              onClick={
                                cell.column.id === "actions"
                                  ? (e) => e.stopPropagation()
                                  : null
                              }
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })) || (
                <tr>
                  <td
                    className="text-center nohover"
                    colSpan={
                      enableRowSelection ? columns.length + 1 : columns.length
                    }
                  >
                    No records available
                  </td>
                </tr>
              )}
            </tbody>
          </BTable>
        </div>
      </div>
    );
  }
);

export default TableWithoutPagination;
