import { useEffect, useRef, useState } from "react";

import {
  Loader,
  DefaultModal,
  ErrorMessages,
  BreadCrumbs,
} from "components/common";
import { useStorageApi } from "apis";
import { useIsMounted } from "hooks";
import AddFilesTable from "./Table";

import "./AddInputFiles.scss";

const AddInputFiles = ({
  title,
  onFilesAdded,
  onClose,
  addFiles,
  addedFiles,
  allowedExtensions = [],
  enableMultiSelect = true,
  curPath,
  setCurPath,
}) => {
  const tableRef = useRef();
  const isMounted = useIsMounted();
  const storageApi = useStorageApi();

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [errors, setErrors] = useState({});

  const getItems = () => {
    setIsLoading(true);

    storageApi
      .list("input", curPath)
      .then((response) => {
        if (isMounted()) {
          setItems(response.data);
        }
      })
      .finally(() => isMounted() && setIsLoading(false));
  };

  const handleAdd = (e) => {
    e.preventDefault();

    setIsAdding(true);

    addFiles(filesToAdd)
      .catch((error) => isMounted() && setErrors(error.response.data))
      .then(onFilesAdded)
      .then(onClose)
      .finally(() => isMounted() && setIsAdding(false));
  };

  const handleNavigate = (path) => setCurPath(path);
  const isAdded = (fileToCheck) =>
    addedFiles.some((file) => file.filename === fileToCheck.filename);
  const isSelectable = (fileToCheck) => {
    if (fileToCheck.is_folder || fileToCheck.is_archived) {
      return false;
    } else {
      return (
        allowedExtensions.some((ext) => fileToCheck.filename.endsWith(ext)) &&
        !isAdded(fileToCheck)
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getItems, [curPath]);

  return (
    <DefaultModal
      dialogClassName="add-input-files-modal"
      title={title}
      isLoading={isAdding}
      onSubmit={handleAdd}
      submitDisabled={!filesToAdd.length}
      submitText={enableMultiSelect ? "Add" : "Select"}
      onClose={onClose}
      hasCancel={true}
      size="lg"
      scrollable
    >
      <div className="mb-3">
        <BreadCrumbs
          path={curPath}
          onCrumbClicked={handleNavigate}
        ></BreadCrumbs>
      </div>
      <AddFilesTable
        tableRef={tableRef}
        data={items}
        onNavigate={handleNavigate}
        setSelectedRows={(changedSelection) => setFilesToAdd(changedSelection)}
        isAdded={isAdded}
        isSelectable={isSelectable}
        enableMultiSelect={enableMultiSelect}
      ></AddFilesTable>
      <Loader isLoading={isLoading}></Loader>
      <ErrorMessages errors={errors} className="mt-4"></ErrorMessages>
    </DefaultModal>
  );
};

export default AddInputFiles;
