import {
  cloneElement,
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Table as BTable } from "react-bootstrap";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import TablePagination from "./TablePagination";
import {
  CellConditionalSelect,
  HeaderConditionalSelect,
} from "./ConditionalSelect";

import "./table.scss";
import { GrDown, GrNext } from "react-icons/gr";

const NestedTable = forwardRef(
  (
    {
      columns,
      data,
      getData,
      children,
      subTableDataFields,
      defaultPageSize = 25,
      defaultSortBy = [],
      enableRowSelection = false,
      enableRowClick = false,
      onRowClick = null,
      enableSelectOnClick = false,
      enableMultiSelect = true,
      enableToggleAll = true,
      enableManualSelection = false,
      enableToggleRowOnClick = false,
      isSelectable = () => true,
      isSelected = null,
      selectedRows = null,
      setSelectedRows = null,
      handleToggleRowSelected = false,
      pageCount = 0,
      totalRowCount,
      size = null,
    },
    ref
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      selectedFlatRows,
      toggleAllRowsSelected,
      state: { pageIndex, pageSize, sortBy },
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: defaultPageSize,
          sortBy: defaultSortBy,
        },
        manualPagination: true,
        manualSortBy: true,
        pageCount,
        autoResetPage: false,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        if (enableRowSelection) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: (props) =>
                enableMultiSelect && enableToggleAll ? (
                  <HeaderConditionalSelect
                    headerProps={props}
                    isSelectable={isSelectable}
                  />
                ) : null,
              Cell: ({ row }) => (
                <CellConditionalSelect
                  row={row}
                  isSelectable={isSelectable}
                  toggleRowSelected={toggleRowSelected}
                />
              ),
            },
            ...columns,
          ]);
        }
      }
    );
    const [open, setOpen] = useState(false);

    const toggleRowSelected = (row, checked = null) => {
      if (isSelectable(row.original)) {
        if (enableManualSelection) {
          checked = checked !== null ? checked : !row.isSelected;
          handleToggleRowSelected(row, checked);
        } else {
          !enableMultiSelect && toggleAllRowsSelected(false);
          checked = checked !== null ? checked : !row.isSelected;
          row.toggleRowSelected(checked);
        }
      }
    };

    const toggleRowOpen = (id) => {
      if (open === id) {
        setOpen(false);
      } else {
        setOpen(id);
      }
    };

    useEffect(() => {
      !enableManualSelection &&
        !!setSelectedRows &&
        setSelectedRows(selectedFlatRows.map((row) => row.original));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlatRows]);

    // Listen for changes in pagination and use the state to fetch our new data
    const useEffectRef = useRef(false);

    useEffect(() => {
      if (useEffectRef.current) {
        getData(pageIndex + 1, pageSize, sortBy);
      } else {
        useEffectRef.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sortBy]);

    useEffect(() => {
      if (enableManualSelection) {
        page.forEach((row) => row.toggleRowSelected(isSelected(row.original)));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, selectedRows]);

    useImperativeHandle(ref, () => ({
      resetSelection: () => toggleAllRowsSelected(false),
      getPageSize: () => pageSize,
      getPage: () => pageIndex + 1,
      getSortBy: () => sortBy,
      resetPage: () => gotoPage(0),
    }));

    return (
      <div className="common-table">
        <TablePagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          data={data}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalRowCount={totalRowCount}
        />

        <div>
          <BTable
            size={size}
            responsive
            hover={enableRowClick || enableSelectOnClick}
            className="table-centered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {!!children && <th className="row-expand"></th>}
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`${column.id} ${
                          !column.disableSort && column.canSort && "sortable"
                        } ${
                          column.isSortedDesc
                            ? "desc"
                            : column.isSorted
                            ? "asc"
                            : ""
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {(page.length &&
                page.map((row) => {
                  prepareRow(row);
                  const hasSubTableData =
                    row.original &&
                    subTableDataFields &&
                    subTableDataFields.some(
                      (field) =>
                        row.original[field] && row.original[field].length
                    );
                  return (
                    <Fragment key={row.id}>
                      <tr
                        {...row.getRowProps()}
                        onClick={(e) => {
                          if (enableRowClick) {
                            onRowClick(row.original);
                          } else if (enableSelectOnClick) {
                            toggleRowSelected(row);
                          } else if (enableToggleRowOnClick) {
                            toggleRowOpen(row.id);
                          }
                        }}
                        className={
                          enableRowClick ||
                          enableSelectOnClick ||
                          enableToggleRowOnClick
                            ? "clickable"
                            : ""
                        }
                      >
                        {!!children && (
                          <td className="row-expand">
                            {hasSubTableData && (
                              <span
                                id={row.id}
                                className="clickable"
                                onClick={() => toggleRowOpen(row.id)}
                              >
                                {open === row.id ? (
                                  <span>
                                    <GrDown />
                                  </span>
                                ) : (
                                  <span>
                                    <GrNext />
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                        )}
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={cell.column.id}
                              onClick={
                                cell.column.id === "actions"
                                  ? (e) => e.stopPropagation()
                                  : null
                              }
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                      {open === row.id && hasSubTableData && (
                        <tr colSpan={row.cells.length + 1}>
                          <td className="sub-table-filler"></td>
                          <td colSpan={row.cells.length}>
                            {cloneElement(children, { data: row.original })}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })) || (
                <tr>
                  <td
                    className="text-center nohover"
                    colSpan={
                      enableRowSelection ? columns.length + 1 : columns.length
                    }
                  >
                    No records available
                  </td>
                </tr>
              )}
            </tbody>
          </BTable>
        </div>
      </div>
    );
  }
);

export default NestedTable;
