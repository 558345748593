import { useMemo } from "react";
import useApi from "./useApi";

const useSupportingFilesApi = () => {
  const api = useApi();

  return useMemo(
    () => ({
      post: (experiment, param, path, filename) => {
        return api.call({
          method: "post",
          url: "supporting_file/",
          data: {
            experiment: experiment.identifier,
            param,
            path,
            filename,
          },
        });
      },
      delete: (id) => {
        return api.call({ method: "delete", url: `supporting_file/${id}/` });
      },
    }),
    [api]
  );
};

export default useSupportingFilesApi;
