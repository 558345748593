import { useMemo } from "react";
import { GrCheckmark, GrDocument, GrFolder } from "react-icons/gr";
import { Table } from "components/common";
import { PIPELINES } from "config";

export default function AddFilesTable({
  data,
  setSelectedRows,
  tableRef,
  onNavigate,
  isAdded,
  isSelectable,
  enableMultiSelect,
  showExperiments,
}) {
  const columns = useMemo(
    () => {
      if (showExperiments) {
        return [
          {
            Header: "Experiment",
            accessor: "identifier",
            id: "name",
            Cell: ({ row }) => (
              <>
                <GrFolder className="me-2" />
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    onNavigate(row.original.identifier);
                  }}
                >
                  {row.original.identifier}
                </a>
              </>
            ),
          },
          {
            Header: "Pipeline",
            accessor: "pipeline",
            Cell: ({ value }) => PIPELINES[value].config.displayName,
          },
        ];
      } else {
        return [
          {
            Header: "Filename",
            accessor: "filename",
            Cell: ({ row }) => (
              <span>
                <GrDocument className="me-2" />
                {row.original.filename}
              </span>
            ),
          },
          {
            Header: enableMultiSelect ? "Added" : "Selected",
            id: "added",
            accessor: null,
            Cell: ({ row }) => (isAdded(row.original) ? <GrCheckmark /> : null),
          },
        ];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showExperiments]
  );
  return (
    <Table
      ref={tableRef}
      columns={columns}
      defaultPageSize={10}
      data={useMemo(() => data, [data])}
      enableRowSelection={!showExperiments}
      setSelectedRows={setSelectedRows}
      noPagination={!showExperiments}
      enableConditionalSelect={!showExperiments}
      isSelectable={isSelectable}
      enableMultiSelect={enableMultiSelect}
      enableSelectOnClick={!showExperiments}
    ></Table>
  );
}
