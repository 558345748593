import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GrTrash } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

import { useIsMounted, useUser } from "hooks";
import { useExperimentApi } from "apis";
import { DeleteArchiveExperiment } from "components/common";
import { PIPELINES } from "config";

import PipelineOverview from "./PipelineOverview";
import Setup from "./Setup";
import Samples from "./Samples";
import SupportingFiles from "./SupportingFiles";
import GeneDataHitlists from "./GeneDataHitlists";
import Metadata from "./Metadata";
import validateExperiment from "./validateExperiment";
import { CompactView, ExtendedView } from "./Views";

import "./ConfigureExperiment.scss";

const ConfigureExperiment = ({
  experiment,
  getExperiment,
  setExperiment,
  samples,
  getSamples,
  isSamplesLoading,
  setIsSamplesLoading,
  supportingFiles,
  getSupportingFiles,
  isSupportingFilesLoading,
  setIsSupportingFilesLoading,
  setHeaderActions,
  setErrors,
  setIsLoading,
  metadataFieldValues,
  metadataEnabled,
}) => {
  const experimentApi = useExperimentApi();

  const isMounted = useIsMounted();
  const user = useUser();
  const metadataRef = useRef();
  const setupRef = useRef();
  const navigate = useNavigate();
  const extendedView =
    PIPELINES[experiment.pipeline].config.extendedView.configure;
  // Current selected path when either selecting samples or supporting files
  const [curPath, setCurPath] = useState("");
  const [showDeleteExperiment, setShowDeleteExperiment] = useState(false);
  const [showSupportingFiles, setShowSupportingFiles] = useState(false);
  const showGeneDataHitlists = PIPELINES[experiment.pipeline].config.geneData;

  const handleStartExperiment = useCallback(() => {
    // Validate metadata form
    if (metadataEnabled && !metadataRef.current.validate()) {
      return;
    }

    // Validate setup form
    if (!setupRef.current.validate()) {
      return;
    }

    setIsLoading(true);
    getExperiment().then((experiment) => {
      Promise.all([getSamples(false), getSupportingFiles(false)]).then(
        ([samples, supportingFiles]) =>
          validateExperiment(
            experiment,
            samples,
            supportingFiles,
            metadataEnabled
          )
            .then(() =>
              experimentApi
                .start(experiment.identifier)
                .catch((error) => isMounted() && setErrors(error.response.data))
                .then(() => {
                  isMounted() && setHeaderActions([]);
                  return getExperiment(true);
                })
            )
            .catch(
              (errors) =>
                isMounted() &&
                setErrors(
                  errors,
                  "Please fix following issues to be able to start your experiment:"
                )
            )
            .finally(() => isMounted() && setIsLoading(false))
      );
    });
  }, [
    experimentApi,
    getExperiment,
    getSamples,
    getSupportingFiles,
    isMounted,
    metadataEnabled,
    setErrors,
    setHeaderActions,
    setIsLoading,
  ]);

  useEffect(() => {
    setShowSupportingFiles(
      !!PIPELINES[experiment.pipeline].config.supportingFiles.length
    );
    user.canWrite &&
      isMounted() &&
      setHeaderActions([
        {
          name: null,
          icon: <GrTrash></GrTrash>,
          onClick: () => setShowDeleteExperiment(true),
        },
        {
          name: "Start Experiment",
          variant: "secondary",
          icon: null,
          onClick: handleStartExperiment,
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, experiment]);

  const getMetadataComponent = () => {
    return (
      <Metadata
        ref={metadataRef}
        experiment={experiment}
        getExperiment={getExperiment}
        metadataFieldValues={metadataFieldValues}
        setErrors={setErrors}
      ></Metadata>
    );
  };

  const getSetupComponent = () => {
    return (
      <Setup
        ref={setupRef}
        experiment={experiment}
        getExperiment={getExperiment}
        samples={samples}
        setErrors={setErrors}
        extendedView={extendedView}
      ></Setup>
    );
  };

  const getSamplesComponent = () => {
    return (
      <Samples
        experiment={experiment}
        samples={samples}
        getSamples={getSamples}
        isLoading={isSamplesLoading}
        setIsLoading={setIsSamplesLoading}
        metadataFieldValues={metadataFieldValues}
        setErrors={setErrors}
        curPath={curPath}
        setCurPath={setCurPath}
      ></Samples>
    );
  };

  const getSupportingFilesComponent = () => {
    return (
      <SupportingFiles
        experiment={experiment}
        supportingFiles={supportingFiles}
        getSupportingFiles={getSupportingFiles}
        setErrors={setErrors}
        isLoading={isSupportingFilesLoading}
        setIsLoading={setIsSupportingFilesLoading}
        curPath={curPath}
        setCurPath={setCurPath}
      ></SupportingFiles>
    );
  };

  const getGenedataHitlistComponent = () => {
    return (
      <GeneDataHitlists
        experiment={experiment}
        setExperiment={setExperiment}
        setErrors={setErrors}
      />
    );
  };

  return (
    <>
      <Row>
        <Col>
          <PipelineOverview pipeline={experiment.pipeline}></PipelineOverview>
        </Col>
      </Row>
      {extendedView ? (
        <ExtendedView
          metadataEnabled={metadataEnabled}
          Metadata={getMetadataComponent()}
          Setup={getSetupComponent()}
          Samples={getSamplesComponent()}
          showSupportingFiles={showSupportingFiles}
          SupportingFiles={getSupportingFilesComponent()}
        ></ExtendedView>
      ) : (
        <CompactView
          metadataEnabled={metadataEnabled}
          Metadata={getMetadataComponent()}
          Setup={getSetupComponent()}
          Samples={getSamplesComponent()}
          showSupportingFiles={showSupportingFiles}
          SupportingFiles={getSupportingFilesComponent()}
          showGeneDataHitlists={showGeneDataHitlists}
          GeneDataHitlists={getGenedataHitlistComponent()}
        ></CompactView>
      )}

      {showDeleteExperiment && (
        <DeleteArchiveExperiment
          experiment={experiment}
          onCancel={() => setShowDeleteExperiment(false)}
          onDeleted={() => {
            setShowDeleteExperiment(false);
            navigate("/experiments/");
          }}
        ></DeleteArchiveExperiment>
      )}
    </>
  );
};

export default ConfigureExperiment;
