import { useMemo } from "react";
import useApi from "./useApi";

const useMetadataApi = () => {
  const api = useApi();

  return useMemo(
    () => ({
      get: () => api.call({ method: "get", url: `metadata_field_values/` }),
    }),
    [api]
  );
};

export default useMetadataApi;
