import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { GrAdd } from "react-icons/gr";

import { useIsMounted } from "hooks";
import { useExperimentApi } from "apis";
import { Card, Loader } from "../../../common";
import AddGenedataHitLists from "./AddGenedataHitLists";
import GeneDataHitlistTable from "./GeneDataHitlistsTable";

const GeneDataHitlists = ({ experiment, setExperiment, setErrors }) => {
  const experimentApi = useExperimentApi();
  const isMounted = useIsMounted();
  const [showGenedataHitlists, setShowGenedataHitlists] = useState(false);
  const [hitlists, setHitlists] = useState(
    experiment.setup_params.hitlists || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = useCallback(
    (updatedHitlist) => {
      setIsUpdating(true);

      const foundHitlist = hitlists.find(
        (hitlist) =>
          hitlist.project_id === updatedHitlist.project_id &&
          hitlist.hitlist_id === updatedHitlist.hitlist_id
      );

      foundHitlist.platform = updatedHitlist.platform;
      setHitlists(hitlists);
      const payload = {
        setup_params: {
          ...experiment.setup_params,
          hitlists: hitlists,
        },
      };

      experimentApi
        .update(experiment.identifier, payload)
        .catch((error) => {
          isMounted() && setErrors(error.response.data);
        })
        .finally(() => isMounted() && setIsUpdating(false));
    },
    [
      experiment.identifier,
      experiment.setup_params,
      experimentApi,
      hitlists,
      isMounted,
      setErrors,
    ]
  );

  const handleDelete = useCallback(
    (selectedHitlist) => {
      setIsLoading(true);

      const updatedHitlists = hitlists.filter(
        (hitlist) =>
          !(
            hitlist.project_id === selectedHitlist.project_id &&
            hitlist.hitlist_id === selectedHitlist.hitlist_id
          )
      );

      const payload = {
        setup_params: {
          ...experiment.setup_params,
          hitlists: updatedHitlists,
        },
      };

      experimentApi
        .update(experiment.identifier, payload)
        .then((response) => isMounted() && setExperiment(response.data))
        .catch((error) => {
          isMounted() && setErrors(error.response.data);
        })
        .finally(() => setIsLoading(false));
    },
    [
      experiment.identifier,
      experiment.setup_params,
      experimentApi,
      hitlists,
      isMounted,
      setErrors,
      setExperiment,
    ]
  );

  useEffect(() => {
    setHitlists(experiment?.setup_params?.hitlists || []);
  }, [experiment?.setup_params?.hitlists]);

  return (
    <Card
      title="Genedata Hitlists"
      className="add-genedata-hitlists h-100"
      isLoading={isUpdating}
      actions={
        <Button
          onClick={() => {
            setShowGenedataHitlists(true);
          }}
          size="sm"
          className="d-flex align-items-center gr-add"
        >
          <GrAdd className="me-1" />
          Add
        </Button>
      }
    >
      <GeneDataHitlistTable
        hitlists={hitlists}
        onDelete={handleDelete}
        onChange={handleChange}
      />

      {showGenedataHitlists && (
        <AddGenedataHitLists
          onClose={() => setShowGenedataHitlists(false)}
          experiment={experiment}
          setExperiment={setExperiment}
          hitlists={hitlists}
        />
      )}
      <Loader isLoading={isLoading} />
    </Card>
  );
};

export default GeneDataHitlists;
