import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { DefaultModal } from "components/common";
import { PIPELINES } from "config";
import { getDatetimeFormatted } from "utils";
import { getIdsFromSequencePath } from "./utils";

const SequenceDetails = ({ sequence, samples, onClose }) => {
  const [sample, setSample] = useState(null);

  const getSequenceValue = (field) => sequence[field] || "/";

  useEffect(() => {
    if (sequence) {
      const ids = getIdsFromSequencePath(sequence.$path);
      const sample = samples.find(
        (sample) =>
          sample.sample_id === ids.sampleId &&
          sample.experiment.identifier === ids.experimentId &&
          (!sample.lane || sample.lane === ids.lane)
      );
      setSample(sample);
    }
  }, [samples, sequence]);

  return (
    <DefaultModal
      title="Sequence Details"
      onClose={onClose}
      size="lg"
      backdrop="false"
      scrollable
    >
      <dl>
        {!!sample && (
          <>
            <Row>
              <h5>General</h5>
              <Col>
                <dt>Sequence ID</dt>
                <dd>{getSequenceValue("sequence_id")}</dd>
                <dt>Sample ID</dt>
                <dd>{sample.sample_id}</dd>
                <dt>Animal ID</dt>
                <dd>{sample.animal_id}</dd>
                <dt>Genotype</dt>
                <dd>{sample.genotype}</dd>
              </Col>
              <Col>
                <dt>Experiment ID</dt>
                <dd>{sample.experiment.identifier}</dd>
                <dt>Pipeline</dt>
                <dd>
                  {PIPELINES[sample.experiment.pipeline].config.displayName}
                </dd>
                <dt>Completed On</dt>
                <dd>{getDatetimeFormatted(sample.experiment.completed)}</dd>
              </Col>
            </Row>

            <Row className="mt-3">
              <h5>Metadata</h5>
              <Col>
                <dt>Client</dt>
                <dd>{sample.experiment.metadata.client}</dd>
                <dt>Project</dt>
                <dd>{sample.experiment.metadata.project}</dd>
                <dt>Target</dt>
                <dd>{sample.experiment.metadata.target}</dd>
              </Col>
              <Col>
                <dt>Ab Clone Format</dt>
                <dd>{sample.experiment.metadata.ab_clone_format}</dd>
                <dt>Animal Species</dt>
                <dd>{sample.experiment.metadata.animal_species}</dd>
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-3">
          <h5>Sequence</h5>
          <Col>
            <dt>Sequence</dt>
            <dd className="text-break">{getSequenceValue("sequence")}</dd>
            <dt>Sequence Alignment</dt>
            <dd className="text-break">
              {getSequenceValue("sequence_alignment")}
            </dd>
            <dt>Germline Alignment</dt>
            <dd className="text-break">
              {getSequenceValue("germline_alignment")}
            </dd>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <dt>FWR1</dt>
            <dd className="text-break">{getSequenceValue("fwr1")}</dd>
          </Col>
          <Col>
            <dt>FWR1 AA</dt>
            <dd className="text-break">{getSequenceValue("fwr1_aa")}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <dt>FWR2</dt>
            <dd className="text-break">{getSequenceValue("fwr2")}</dd>
          </Col>
          <Col>
            <dt>FWR2 AA</dt>
            <dd className="text-break">{getSequenceValue("fwr2_aa")}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <dt>FWR3</dt>
            <dd className="text-break">{getSequenceValue("fwr3")}</dd>
          </Col>
          <Col>
            <dt>FWR3 AA</dt>
            <dd className="text-break">{getSequenceValue("fwr3_aa")}</dd>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <dt>CDR1</dt>
            <dd className="text-break">{getSequenceValue("cdr1")}</dd>
          </Col>
          <Col>
            <dt>CDR1 AA</dt>
            <dd className="text-break">{getSequenceValue("cdr1_aa")}</dd>
          </Col>
        </Row>
        <Row>
          <Col>
            <dt>CDR2</dt>
            <dd className="text-break">{getSequenceValue("cdr2")}</dd>
          </Col>
          <Col>
            <dt>CDR2 AA</dt>
            <dd className="text-break">{getSequenceValue("cdr2_aa")}</dd>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <dt>CDR3</dt>
            <dd className="text-break">{getSequenceValue("cdr3")}</dd>
          </Col>
          <Col xs="6">
            <dt>CDR3 AA</dt>
            <dd className="text-break">{getSequenceValue("cdr3_aa")}</dd>
          </Col>
          <Col xs="6">
            <dt>CDR3 AA Length</dt>
            <dd className="text-break">{getSequenceValue("cdr3_aa_length")}</dd>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="6">
            <dt>Junction</dt>
            <dd className="text-break">{getSequenceValue("junction")}</dd>
          </Col>
          <Col xs="6">
            <dt>Junction AA</dt>
            <dd className="text-break">{getSequenceValue("junction_aa")}</dd>
          </Col>
          <Col xs="6">
            <dt>Junction Length</dt>
            <dd className="text-break">
              {getSequenceValue("junction_length")}
            </dd>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <dt>J call</dt>
            <dd className="text-break">{getSequenceValue("j_call")}</dd>
            <dt>V call</dt>
            <dd className="text-break">{getSequenceValue("v_call")}</dd>
            <dt>D call</dt>
            <dd className="text-break">{getSequenceValue("d_call")}</dd>
          </Col>
          <Col>
            <dt>Clone ID</dt>
            <dd className="text-break">{getSequenceValue("clone_id")}</dd>
            <dt>Locus</dt>
            <dd className="text-break">{getSequenceValue("locus")}</dd>
            <dt>Lineage</dt>
            <dd className="text-break">{getSequenceValue("lineage")}</dd>
          </Col>
        </Row>
      </dl>
    </DefaultModal>
  );
};

export default SequenceDetails;
