import { useState } from "react";
import { GrDocument, GrStatusWarning, GrTrash } from "react-icons/gr";
import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";

import { useSupportingFilesApi } from "apis";
import { useIsMounted } from "hooks";
import { Card, IconButton } from "components/common";
import AddFiles from "../AddInputFiles/AddInputFiles";
import FieldDescription from "components/common/FormInputs/FieldDescription";
import { PIPELINES, FILE_STATES, FILE_ERROR_MESSAGES } from "config";

const SupportingFileLabel = ({ supportingFile }) => {
  const isAvailable = [FILE_STATES.AVAILABLE, FILE_STATES.RESTORED].includes(
    supportingFile.status
  );
  return (
    <OverlayTrigger
      placement="top"
      overlay={(props) => (
        <Tooltip {...props}>
          {isAvailable
            ? `${supportingFile.path}${supportingFile.filename}`
            : FILE_ERROR_MESSAGES[supportingFile.status]}
        </Tooltip>
      )}
    >
      <small className="text-break d-flex align-items-center">
        {isAvailable ? null : <GrStatusWarning className="text-danger me-2" />}
        {supportingFile.filename}
      </small>
    </OverlayTrigger>
  );
};

const SupportingFiles = ({
  experiment,
  supportingFiles,
  getSupportingFiles,
  setErrors,
  isLoading,
  setIsLoading,
  curPath,
  setCurPath,
}) => {
  const isMounted = useIsMounted();
  const supportingFilesApi = useSupportingFilesApi();
  const [selectedSupportingFile, setSelectedSupportingFile] = useState(null);
  const [showAddFiles, setShowAddFiles] = useState(false);

  const addSupportingFile = (file) => {
    // this function is used while the add files modal is open.
    // Error handling is handled in the component
    return supportingFilesApi.post(
      experiment,
      selectedSupportingFile.param,
      file.path,
      file.filename
    );
  };

  const deleteSupportingFile = (supportingFile) => {
    setIsLoading(true);

    supportingFilesApi
      .delete(supportingFile.id)
      .catch((error) => isMounted() && setErrors(error.response.data))
      .finally(getSupportingFiles)
      .finally(() => isMounted() && setIsLoading(false));
  };

  const getSupportingFile = (param) => {
    return supportingFiles.find(
      (supportingFile) => supportingFile.param === param
    );
  };

  return (
    <Card title="Supporting Files" className="h-100" isLoading={isLoading}>
      <div className="supporting-files">
        <Row>
          <Col>
            {PIPELINES[experiment.pipeline].config.supportingFiles.map(
              (supportingFile) => {
                const addedFile = getSupportingFile(supportingFile.param);
                return (
                  <Row key={supportingFile.param}>
                    <Col>
                      <Col className="d-flex fw-bold">
                        {supportingFile.label}
                        {!!supportingFile.description && (
                          <FieldDescription
                            description={supportingFile.description}
                          ></FieldDescription>
                        )}
                      </Col>
                      {!!addedFile ? (
                        <SupportingFileLabel
                          supportingFile={addedFile}
                        ></SupportingFileLabel>
                      ) : null}
                    </Col>
                    <Col xs="auto" className="px-0">
                      {!!addedFile ? (
                        <IconButton
                          title="Delete"
                          icon={<GrTrash />}
                          onClick={() => deleteSupportingFile(addedFile)}
                        ></IconButton>
                      ) : (
                        <IconButton
                          title="Select"
                          icon={<GrDocument />}
                          onClick={() => {
                            setSelectedSupportingFile(supportingFile);
                            setShowAddFiles(true);
                          }}
                        ></IconButton>
                      )}
                    </Col>
                  </Row>
                );
              }
            )}
          </Col>
        </Row>

        {showAddFiles && (
          <AddFiles
            title="Select Supporting File"
            onFilesAdded={() => getSupportingFiles(false)}
            onClose={() => {
              setShowAddFiles(false);
              setSelectedSupportingFile(null);
            }}
            addFiles={(files) => addSupportingFile(files[0])}
            addedFiles={supportingFiles}
            allowedExtensions={[selectedSupportingFile.allowedExtension]}
            enableMultiSelect={false}
            curPath={curPath}
            setCurPath={setCurPath}
          ></AddFiles>
        )}
      </div>
    </Card>
  );
};

export default SupportingFiles;
