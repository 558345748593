import { Button, Form, Modal } from "react-bootstrap";
import ButtonWithSpinner from "./ButtonWithSpinner";

const DefaultModal = ({
  hasCancel = false,
  onClose,
  title,
  children,
  isLoading = false,
  onSubmit = null,
  submitText = "Confirm",
  cancelText = "Cancel",
  size = "md",
  scrollable = false,
  dialogClassName = null,
  submitDisabled = false,
  backdrop = "static",
}) => {
  return (
    <Modal
      show
      onHide={onClose}
      size={size}
      backdrop={backdrop}
      scrollable={scrollable}
      dialogClassName={dialogClassName}
    >
      <Modal.Header>
        <Modal.Title className="text-capitalize">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form id="default-modal-form" onSubmit={onSubmit ? onSubmit : onClose}>
          {children}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        {hasCancel && (
          <Button variant="light" onClick={onClose} disabled={isLoading}>
            {cancelText}
          </Button>
        )}
        <ButtonWithSpinner
          type="submit"
          form="default-modal-form"
          disabled={submitDisabled}
          isLoading={isLoading}
        >
          {submitText}
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
};

export default DefaultModal;
