import ChartTable from "./ChartTable";

export default function VisualizationsGroupSubTables({
  data: chartGroup,
  onNavigate,
}) {
  if (!chartGroup.charts.length) {
    return false;
  }
  return (
    <>
      {!!chartGroup.charts.length && (
        <div>
          <ChartTable chartGroup={chartGroup} onNavigate={onNavigate} />
        </div>
      )}
    </>
  );
}
