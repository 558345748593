import { useState, useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";

import { useIsMounted } from "hooks";

import "./Header.scss";

export default function Header({ title, headerActions, sidebarCollapsed }) {
  const [scroll, setScroll] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (isMounted()) {
        const scrollCheck = window.scrollY !== 0;
        if (scroll !== scrollCheck) {
          setScroll(scrollCheck);
        }
      }
    });
  });

  return (
    <header
      className={`${sidebarCollapsed ? "sidebar-collapsed" : ""} ${
        scroll ? "scrolled" : ""
      }`}
    >
      <div>
        <div className="d-flex w-100 align-items-center">
          <div className="me-auto">
            <h2 className="my-0">{title}</h2>
          </div>
          <div className="actions d-flex">
            {headerActions.map((action) =>
              action.component ? (
                <Fragment key={action.name}>{action.component}</Fragment>
              ) : (
                <Button
                  key={action.name}
                  onClick={action.onClick}
                  disabled={action.disabled}
                  variant={action.variant || "light"}
                  size={action.size || "md"}
                  className="d-flex justify-content-center align-items-center"
                >
                  {!!action.icon && (
                    <span className={`${!!action.name && "me-2"} d-flex`}>
                      {action.icon}
                    </span>
                  )}
                  {action.name}
                </Button>
              )
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
