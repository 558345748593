import { useMemo } from "react";
import useApi from "./useApi";

const useExperimentApi = () => {
  const api = useApi();

  return useMemo(
    () => ({
      list: (page, size, filters = {}, ordering = []) => {
        let filtersQuery = "";

        if (Object.keys(filters).length) {
          filtersQuery = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        }

        let orderingQuery = "";

        if (ordering.length) {
          orderingQuery = "ordering=";

          orderingQuery += ordering.map(
            (field) => `${field.desc ? "-" : ""}${field.id}`
          );
        }

        let queryString;
        if (page && size) {
          queryString = `?page=${page}&size=${size}&${filtersQuery}&${orderingQuery}`;
        } else {
          queryString = `?${filtersQuery}${
            orderingQuery ? `&${orderingQuery}` : ""
          }`;
        }

        return api.call({
          method: "get",
          url: `experiment/${queryString}`,
        });
      },
      get: (identifier) => {
        return api.call({
          method: "get",
          url: `experiment/${identifier}/`,
        });
      },
      create: (identifier, pipeline, version, defaultParams) => {
        const data = {
          identifier,
          pipeline,
          pipeline_version: version,
          setup_params: defaultParams,
        };
        return api.call({ method: "post", url: "experiment/", data: data });
      },
      delete: (identifier) => {
        return api.call({
          method: "delete",
          url: `experiment/${identifier}/`,
        });
      },
      update: (identifier, data) => {
        return api.call({
          method: "patch",
          url: `experiment/${identifier}/`,
          data,
        });
      },
      start: (identifier) => {
        return api.call({
          method: "post",
          url: `experiment/${identifier}/start/`,
        });
      },
      copy: (identifier, newIdentifier) => {
        const data = { identifier: newIdentifier };
        return api.call({
          method: "post",
          url: `experiment/${identifier}/copy/`,
          data: data,
        });
      },
      archive: (identifier, reason, description) => {
        return api.call({
          method: "post",
          url: `experiment/${identifier}/archive/`,
          data: {
            reason,
            description,
          },
        });
      },
      getSamples: (identifier) =>
        api.call({ method: "get", url: `experiment/${identifier}/samples/` }),
      getSupportingFiles: (identifier) =>
        api.call({
          method: "get",
          url: `experiment/${identifier}/supporting_files/`,
        }),
    }),
    [api]
  );
};

export default useExperimentApi;
