import { useMemo } from "react";
import { GrStatusWarning, GrTrash } from "react-icons/gr";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

import { IconButton, SimpleTable } from "components/common";
import {
  NumberInput,
  SelectInput,
  TextInput,
} from "components/common/FormInputs";
import { FILE_STATES, FILE_ERROR_MESSAGES } from "config";

import "./Samples.scss";

const getLaneAsNumber = (value) => parseInt(value.split("L")[1], 10);

const SamplesTable = ({
  experiment,
  samples,
  onDelete,
  onChange,
  fields,
  metadataFieldValues,
}) => {
  const columns = useMemo(
    () => {
      const columns = [
        {
          id: "file-status",
          accessor: null,
          Cell: ({ row }) => {
            const status = row.original.status;

            const isAvailable = [
              FILE_STATES.AVAILABLE,
              FILE_STATES.RESTORED,
            ].includes(status);

            return isAvailable ? null : (
              <OverlayTrigger
                placement="top"
                overlay={(props) => (
                  <Tooltip {...props} className="description-tooltip">
                    {FILE_ERROR_MESSAGES[status]}
                  </Tooltip>
                )}
              >
                <span className="text-center">
                  <GrStatusWarning className="text-danger" />
                </span>
              </OverlayTrigger>
            );
          },
        },
        {
          Header: "Filename",
          accessor: "filename",
          id: "name",
          Cell: ({ row, value }) => (
            <OverlayTrigger
              placement="top"
              overlay={(props) => (
                <Tooltip {...props} className="description-tooltip">
                  {row.original.path ? `${row.original.path}${value}` : value}
                </Tooltip>
              )}
            >
              <span>{value}</span>
            </OverlayTrigger>
          ),
        },
      ];

      if (experiment.pipeline === "integration") {
        columns.push({
          Header: "Experiment",
          accessor: "path",
          id: "experiment",
          Cell: ({ value }) => value.split("/")[0],
        });
        columns.push({
          Header: "Platform",
          accessor: "platform",
          id: "platform",
          Cell: ({ row, value }) => {
            return (
              <SelectInput
                name="platform"
                apiValue={value}
                size="sm"
                onChange={(name, value) => onChange(row.original, name, value)}
                choices={[
                  { value: "bulk", name: "Bulk" },
                  { value: "xPloration", name: "xPloration" },
                ]}
              />
            );
          },
        });
      } else {
        if (fields.includes("animal_id")) {
          columns.push({
            Header: "Animal ID",
            accessor: "animal_id",
            id: "animal_id",
            Cell: ({ row, value }) => (
              <TextInput
                placeholder="Animal ID"
                name="animal_id"
                apiValue={value}
                size="sm"
                onChange={(name, value) => onChange(row.original, name, value)}
              ></TextInput>
            ),
          });
        }

        if (fields.includes("sample_id")) {
          columns.push({
            Header: "Sample ID",
            accessor: "sample_id",
            id: "sample_id",
            Cell: ({ row, value }) => (
              <TextInput
                placeholder="Sample ID"
                name="sample_id"
                apiValue={value}
                size="sm"
                onChange={(name, value) => onChange(row.original, name, value)}
              ></TextInput>
            ),
          });
        }

        if (fields.includes("genotype")) {
          columns.push({
            Header: "Genotype",
            accessor: "genotype",
            id: "genotype",
            Cell: ({ row, value }) => (
              <SelectInput
                name="genotype"
                apiValue={value}
                size="sm"
                onChange={(name, value) => onChange(row.original, name, value)}
                choices={metadataFieldValues["genotype"] || []}
              />
            ),
          });
        }

        if (fields.includes("lane")) {
          columns.push({
            Header: "Lane",
            accessor: "lane",
            id: "lane",
            Cell: ({ row, value }) => (
              <InputGroup size="sm">
                <InputGroup.Text>L</InputGroup.Text>
                <NumberInput
                  size="sm"
                  name="lane"
                  max={999}
                  min={1}
                  apiValue={value ? getLaneAsNumber(value) : ""}
                  onChange={(name, value) =>
                    onChange(
                      row.original,
                      name,
                      `L${value.toString().padStart(3, "0")}`
                    )
                  }
                ></NumberInput>
              </InputGroup>
            ),
          });
        }

        if (fields.includes("readtype")) {
          columns.push({
            Header: "Read Type",
            accessor: "readtype",
            id: "readtype",
            Cell: ({ row, value }) => (
              <SelectInput
                name="readtype"
                apiValue={value}
                size="sm"
                onChange={(name, value) => onChange(row.original, name, value)}
                choices={[
                  { value: "R1", name: "R1" },
                  { value: "R2", name: "R2" },
                ]}
              />
            ),
          });
        }
      }

      columns.push({
        Header: "Actions",
        accessor: null,
        id: "actions",
        Cell: ({ row }) => {
          return (
            <IconButton
              title="Delete"
              icon={<GrTrash />}
              onClick={() => onDelete(row.original)}
            ></IconButton>
          );
        },
      });
      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [experiment, onChange]
  );

  return (
    <SimpleTable
      columns={columns}
      data={samples}
      emptyMsg="Please add files to experiment"
    ></SimpleTable>
  );
};

export default SamplesTable;
