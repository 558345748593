import { useCallback, useEffect, useRef, useState } from "react";

import { useIsMounted } from "hooks";
import { useExperimentApi, useGenedataApi } from "apis";
import {
  BreadCrumbs,
  DefaultModal,
  ErrorMessages,
  Loader,
} from "components/common";
import AddGenedataHitlistsTable from "./Table";

import "./addGenedataHitLists.scss";

export default function AddGeneDataHilist({
  experiment,
  setExperiment,
  hitlists,
  onClose,
}) {
  const tableRef = useRef();
  const experimentApi = useExperimentApi();
  const isMounted = useIsMounted();
  const genedataApi = useGenedataApi();

  const [projects, setProjects] = useState([]);
  const [curProject, setCurProject] = useState("");
  const [curItems, setCurItems] = useState([]);
  const [hitlistsToAdd, setHitlistsToAdd] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showHitlists, setShowHitlists] = useState(false);

  const getHitlistItems = useCallback(() => {
    if (curProject) {
      setShowHitlists(true);
      let selectedProject = projects.find(
        (project) => project.identifier === curProject
      );

      setCurItems(
        selectedProject.hitlists.map((hitlist) => ({
          project_id: selectedProject.identifier,
          project_name: selectedProject.name,
          hitlist_id: hitlist.identifier,
          hitlist_name: hitlist.name,
          platform: "",
        }))
      );
    } else {
      setShowHitlists(false);
      setCurItems(
        projects.map((project) => ({
          project_id: project.identifier,
          project_name: project.name,
        }))
      );
    }
  }, [curProject, projects]);

  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();
      setIsAdding(true);

      const payload = {
        setup_params: {
          ...experiment.setup_params,
          hitlists: [...hitlists, ...hitlistsToAdd],
        },
      };

      experimentApi
        .update(experiment.identifier, payload)
        .then((response) => {
          isMounted() && setExperiment(response.data);
          onClose();
        })
        .catch((error) => {
          isMounted() && setErrors(error.response.data);
        })
        .finally(() => isMounted() && setIsAdding(false));
    },
    [
      hitlistsToAdd,
      hitlists,
      experiment.setup_params,
      experiment.identifier,
      experimentApi,
      setExperiment,
      onClose,
      isMounted,
    ]
  );

  const isAdded = (hitlist) =>
    hitlists.some(
      (addedHitlist) =>
        addedHitlist.project_id === hitlist.project_id &&
        addedHitlist.hitlist_id === hitlist.hitlist_id
    );

  const handleNavigate = (path) => {
    setCurProject(path);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getHitlistItems, [curProject, projects]);

  useEffect(() => {
    setIsLoading(true);
    genedataApi
      .getProjects()
      .then((response) => {
        isMounted() && setProjects(response.data);
      })
      .catch((error) => {
        isMounted() && setErrors(error.response.data);
      })
      .finally(() => {
        isMounted() && setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultModal
      dialogClassName="add-genedata-modal"
      title="Add Genedata Hitlists"
      isLoading={isAdding}
      onSubmit={handleAdd}
      submitDisabled={!hitlistsToAdd.length}
      submitText="Add"
      onClose={onClose}
      hasCancel={true}
      size="lg"
      scrollable
    >
      <div className="mb-3">
        <BreadCrumbs
          path={curProject}
          onCrumbClicked={handleNavigate}
        ></BreadCrumbs>
      </div>
      <AddGenedataHitlistsTable
        tableRef={tableRef}
        data={curItems}
        onNavigate={handleNavigate}
        setSelectedRows={(changedSelection) =>
          setHitlistsToAdd(changedSelection)
        }
        isAdded={isAdded}
        showHitlists={showHitlists}
      ></AddGenedataHitlistsTable>
      <ErrorMessages errors={errors} className="mt-4"></ErrorMessages>
      <Loader isLoading={isLoading}></Loader>
    </DefaultModal>
  );
}
