import { Card } from "components/common";
import { useIsMounted } from "hooks";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../charts.scss";
import SampleFilter from "../SampleFilter";

const PlotlyWrapper = ({ sampleData, title, description, setIsLoading }) => {
  const isMounted = useIsMounted();
  const [url, setUrl] = useState("");
  const [activeSample, setActiveSample] = useState({});

  useEffect(() => {
    if (Object.keys(activeSample).length > 0) {
      isMounted() && setIsLoading(true);
      setUrl(activeSample.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSample]);

  return (
    <Row>
      <Col>
        <Card title={title} description={description}>
          <Row>
            <SampleFilter setSample={setActiveSample} samples={sampleData} />
          </Row>
          {!!url && (
            <Row>
              <div className="plotly-wrapper">
                <embed
                  onLoad={(e) => setIsLoading(false)}
                  width="750px"
                  src={url}
                  type="text/html"
                  id="obj"
                ></embed>
              </div>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default PlotlyWrapper;
