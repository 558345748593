import BTable from "react-bootstrap/Table";
import { useTable } from "react-table";

export default function SubTable({ columns, data }) {
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <BTable striped responsive size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className={column.id}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps}>
        {(rows.length &&
          rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={cell.column.id}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })) || (
          <tr>
            <td className="text-center nohover" colSpan={columns.length}>
              No records available
            </td>
          </tr>
        )}
      </tbody>
    </BTable>
  );
}
