import { Card } from "components/common";
import StackedBarChart from "components/common/Charts/StackedBarchart/Stackedbarchart";
import useIsMounted from "hooks/useIsMounted";
import { useEffect, useState } from "react";
import Combobox from "react-widgets/Combobox";
import { Button, Col, Row } from "react-bootstrap";
import { CDR_CHOICES, SERIES, colorConfig } from "./config";

import * as Highcharts from "highcharts";
import SampleFilter from "components/common/Charts/SampleFilter";
import DownloadCSVButton from "components/common/DownloadCSVButton/DownloadCSVButton";

import "./AminoAcidStackColumns.scss";

const AminoAcidStackColumns = ({
  sampleData,
  visualization_id,
  description,
  setIsLoading,
}) => {
  const isMounted = useIsMounted();
  const [data, setData] = useState([]);
  const [lineages, setLineages] = useState([]);
  const [activeSample, setActiveSample] = useState({});
  const [activeLineage, setActiveLineage] = useState(null);

  useEffect(() => {
    if (!!Object.keys(activeSample).length) {
      setActiveLineage(null);
      const data = getdataForSample();
      isMounted() && setData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSample]);

  const generateHighchartsConfig = (cdrData) => {
    const lineageData = cdrData.data.filter(
      (x) => Number(x.lineage) === activeLineage
    );
    const hcConfig = {
      title: cdrData.label,
      xAxis: {
        showEmpty: false,
        categories: lineageData.map((x) => {
          return x.imgt_position;
        }),
        title: {
          text: "AA Position",
        },
      },
      yAxis: {
        max: 100,
        min: 0,
        labels: {
          format: "{text}%",
        },
        title: {
          text: "Frequency",
        },
      },
      series: SERIES.map((serie) => {
        const serieData = lineageData.map((sd, index) => {
          return {
            x: index,
            y:
              Number(sd[serie]) === 0
                ? null
                : Math.round(sd[serie] * 100) / 100,
            name: serie,
          };
        });

        return {
          name: serie,
          type: "column",
          data: serieData,
          color: colorConfig[serie],
          borderColor: "#000000",
        };
      }),
    };
    return hcConfig;
  };

  const toggleLegend = () => {
    for (let hc of Highcharts.charts) {
      if (!!hc) {
        hc.update({
          legend: {
            enabled: !hc.legend.display,
          },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: !hc.legend.display,
              },
            },
          },
        });
      }
    }
  };

  const getdataForSample = () => {
    const relevantSampleData = activeSample["data"];
    if (!!relevantSampleData) {
      const cdrmapper = CDR_CHOICES.map((cdr) => {
        return {
          cdr: cdr.value,
          label: cdr.name,
          data: relevantSampleData.filter((x) => x.cdr === cdr.value),
        };
      });
      const AllLineages = cdrmapper[0].data.map((x) => {
        return Number(x.lineage);
      });
      const orderedLineages = AllLineages.sort((a, b) => a - b);
      setLineages([...new Set(orderedLineages)]);
      setIsLoading(false);
      return cdrmapper;
    }
    return [];
  };

  useEffect(() => {
    if (Object.keys(activeSample).length === 0) {
      setActiveSample(sampleData[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveLineage(lineages[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineages]);

  return (
    <Row>
      <Col>
        <Card
          title="Amino Acid Stack Columns"
          description={description}
          actions={
            !!activeLineage && (
              <>
                <DownloadCSVButton
                  data={data.map((d) => d.data).flat(1)}
                  filename={`${visualization_id}_aacolumns_${activeSample["sample_id"]}_${activeSample["lane"]}_${activeSample["experiment_id"]}_lineage${activeLineage}`}
                />
                <Button
                  className="flex-shrink-0 legend-button"
                  size="sm"
                  variant="outline-secondary"
                  onClick={toggleLegend}
                >
                  Toggle Legend
                </Button>
              </>
            )
          }
        >
          {!!data && (
            <Row className="filtercontainer">
              <SampleFilter setSample={setActiveSample} samples={sampleData} />

              <Col md="6" lg="4" xxl="2" className="mb-1">
                {!!activeLineage ? (
                  <>
                    <label>
                      <b>Lineage</b>
                    </label>
                    <Combobox
                      hideCaret
                      data={lineages}
                      defaultValue={activeLineage}
                      onSelect={(e) => {
                        setActiveLineage(e);
                      }}
                    />
                  </>
                ) : (
                  <label>
                    <b>No lineage available</b>
                  </label>
                )}
              </Col>
            </Row>
          )}

          <Row>
            {!!data &&
              !!activeLineage &&
              data.map((cdrData) => {
                if (cdrData.cdr === "cdr3") {
                  return (
                    <Col
                      sm="12"
                      lg="6"
                      xxl="4"
                      key={cdrData.cdr}
                      className="offset-0 offset-lg-3 offset-xxl-0"
                    >
                      <StackedBarChart
                        config={generateHighchartsConfig(cdrData)}
                      ></StackedBarChart>
                    </Col>
                  );
                }
                return (
                  <Col sm="12" lg="6" xxl="4" key={cdrData.cdr}>
                    <StackedBarChart
                      config={generateHighchartsConfig(cdrData)}
                    ></StackedBarChart>
                  </Col>
                );
              })}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default AminoAcidStackColumns;
