import { useMemo } from "react";

import { Table } from "components/common";

const SequencesTable = ({ tableRef, data, onRowClick }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Sequence ID",
        accessor: "sequence_id",
      },
      {
        Header: "CDR3 AA",
        accessor: "cdr3_aa",
      },
      {
        Header: "Experiment ID",
        accessor: "experiment_id",
        Cell: ({ row }) => row.original.$path.split("//")[1].split("/")[1],
      },
      {
        Header: "Sample ID",
        accessor: "sample_id",
        Cell: ({ row }) =>
          row.original.$path.split("//")[1].split("/")[2].split(".")[0],
      },
    ],
    []
  );
  return (
    <Table
      ref={tableRef}
      columns={columns}
      size="sm"
      data={useMemo(() => data, [data])}
      enableGlobalFilter={false}
      defaultPageSize={100}
      enableRowClick={true}
      onRowClick={onRowClick}
      emptyRowMessage={
        <p>
          Add Filters to query the sequence database
          <br />
          Live Search is limited to 2500 results
          <br />
          Export to CSV to get all query results
        </p>
      }
    ></Table>
  );
};

export default SequencesTable;
