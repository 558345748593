import { ListGroup } from "react-bootstrap";
import { Card } from "components/common";
import { METADATA_FIELDS } from "config";

const Metadata = ({ experiment }) => {
  return (
    <Card title="Metadata" className="h-100">
      <ListGroup variant="flush">
        {METADATA_FIELDS.map((field) => (
          <ListGroup.Item key={field.key} className="px-2">
            <h6 className="fw-bold">{field.label}</h6>
            <p className="mb-0">{experiment.metadata[field.key] || "/"}</p>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
};

export default Metadata;
