import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getDatetimeFormatted } from "utils";
import { IconButton, Table } from "components/common";
import StatusBadge from "components/common/StatusBadge";
import { GrTrash } from "react-icons/gr";

export default function VisualizationsGroupTable({
  data,
  onDelete,
  getVisualizations,
  tableRef,
  pageCount,
  totalRowCount,
  user,
  children,
}) {
  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Identifier",
        accessor: "identifier",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <OverlayTrigger
              placement="top"
              overlay={(props) => <Tooltip {...props}>{value}</Tooltip>}
            >
              <span>
                <StatusBadge status={value} />
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        Header: "Updated",
        accessor: "updated",
        Cell: ({ value }) => value && getDatetimeFormatted(value),
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          const chartGroup = row.original;

          return chartGroup.status !== "IN_PROGRESS" &&
            (user.isadmin || row.original.user === user.username) ? (
            <IconButton
              title="Delete"
              icon={<GrTrash />}
              onClick={() => onDelete(chartGroup)}
            ></IconButton>
          ) : null;
        },
      },
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={data}
      subTableDataFields={["charts"]}
      getData={getVisualizations}
      pageCount={pageCount}
      totalRowCount={totalRowCount}
      hover={true}
      serverSideFeatures={true}
      enableToggleRowOnClick={true}
      defaultSortBy={[{ id: "updated", desc: true }]}
    >
      {children}
    </Table>
  );
}
