import { DefaultModal } from "components/common";

import "./PipelineInfoModal.scss";

const PipelineInfoModal = ({ title, onClose, children }) => {
  return (
    <DefaultModal
      title={title}
      onClose={onClose}
      dialogClassName="pipeline-info-modal"
      size="md"
      submitText="Close"
      scrollable
    >
      {children}
    </DefaultModal>
  );
};

export default PipelineInfoModal;
