export const SERIES = [
  "A",
  "R",
  "N",
  "D",
  "C",
  "Q",
  "E",
  "G",
  "H",
  "I",
  "L",
  "K",
  "M",
  "F",
  "P",
  "S",
  "T",
  "W",
  "Y",
  "V",
];

export const CDR_CHOICES = [
  { value: "cdr1", name: "CDR1" },
  { value: "cdr2", name: "CDR2" },
  { value: "cdr3", name: "CDR3" },
];

export const colorConfig = {
  A: "#8CFF8C",
  R: "#00007C",
  N: "#FF7C70",
  D: "#A00042",
  C: "#FFFF70",
  Q: "#FF7042",
  E: "#660000",
  G: "#FFFFFF",
  H: "#7070FF",
  I: "#004C00",
  L: "#525252",
  K: "#4747B8",
  M: "#B8A042",
  F: "#534C42",
  P: "#525252",
  S: "#FF8C4B",
  T: "#B84C00",
  W: "#4F4600",
  Y: "#8C704C",
  V: "#FF8CFF",
};
