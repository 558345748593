import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GrCopy, GrRefresh, GrTrash } from "react-icons/gr";

import { PIPELINES } from "config";
import { useUser } from "hooks";
import {
  Card,
  DefaultModal,
  DeleteArchiveExperiment,
  IconButton,
} from "components/common";
import CopyExperiment from "./CopyExperiment";
import ExperimentStatus from "components/Experiments/ExperimentStatus";
import { getDatetimeFormatted } from "utils";
import { useNavigate } from "react-router-dom";

const Details = ({ experiment, getExperiment }) => {
  const user = useUser();
  const navigate = useNavigate();
  const [showDeleteExperiment, setShowDeleteExperiment] = useState(false);
  const [showCopyExperiment, setShowCopyExperiment] = useState(false);
  const [showTraceback, setShowTraceback] = useState(false);

  const canDelete =
    user.isAdmin || (user.canWrite && user.username === experiment.user);

  return (
    <Card
      title="Experiment Details"
      className="h-100"
      actions={
        <>
          <IconButton
            title="Refresh"
            icon={<GrRefresh />}
            onClick={getExperiment}
          ></IconButton>

          {user.canWrite && (
            <IconButton
              title="Copy"
              icon={<GrCopy />}
              onClick={() => setShowCopyExperiment(true)}
            ></IconButton>
          )}

          {canDelete && (
            <IconButton
              title="Delete"
              icon={<GrTrash />}
              onClick={() => setShowDeleteExperiment(true)}
            ></IconButton>
          )}
        </>
      }
    >
      <Row xs={1} md={2} className="mt-2 mx-2">
        <Col className="mb-2">
          <h6 className="fw-bold">Identifier</h6>
          <p>{experiment.identifier}</p>
        </Col>
        <Col className="mb-2 align-items-center">
          <h6 className="fw-bold">Status</h6>
          <div className="w-75">
            <ExperimentStatus experiment={experiment}></ExperimentStatus>
            {!!experiment.status_traceback && (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setShowTraceback(true);
                }}
                className="ms-2 text-decoration-none"
              >
                Show Traceback
              </a>
            )}
          </div>
        </Col>
        <Col className="mb-2">
          <h6 className="fw-bold">User</h6>
          <p>{experiment.user}</p>
        </Col>
        <Col className="mb-2">
          <h6 className="fw-bold">Pipeline</h6>
          <p>{PIPELINES[experiment.pipeline].config.displayName}</p>
        </Col>
        <Col className="mb-2">
          <h6 className="fw-bold">Created</h6>
          <p>
            {!!experiment.created
              ? getDatetimeFormatted(experiment.created)
              : "/"}
          </p>
        </Col>
        <Col className="mb-2">
          <h6 className="fw-bold">Started</h6>
          <p>
            {!!experiment.started
              ? getDatetimeFormatted(experiment.started)
              : "/"}
          </p>
        </Col>
        <Col className="mb-xs-2 mb-md-0">
          <h6 className="fw-bold">Updated</h6>
          <p>
            {!!experiment.updated
              ? getDatetimeFormatted(experiment.updated)
              : "/"}
          </p>
        </Col>
        <Col>
          <h6 className="fw-bold">Status Updated</h6>
          <p>
            {!!experiment.status_updated
              ? getDatetimeFormatted(experiment.status_updated)
              : "/"}
          </p>
        </Col>
      </Row>

      {showDeleteExperiment && (
        <DeleteArchiveExperiment
          experiment={experiment}
          onCancel={() => setShowDeleteExperiment(false)}
          onDeleted={() => {
            setShowDeleteExperiment(false);
            navigate(`/experiments/`);
          }}
        ></DeleteArchiveExperiment>
      )}

      {showCopyExperiment && (
        <CopyExperiment
          experiment={experiment}
          onClose={() => setShowCopyExperiment(false)}
        ></CopyExperiment>
      )}

      {showTraceback && (
        <DefaultModal
          scrollable
          title="Traceback log"
          size="lg"
          submitText="Close"
          onClose={() => setShowTraceback(false)}
        >
          <pre className="white-space-pre-wrap">
            {experiment.status_traceback}
          </pre>
        </DefaultModal>
      )}
    </Card>
  );
};

export default Details;
