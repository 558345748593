import { RangeInput, SelectInput, TextInput } from "../../../common/FormInputs";

const Param = ({ value = "", paramConfig, onChange, className = "mt-2" }) => {
  switch (paramConfig.type) {
    case "range":
      return (
        <RangeInput
          className={className}
          min={paramConfig.min}
          max={paramConfig.max}
          step={paramConfig.step}
          name={paramConfig.key}
          onChange={onChange}
          label={paramConfig.label}
          apiValue={value}
          description={paramConfig.description}
        ></RangeInput>
      );
    case "select":
      return (
        <SelectInput
          className={className}
          name={paramConfig.key}
          apiValue={value}
          description={paramConfig.description}
          label={paramConfig.label}
          onChange={onChange}
          choices={paramConfig.choices}
        ></SelectInput>
      );
    case "input":
      return (
        <TextInput
          className={className}
          name={paramConfig.key}
          apiValue={value}
          description={paramConfig.description}
          label={paramConfig.label}
          onChange={onChange}
        ></TextInput>
      );
    default:
      return null;
  }
};

export default Param;
