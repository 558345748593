import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import FieldDescription from "./FieldDescription";

const SelectInput = ({
  className = null,
  label = null,
  apiValue,
  name,
  onChange,
  choices,
  description = null,
  inputRef = null,
  size = null,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => setValue(apiValue === null ? "" : apiValue), [apiValue]);
  return (
    <Form.Group className={className}>
      {!!label && (
        <Form.Label>
          <span className="d-flex align-items-center">
            {label}
            {!!description && (
              <FieldDescription description={description}></FieldDescription>
            )}
          </span>
        </Form.Label>
      )}
      <Form.Select
        required
        size={size}
        value={value}
        name={name}
        onChange={(e) => {
          let value = e.target.value;
          try {
            // Try parsing true/false string values to boolean
            value = JSON.parse(e.target.value);
          } catch {}
          setValue(value);
          onChange(e.target.name, value);
        }}
        ref={inputRef}
      >
        {value === "" && (
          <option key="" value="">
            Choose...
          </option>
        )}

        {choices.map((choice) => {
          if (typeof choice === "string") {
            choice = { name: choice, value: choice };
          }
          return (
            <option key={choice.name} value={choice.value}>
              {choice.name}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

export default SelectInput;
