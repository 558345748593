import { Card as BootstrapCard } from "react-bootstrap";
import Spinner from "../Spinner";

import "./Card.scss";

export default function Card({
  title,
  actions = null,
  children = null,
  description = null,
  className = "",
  isLoading = false,
}) {
  return (
    <div className={`card-container ${className}`}>
      <BootstrapCard className="h-100">
        <BootstrapCard.Header className="d-flex align-items-center">
          <BootstrapCard.Title>
            {title}
            {isLoading && <Spinner></Spinner>}
          </BootstrapCard.Title>
          {actions && <div className="card-actions">{actions}</div>}
        </BootstrapCard.Header>
        {description && (
          <BootstrapCard.Text className="mb-0 text-muted card-subtitle">
            {description}
          </BootstrapCard.Text>
        )}
        <BootstrapCard.Body>{children}</BootstrapCard.Body>
      </BootstrapCard>
    </div>
  );
}
