import { useMemo } from "react";
import { IconButton, Table } from "components/common";
import { GrDownload } from "react-icons/gr";
import { PIPELINES } from "config";
import { getDatetimeFormatted } from "utils";

export default function SamplesTable({
  samples,
  getSamples,
  tableRef,
  pageCount,
  totalRowCount,
  isSampleSelected,
  handleToggleRowSelected,
  onDownload,
  selectedSamples,
  enableRowSelection,
}) {
  const columns = useMemo(
    () => [
      {
        Header: "Experiment",
        accessor: "experiment.identifier",
        id: "experiment.identifier",
      },
      {
        Header: "Sample ID",
        accessor: "sample_id",
      },
      {
        Header: "Lane",
        accessor: "lane",
      },
      {
        Header: "Animal ID",
        accessor: "animal_id",
      },
      {
        Header: "Pipeline",
        accessor: "experiment.pipeline",
        Cell: ({ value }) => PIPELINES[value].config.displayName,
      },
      {
        Header: "Target",
        accessor: "experiment.metadata.target",
      },
      {
        Header: "Animal Species",
        accessor: "experiment.metadata.animal_species",
      },
      {
        Header: "Genotype",
        accessor: "genotype",
      },
      {
        Header: "Completed",
        accessor: "experiment.completed",
        Cell: ({ value }) => value && getDatetimeFormatted(value),
      },
      {
        Header: "",
        accessor: null,
        id: "actions",
        Cell: ({ row }) => (
          <IconButton
            title="Download"
            icon={<GrDownload />}
            onClick={() => onDownload(row.original)}
          ></IconButton>
        ),
      },
    ],
    [onDownload]
  );

  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={samples}
      getData={getSamples}
      hover
      serverSideFeatures
      pageCount={pageCount}
      totalRowCount={totalRowCount}
      enableRowSelection={enableRowSelection}
      enableSelectOnClick={enableRowSelection}
      size="sm"
      enableManualSelection={true}
      isSelected={isSampleSelected}
      isSelectable={(sample) =>
        ["bulkAIRR", "scAIRR"].includes(sample.experiment.pipeline)
      }
      handleToggleRowSelected={handleToggleRowSelected}
      enableToggleAll={false}
      selectedRows={selectedSamples}
      defaultSortBy={[{ id: "experiment.completed", desc: true }]}
    />
  );
}
