import { useMemo } from "react";
import useApi from "./useApi";

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

const useSamplesApi = () => {
  const api = useApi();

  return useMemo(
    () => ({
      post: (experiment, path, filename, type, platform) => {
        const data = removeEmpty({
          experiment: experiment.identifier,
          path,
          filename,
          type,
          platform,
        });

        return api.call({
          method: "post",
          url: "sample/",
          data,
        });
      },
      update: (id, data) => {
        return api.call({ method: "patch", url: `sample/${id}/`, data });
      },
      delete: (id) => {
        return api.call({ method: "delete", url: `sample/${id}/` });
      },
    }),
    [api]
  );
};

export default useSamplesApi;
