import { references_species } from "config/common";

const config = {
  displayName: "First Pass Low-throughput BCR",
  params: [
    references_species,
    {
      key: "window_size",
      label: "FilterSeq Window Size",
      type: "range",
      min: 150,
      max: 1000,
      defaultValue: 500,
      description: "The window size for FilterSeq step.",
    },
    {
      key: "assemble_maxerror",
      label: "Assemble Max Error Rate",
      type: "range",
      min: 0,
      max: 1,
      step: 0.1,
      defaultValue: 0.3,
      description: "Max error rate allowed for assemble step.",
    },
    {
      key: "preprocessing.masking_primers.maxerror",
      label: "Primer Masking Max Error Rate",
      type: "range",
      min: 0,
      max: 1,
      step: 0.1,
      defaultValue: 0.3,
      description: "Max error rate allowed for primer masking step.",
    },
    {
      key: "seq_type",
      hidden: true,
      defaultValue: "lt_first_pass",
    },
    {
      key: "receptor",
      hidden: true,
      defaultValue: "bcr",
    },
  ],
  samples: {
    allowedExtensions: ["ab1"],
    allowedFields: ["sample_id", "animal_id", "genotype"],
  },
  supportingFiles: [
    {
      param: "linker_fasta",
      label: "Linker",
      allowedExtension: ".fasta",
      description: "Linker Fasta file",
    },
    {
      param: "linkerR_fasta",
      label: "Reverse Linker",
      allowedExtension: ".fasta",
      description: "Reverse Linker Fasta file",
    },
    {
      param: "lc_fr1_fasta",
      label: "LC FR1",
      allowedExtension: ".fasta",
      description:
        "Framework region 1 of the light chains, which is the start of sequences (primer)",
    },
    {
      param: "ighj_fasta",
      label: "IGHJ",
      allowedExtension: ".fasta",
      description: "The end of the heavy chain variable region",
    },
  ],
  pipelineVersions: ["v1.1", "v1.0", "master"],
  populatesSequenceDb: false,
  extendedView: {
    detail: false,
    configure: false,
  },
};

export default config;
