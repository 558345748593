// When changing this file, please also update the ALLOWED_REFERENCE_SPECIES
// in the backend settings.py file.
export const references_species = {
  key: "species",
  label: "Reference Species",
  type: "select",
  choices: [
    { value: "human", name: "Human" },
    { value: "mouse", name: "Mouse" },
    { value: "rat", name: "Rat" },
    { value: "omnichicken", name: "Omnichicken" },
    { value: "omnimouse", name: "Omnimouse" },
    { value: "bovine", name: "Bovine" },
    { value: "omouse", name: "Omouse" },
  ],
  defaultValue: "human",
  description: "The germline reference species for IgBLAST alignment.",
};

export const skip_downstream_analysis = (description) => ({
  key: "skip_downstream_analysis",
  label: "Post-Processing",
  type: "select",
  defaultValue: false,
  choices: [
    { value: false, name: "Yes" },
    { value: true, name: "No" },
  ],
  description,
});
