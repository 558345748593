import { PIPELINES } from "config";
import { Card } from "components/common";

import "./PipelineOverview.scss";
import { Col, Row } from "react-bootstrap";

const PipelineOverview = ({ pipeline }) => {
  return (
    <Card title={null} className="pipeline-overview">
      <Row className="col">
        <Col className="overview">
          <h6 className="mb-4">Pipeline Overview</h6>
          {PIPELINES[pipeline].overview}
        </Col>
        <Col className="steps col-12 col-lg-auto">
          <h6 className="mb-4">How to initiate your experiment?</h6>
          <ul>
            <li>
              <span className="rounded-circle">1</span>Select preferred params
              via 'Setup'.
            </li>
            <li>
              <span className="rounded-circle">2</span>You might also want to
              specify 'Advanced Settings'.
            </li>
            <li>
              <span className="rounded-circle">3</span>Add samples.
            </li>
            <li>
              <span className="rounded-circle">4</span>Fill in sample
              information if required.
            </li>
            <li>
              <span className="rounded-circle">5</span>Add supporting files if
              required.
            </li>
            <li>
              <span className="rounded-circle">6</span>Start your experiment.
            </li>
            <li>
              <span className="rounded-circle">7</span>Enjoy your coffee while
              you wait.
            </li>
          </ul>
        </Col>
      </Row>
    </Card>
  );
};

export default PipelineOverview;
