import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import * as d3 from "d3";

import { DefaultModal, Loader } from "components/common";
import { DropdownList } from "react-widgets";

export default function MassSequenceCheck({ samples, onSubmit, onCancel }) {
  const [experimentId, setExperimentId] = useState("");
  const [sequences, setSequences] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [experimentIds, setExperimentsIds] = useState([]);

  const onFileInputChange = (e) => {
    setIsLoading(true);
    setSequences([]);
    if (!!e.target.files.length) {
      const file = e.target.files[0];

      if (!file.name.endsWith(".csv")) {
        setErrors({ csvfile: "Only csv files are allowed" });
        return;
      }

      const fr = new FileReader();
      fr.onload = () => {
        // @ts-ignore
        const csvData = d3.csvParse(fr.result);

        setIsLoading(false);

        if (!csvData.columns.includes("cdr3_aa")) {
          setErrors({ csvfile: "Could not find cdr3_aa column in csv file" });
          return;
        }

        setSequences(csvData.map((row) => row.cdr3_aa));
      };

      fr.onerror = () => {
        setIsLoading(false);
        setErrors({ csvfile: "Cannot read file" });
      };

      fr.readAsText(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    onSubmit([
      {
        key: "experiment_id",
        operator: "exclude",
        value: experimentId,
      },
      {
        key: "cdr3_aa",
        operator: "in",
        value: sequences.join(","),
      },
    ]);
  };

  useEffect(() => {
    setExperimentsIds([
      // @ts-ignore
      ...new Set(samples.map((sample) => sample.experiment.identifier)),
    ]);
  }, [samples]);

  return (
    <DefaultModal
      title="Filter with CSV"
      hasCancel={true}
      onClose={onCancel}
      submitDisabled={!experimentId || !sequences.length}
      onSubmit={handleSubmit}
      dialogClassName="mass-sequence-check-modal"
    >
      <p>
        Please provide the experiment to exclude and csv file.
        <br />
        The CSV file needs to have the 'cdr3_aa' column.
      </p>
      <Form.Group className="mb-3">
        <Form.Label>Experiment Identifier</Form.Label>
        <DropdownList
          value={experimentId}
          data={experimentIds}
          onChange={(value) => setExperimentId(value)}
        ></DropdownList>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>CSV File</Form.Label>
        <Form.Control
          type="file"
          placeholder="csv file"
          accept=".csv"
          isInvalid={!!errors.csvfile}
          onChange={onFileInputChange}
        />
        {errors.csvfile && (
          <Form.Control.Feedback type="invalid">
            {errors.csvfile}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label>Selected CDR3 AA Values</Form.Label>
        <div className="sequences-container">
          <Form.Control
            as="textarea"
            readOnly
            rows={10}
            value={sequences.join("\n")}
          ></Form.Control>
          <Loader isLoading={isLoading}></Loader>
        </div>
      </Form.Group>
    </DefaultModal>
  );
}
