import { useAsyncDebounce } from "react-table";
import { Form, InputGroup } from "react-bootstrap";
import { GrSearch } from "react-icons/gr";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { DatePicker } from "react-widgets/cjs";
import "./Visualizations.scss";

const getUTCDate = (date, end = false) => {
  let utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getMonth(),
    date.getDate()
  );

  utcDate = new Date(utcDate);

  if (end) {
    utcDate.setUTCHours(23, 59, 59, 999);
  }

  return utcDate.toISOString();
};

const VisualizationsGroupFilters = ({ filters, setFilters, setErrors }) => {
  const [search, setSearch] = useState(null);
  const [updatedAfter, setUpdatedAfter] = useState(
    filters.updated_after ? new Date(filters.updated_after) : null
  );
  const [updatedBefore, setUpdatedBefore] = useState(
    filters.updated_before ? new Date(filters.updated_before) : null
  );

  const onChange = useAsyncDebounce((key, value) => {
    const newFilters = { ...filters };
    value ? (newFilters[key] = value) : delete newFilters[key];
    setFilters({ ...newFilters });
  }, 500);

  const resetFilters = () => {
    setFilters({});
    setSearch(null);
    setUpdatedAfter(null);
    setUpdatedBefore(null);
  };

  const statusOptions = ["CREATED", "IN_PROGRESS", "COMPLETED", "ERROR"].map(
    (status) => {
      return (
        <option key={status} value={status}>
          {status}
        </option>
      );
    }
  );

  return (
    <Row className="mb-3">
      <Col xs="12" xxl="6" className="mb-2 mb-xxl-0">
        <InputGroup>
          <InputGroup.Text>
            <GrSearch />
          </InputGroup.Text>
          <Form.Control
            type="search"
            placeholder="Search Visualizations"
            value={search || ""}
            onChange={(e) => {
              setSearch(e.target.value);
              onChange("identifier_contains", e.target.value);
            }}
          ></Form.Control>
        </InputGroup>
      </Col>

      <Col>
        <Form.Select
          value={filters.status || ""}
          onChange={(e) => onChange("status", e.target.value)}
          className="filter-status"
        >
          {!filters.status && (
            <option key="" value="">
              Status
            </option>
          )}

          {statusOptions}
        </Form.Select>
      </Col>
      <Col className="col-12 col-lg-6 col-xxl mt-2 mt-lg-0">
        <DatePicker
          placeholder="Start Date"
          value={updatedAfter}
          onChange={(changedDate) => {
            setUpdatedAfter(changedDate);
            onChange(
              "updated_after",
              changedDate ? getUTCDate(changedDate) : null
            );
          }}
        />
      </Col>

      <Col className="mt-2 mt-xxl-0 col-12 col-lg-6 col-xxl">
        <DatePicker
          placeholder="End Date"
          value={updatedBefore}
          onChange={(changedDate) => {
            setUpdatedBefore(changedDate);
            onChange(
              "updated_before",
              changedDate ? getUTCDate(changedDate, true) : null
            );
          }}
        />
      </Col>

      <Col xs="auto" className="mt-2 mt-xxl-0">
        <Button
          onClick={resetFilters}
          variant="outline-secondary"
          className="d-flex align-items-center  reset-filters-btn"
        >
          Reset Filters
        </Button>
      </Col>
    </Row>
  );
};

export default VisualizationsGroupFilters;
