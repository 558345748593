import { forwardRef, useRef, useEffect } from "react";
const SelectionCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="form-check mb-0">
      <input
        className="form-check-input"
        type="checkbox"
        value=""
        ref={resolvedRef}
        {...rest}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
});

export default SelectionCheckbox;
