import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./IconButton.scss";

export default function IconButton({
  icon,
  onClick,
  title = null,
  disabled = false,
  className = "",
  notification = 0,
}) {
  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        !disabled && onClick();
      }}
      disabled={disabled}
      className={`icon-btn-container ${className} position-relative`}
    >
      {!!title ? (
        <OverlayTrigger placement="top" overlay={<Tooltip>{title}</Tooltip>}>
          {({ ref, ...triggerHandler }) => (
            <span className="icon-btn" {...triggerHandler} ref={ref}>
              {icon}
            </span>
          )}
        </OverlayTrigger>
      ) : (
        <span className="icon-btn">{icon}</span>
      )}
      {!!notification && (
        <span className="badge bg-danger">{notification}</span>
      )}
    </a>
  );
}
