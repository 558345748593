import { useUser } from "hooks";
import { Button, Card, Col, Row } from "react-bootstrap";

import "./PipelineCard.scss";

const PipelineCard = ({
  pipeline,
  children = null,
  onLearnMore,
  onStartExperiment,
}) => {
  const user = useUser();

  return (
    <Card className="pipeline-card">
      <Card.Header>
        <Card.Title>{pipeline}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between">
        <div>
          {children}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onLearnMore();
            }}
            className="small text-secondary"
          >
            Learn More...
          </a>
        </div>

        {user.canWrite && (
          <Row className="mt-4 justify-content-center">
            <Col xs="auto">
              <Button
                variant="outline-secondary"
                size="lg"
                className="d-block w-100"
                onClick={(e) => {
                  e.preventDefault();
                  onStartExperiment();
                }}
              >
                Start Experiment
              </Button>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default PipelineCard;
