import { get, groupBy } from "lodash";
import {
  PIPELINES,
  METADATA_FIELDS,
  FILE_STATES,
  FILE_ERROR_MESSAGES,
} from "../../../config";

const validateMetadata = (experiment, errors) => {
  const invalid = METADATA_FIELDS.some(
    (field) => !experiment.metadata[field.key]
  );

  if (invalid) {
    errors[`metadata`] = `Not all metadata filled in`;
  }
};

const validateSetupParams = (experiment, config, errors) => {
  const invalid = config.params.some(
    (param) => get(experiment.setup_params, param.key) === ""
  );

  if (invalid) {
    errors[`setup`] = `Not all setup params filled in`;
  }
};

const validateSupportingFiles = (suppportingFiles, config, errors) => {
  config.supportingFiles.forEach((fileConfig) => {
    const supportingFile = suppportingFiles.find(
      (file) => file.param === fileConfig.param
    );

    if (!supportingFile) {
      errors[fileConfig.label] = `'${fileConfig.label}' file not selected`;
    } else if (
      ![FILE_STATES.AVAILABLE, FILE_STATES.RESTORED].includes(
        supportingFile.status
      )
    ) {
      errors[supportingFile.filename] = `${supportingFile.filename}: ${
        FILE_ERROR_MESSAGES[supportingFile.status]
      }`;
    }
  });
};

const doGeneralSampleValidation = (
  samples,
  errors,
  fields,
  allow_empty = false
) => {
  if (!samples.length) {
    if (!allow_empty) {
      errors["samples"] = "No samples added";
    }

    return;
  }

  let allSamplesAvailable = true;
  samples.forEach((sample) => {
    if (
      ![FILE_STATES.AVAILABLE, FILE_STATES.RESTORED].includes(sample.status)
    ) {
      allSamplesAvailable = false;
      errors[sample.filename] = `${sample.filename}: ${
        FILE_ERROR_MESSAGES[sample.status]
      }`;
    }
  });

  if (!allSamplesAvailable) return;

  samples.forEach((sample) => {
    if (fields.includes("sample_id") && sample.sample_id.includes(" ")) {
      errors[
        `${sample.filename}-${sample.path}-sample_id`
      ] = `'${sample.filename}': Sample ID cannot contain spaces. `;
    }

    if (fields.includes("animal_id") && sample.animal_id.includes(" ")) {
      errors[
        `${sample.filename}-${sample.path}-animal_id`
      ] = `'${sample.filename}': Animal ID cannot contain spaces. `;
    }

    if (fields.includes("sample_id") && !sample.sample_id) {
      errors[
        `${sample.filename}-${sample.path}-sample_id-blank`
      ] = `'${sample.filename}': Sample ID cannot be blank. `;
    }

    if (fields.includes("animal_id") && !sample.animal_id) {
      errors[
        `${sample.filename}-${sample.path}-animal_id-blank`
      ] = `'${sample.filename}': Animal ID cannot be blank. `;
    }

    if (fields.includes("lane") && !sample.lane) {
      errors[
        `${sample.filename}-${sample.path}-lane-blank`
      ] = `'${sample.filename}': Lane cannot be blank. `;
    }

    if (fields.includes("readtype") && !sample.readtype) {
      errors[
        `${sample.filename}-${sample.path}-readtype-blank`
      ] = `'${sample.filename}': Readtype cannot be blank. `;
    }

    if (fields.includes("genotype") && !sample.genotype) {
      errors[
        `${sample.filename}-${sample.path}-genotype`
      ] = `'${sample.filename}': Genotype cannot be blank. `;
    }

    if (fields.includes("platform") && !sample.platform) {
      errors[
        `${sample.filename}-${sample.path}-platform`
      ] = `'${sample.filename}': Platform cannot be blank`;
    }
  });
};

const validateFastQSamples = (samples, errors) => {
  doGeneralSampleValidation(samples, errors, [
    "sample_id",
    "animal_id",
    "genotype",
    "lane",
    "readtype",
  ]);

  // If there are general sample errors, don't continue
  if (Object.keys(errors).length) return;

  samples.forEach((sample) => {
    if (sample.filename.endsWith("fastq.gz")) {
      if (!sample.lane || !sample.readtype) {
        errors[
          `${sample.filename}-tags`
        ] = `'${sample.filename}': Lane and readtype cannot be blank. `;
      }
    }
  });

  const groups = groupBy(samples, (sample) =>
    [sample.lane, sample.sample_id].join(",")
  );

  for (let groupName in groups) {
    const readTypes = groups[groupName].map((sample) => sample.readtype);

    if (
      !(readTypes.length === 2) ||
      !(readTypes.includes("R1") && readTypes.includes("R2"))
    ) {
      errors[
        `incorrect-sample-groups`
      ] = `Per Lane and Sample ID there must be exactly one R1 and one R2 file`;
      break;
    }
  }
};

const validateAb1Samples = (experiment, samples, errors) => {
  doGeneralSampleValidation(samples, errors, [
    "sample_id",
    "animal_id",
    "genotype",
  ]);

  // If there are general sample errors, don't continue
  if (Object.keys(errors).length) return;

  // Check if samples is a multiple of n_primers
  if (["ltAIRR", "fpltAIRR"].includes(experiment.pipeline)) {
    const n_primers = experiment.pipeline === "ltAIRR" ? 3 : 2;
    if (samples.length % n_primers !== 0) {
      errors[
        `samples`
      ] = `Amount of samples must be a multiple of ${n_primers}`;
    }
  }
};

const validateIntegrationSamples = (experiment, samples, errors) => {
  doGeneralSampleValidation(samples, errors, ["platform"], true);

  // If there are general sample errors, don't continue
  if (Object.keys(errors).length) return;

  // Validate that there is atleast one sample or hitlist added
  const hitlists = experiment.setup_params?.hitlists || [];

  if (!hitlists.length && !samples.length) {
    errors[
      `${experiment.filename}-samples-hitlists`
    ] = `At least 1 sample or hitlist is required.`;
  }

  // validate that all hitlists have a platform
  hitlists.forEach((hitlist) => {
    if (!hitlist.platform) {
      errors[
        `${hitlist.hitlist_id}-platform`
      ] = `'${hitlist.project_id}_${hitlist.hitlist_id}': Platform cannot be blank`;
    }
  });
};

const validateExperiment = (
  experiment,
  samples,
  supportingFiles,
  metadataEnabled
) => {
  const errors = {};
  const config = PIPELINES[experiment.pipeline].config;

  metadataEnabled && validateMetadata(experiment, errors);
  validateSetupParams(experiment, config, errors);
  validateSupportingFiles(supportingFiles, config, errors);

  if (experiment.pipeline === "integration") {
    validateIntegrationSamples(experiment, samples, errors);
  } else if (["ltAIRR", "fpltAIRR"].includes(experiment.pipeline)) {
    validateAb1Samples(experiment, samples, errors);
  } else {
    validateFastQSamples(samples, errors);
  }

  return !Object.keys(errors).length
    ? Promise.resolve()
    : Promise.reject(errors);
};

export default validateExperiment;
