import { useMemo } from "react";
import { GrTrash } from "react-icons/gr";

import { IconButton, SimpleTable } from "components/common";

import "./GeneDataHitlists.scss";
import { SelectInput } from "components/common/FormInputs";

const GeneDataHitlistsTable = ({ hitlists, onDelete, onChange }) => {
  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Project ID",
        accessor: "project_id",
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Hitlist ID",
        accessor: "hitlist_id",
      },
      {
        Header: "Hitlist Name",
        accessor: "hitlist_name",
      },
      {
        Header: "Platform",
        accessor: "platform",
        id: "platform",
        Cell: ({ row, value }) => {
          value = value || "";
          return (
            <SelectInput
              name="platform"
              apiValue={value}
              size="sm"
              onChange={(name, value) =>
                onChange({ ...row.original, [name]: value })
              }
              choices={[
                { value: "bulk", name: "Bulk" },
                { value: "xPloration", name: "xPloration" },
              ]}
            />
          );
        },
      },
      {
        Header: "Actions",
        accessor: null,
        id: "actions",
        Cell: ({ row }) => {
          return (
            <IconButton
              title="Delete"
              icon={<GrTrash />}
              onClick={() => onDelete(row.original)}
            />
          );
        },
      },
    ];

    return columns;
  }, [onChange, onDelete]);

  return (
    <SimpleTable
      columns={columns}
      data={hitlists}
      emptyMsg="Please add hitlists to this experiment"
    />
  );
};

export default GeneDataHitlistsTable;
