import { DefaultModal } from "components/common";
import { ListGroup } from "react-bootstrap";

const ConfirmOverwrite = ({ existingFiles, uploads, onConfirm, onCancel }) => {
  return (
    <DefaultModal
      title="Confirm Overwrite Files"
      scrollable
      hasCancel
      onClose={onCancel}
      onSubmit={(e) => {
        e.preventDefault();
        onConfirm();
      }}
    >
      <p>Are you sure you want to overwrite the following files?</p>

      <ListGroup variant="flush">
        {existingFiles
          .filter((existingFile) =>
            Object.values(uploads).some(
              (upload) => upload.file.name === existingFile.filename
            )
          )
          .map((object) => (
            <ListGroup.Item key={object.filename}>
              {object.filename}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </DefaultModal>
  );
};

export default ConfirmOverwrite;
