import { Row, Col } from "react-bootstrap";
import { GrStatusWarning } from "react-icons/gr";

import DefaultModal from "./DefaultModal";
import ErrorMessages from "./ErrorMessages";

export default function ConfirmDelete({
  name,
  resourceType,
  isLoading,
  onCancel,
  onConfirm,
  deleteWarning = null,
  errors = null,
  action = "delete",
}) {
  return (
    <DefaultModal
      title={`Confirm ${action}`}
      isLoading={isLoading}
      onClose={onCancel}
      onSubmit={onConfirm}
      hasCancel={true}
    >
      {deleteWarning && (
        <Row className="mb-3">
          <Col>
            <p className="mb-0">
              <GrStatusWarning className="mr-2" />
              {deleteWarning}
            </p>
          </Col>
        </Row>
      )}

      <p>
        Are you sure you want to {action} {resourceType ? resourceType : ""} '
        {name}'?
      </p>

      <ErrorMessages errors={errors} className="mt-4"></ErrorMessages>
    </DefaultModal>
  );
}
