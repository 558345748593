import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";
import FieldDescription from "./FieldDescription";

const TextInput = ({
  className = null,
  size = null,
  name,
  label = null,
  placeholder = null,
  description = null,
  onChange,
  apiValue,
  inputRef = null,
}) => {
  const [value, setValue] = useState(apiValue);

  const handleChangeDebounced = useAsyncDebounce(
    (value) => onChange(name, value),
    500
  );

  useEffect(() => !!apiValue && setValue(apiValue), [apiValue]);

  return (
    <Form.Group className={className}>
      {!!label && (
        <Form.Label className="d-flex align-items-center">
          {label}
          {!!description && (
            <FieldDescription description={description}></FieldDescription>
          )}
        </Form.Label>
      )}
      <Form.Control
        ref={inputRef}
        required
        name={name}
        size={size}
        value={value}
        placeholder={placeholder || label}
        onChange={(e) => {
          setValue(e.target.value);
          handleChangeDebounced(e.target.value);
        }}
      ></Form.Control>
    </Form.Group>
  );
};

export default TextInput;
