import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import HC_brokenAxis from "highcharts/modules/broken-axis";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HC_more from "highcharts/highcharts-more";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { useEffect, useState } from "react";

import "../charts.scss";

exporting(Highcharts);
HC_brokenAxis(Highcharts);
HC_more(Highcharts);
highchartsAccessibility(Highcharts);
NoDataToDisplay(Highcharts);

const ScatterPlot = ({ config }) => {
  const [options, setOptions] = useState({});

  const fetchScatterPlotData = () => {
    const options = {
      chart: {
        type: "bubble",
        plotBorderWidth: 1,
        zoomType: "xy",
      },
      title: {
        text: config.title,
      },
      legend: !!config.legend
        ? config.legend
        : {
            enabled: false,
          },

      xAxis: !!config.xAxis
        ? config.xAxis
        : {
            showEmpty: false,
            categories: config.columns,
          },
      yAxis: !!config.yAxis
        ? config.yAxis
        : {
            labels: {
              format: "{text}%",
            },
            title: {
              text: "Percentage",
            },
          },
      plotOptions: config.plotOptions,
      series: config.series ? config.series : [],
      credits: {
        enabled: false,
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    fetchScatterPlotData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      loader={true}
      containerProps={{ className: "full-width-chart-container" }}
    />
  );
};

export default ScatterPlot;
