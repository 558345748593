import { Spinner as BootstrapSpinner } from "react-bootstrap";

export default function Spinner({
  className = null,
  as = "div",
  size = "md",
  message = null,
}) {
  return (
    <div className={`spinner-container ${className}`}>
      <BootstrapSpinner animation="border" role="status" as={as} size={size}>
        <span className="visually-hidden">
          {message ? message : "Loading..."}
        </span>
      </BootstrapSpinner>
      {message && <p className="spinner-message">{message}</p>}
    </div>
  );
}
