import { Col, Row } from "react-bootstrap";

export const ExtendedView = ({
  metadataEnabled,
  Metadata,
  Setup,
  Samples,
  showSupportingFiles,
  SupportingFiles,
}) => {
  return (
    <Row>
      <Col md="12">
        <Row>
          {metadataEnabled && (
            <Col xs="12" xxl="3" className="mt-4">
              {Metadata}
            </Col>
          )}
          <Col xs="12" xxl={metadataEnabled ? 9 : 12} className="mt-4">
            {Setup}
          </Col>
        </Row>
      </Col>
      <Col xs="12" xxl="12">
        <Row>
          <Col className="mt-4">{Samples}</Col>
          {showSupportingFiles && (
            <Col xs="12" xxl="3" className="mt-4">
              {SupportingFiles}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export const CompactView = ({
  metadataEnabled,
  Metadata,
  Setup,
  Samples,
  showSupportingFiles,
  SupportingFiles,
  showGeneDataHitlists,
  GeneDataHitlists,
}) => {
  return (
    <Row>
      <Col xs="12" xxl={metadataEnabled ? 4 : 3}>
        <Row>
          {metadataEnabled && (
            <Col xs="12" xxl="6" className="mt-4">
              {Metadata}
            </Col>
          )}
          <Col xs="12" xxl={metadataEnabled ? 6 : 12} className="mt-4">
            {Setup}
          </Col>
          {showSupportingFiles && (
            <Col xs="12" className="mt-4">
              {SupportingFiles}
            </Col>
          )}
        </Row>
      </Col>
      <Col xs="12" xxl={metadataEnabled ? 8 : 9}>
        <Col xs="12" className="mt-4">
          {Samples}
        </Col>
        {showGeneDataHitlists && (
          <Col xs="12" className="mt-4">
            {GeneDataHitlists}
          </Col>
        )}
      </Col>
    </Row>
  );
};
