import { useMemo } from "react";

import useApi from "./useApi";

const useVisualizationApi = () => {
  const api = useApi();
  return useMemo(
    () => ({
      listSamples: (page, size, filters = {}, ordering = []) => {
        let filtersQuery = "";

        if (Object.keys(filters).length) {
          filtersQuery = Object.entries(filters)
            .map(([key, value]) => {
              if (["sample_id", "animal_id", "experiment_id"].includes(key)) {
                key = `${key}_contains`;
              }
              return `${key}=${value}`;
            })
            .join("&");
        }

        let orderingQuery = "";

        if (ordering.length) {
          orderingQuery = "&ordering=";

          orderingQuery += ordering.map(
            (field) =>
              `${field.desc ? "-" : ""}${field.id.replaceAll(".", "__")}`
          );
        }

        let queryString;
        if (page && size) {
          queryString = `?page=${page}&size=${size}${
            filtersQuery ? `&${filtersQuery}` : ""
          }${orderingQuery}`;
        } else {
          queryString = `?${filtersQuery}${
            orderingQuery ? `&${orderingQuery}` : ""
          }`;
        }

        return api.call({
          method: "get",
          url: `visualization/samples/${queryString}`,
        });
      },
      listVisualizations: (page, size, filters = {}, ordering = []) => {
        let filtersQuery = "";

        if (!!Object.keys(filters).length) {
          filtersQuery = Object.entries(filters)
            .map(([key, value]) => `&${key}=${value}`)
            .join("");
        }
        let orderingQuery = "";

        if (ordering.length) {
          orderingQuery = "&ordering=";

          orderingQuery += ordering.map(
            (field) => `${field.desc ? "-" : ""}${field.id}`
          );
        }
        return api.call({
          method: "get",
          url: `visualization/groups/?page=${page}&size=${size}${filtersQuery}${orderingQuery}`,
        });
      },
      getChart: (visualizationgroup_id) => {
        return api.call({
          method: "get",
          url: `visualization/groups/${visualizationgroup_id}`,
        });
      },
      delete: (visualizationgroup_id) => {
        return api.call({
          method: "delete",
          url: `visualization/groups/${visualizationgroup_id}/`,
        });
      },
      createVisualization: (chartTypes, selectedSamples, groupName) => {
        const data = {
          identifier: groupName,
          samples: selectedSamples.map((s) => {
            return {
              sample_id: s.sample_id,
              experiment_id: s.experiment.identifier,
              lane: s.lane,
            };
          }),
          chart_types: chartTypes,
        };
        return api.call({
          method: "post",
          url: `visualization/groups/`,
          data: data,
        });
      },
    }),
    [api]
  );
};

export default useVisualizationApi;
