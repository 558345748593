import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { DefaultModal } from "components/common";
import { useNavigate } from "react-router-dom";
import { CHARTS } from "config";
import { useVisualizationApi } from "apis";
import { useIsMounted } from "hooks";

const CreateVisualization = ({ samples, onClose }) => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const visualizationApi = useVisualizationApi();
  const [charts, setCharts] = useState([]);
  const [groupName, setGroupName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const toggleCheckbox = (checked, value) => {
    if (!!checked && !charts.includes(value)) {
      setCharts((oldcharts) => [...oldcharts, value]);
    } else if (charts.includes(value)) {
      const newArray = charts.filter((m) => m !== value);
      setCharts(newArray);
    }
  };

  const createVisualizations = () => {
    setIsLoading(true);
    setErrors({});
    visualizationApi
      .createVisualization(charts, samples, groupName)
      .then(() => {
        onClose();
        navigate("/visualizations");
      })
      .catch((error) => {
        isMounted() && setErrors(error.response.data);
      })
      .finally(() => isMounted() && setIsLoading(false));
  };

  return (
    <DefaultModal
      title="Create Visualization"
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault();
        createVisualizations();
      }}
      hasCancel={true}
      isLoading={isLoading}
      submitText="Create"
      scrollable
    >
      <div className="mb-3">
        <Form.Group className="mb-3" controlId="createForm.pipeline">
          <Form.Label>
            <h5>Identifier</h5>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Visualization group name"
            required={true}
            onChange={(e) => {
              setGroupName(e.target.value);
              setErrors({});
            }}
            isInvalid={!!errors.identifier}
          />

          {errors.identifier && (
            <Form.Control.Feedback type="invalid">
              {errors.identifier}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label className="m-0">
            <h5>Charts</h5>
          </Form.Label>
          {errors.chart_types && (
            <span className="invalid_charts">{errors.chart_types}</span>
          )}
          {Object.keys(CHARTS).map((chartKey) => {
            const chart = CHARTS[chartKey];
            if (
              !!chart.minimum_samples &&
              [...new Set(samples.map((s) => s.sample_id))].length <
                chart.minimum_samples
            ) {
              return (
                <Form.Check
                  key={chartKey}
                  disabled
                  type="checkbox"
                  label={`${chart.title} (minimum ${chart.minimum_samples} different samples required)`}
                  name={chart.title}
                  id={`disabled-default-${chartKey}`}
                />
              );
            }
            return (
              <Form.Check
                key={chartKey}
                type="checkbox"
                label={chart.title}
                name={chart.title}
                onChange={(e) => {
                  toggleCheckbox(e.target.checked, chartKey);
                }}
                id={`default-${chartKey}-${Object.keys(CHARTS).indexOf(
                  chartKey
                )}`}
              />
            );
          })}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>
            <h5>Selected Samples</h5>
          </Form.Label>
          <Row>
            <Col md="auto">
              <Row>
                <Col>
                  <b>Sample ID</b>
                </Col>
              </Row>
              {samples.map((sample) => {
                return (
                  <Row
                    key={`${sample.experiment.identifier} - ${sample.sample_id} - ${sample.lane}`}
                  >
                    <Col>{sample.sample_id}</Col>
                  </Row>
                );
              })}
            </Col>
            <Col md="auto">
              <Row className="row-cols-1">
                <Col>
                  <b>Lane</b>
                </Col>
              </Row>
              {samples.map((sample) => {
                return (
                  <Row
                    key={`${sample.experiment.identifier} - ${sample.sample_id} - ${sample.lane}`}
                  >
                    <Col>{sample.lane}</Col>
                  </Row>
                );
              })}
            </Col>
            <Col>
              <Row className="row-cols-1">
                <Col>
                  <b>Experiment ID</b>
                </Col>
              </Row>
              {samples.map((sample) => {
                return (
                  <Row
                    key={`${sample.experiment.identifier} - ${sample.sample_id} - ${sample.lane}`}
                  >
                    <Col>{sample.experiment.identifier}</Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Form.Group>
      </div>
    </DefaultModal>
  );
};

export default CreateVisualization;
