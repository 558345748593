import { useContext, useEffect } from "react";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import Footer from "components/Footer";
import { Loader } from "components/common";
import { sidebarCollapsedContext } from "contexts";

import "./MainWrapper.scss";

const SIDEBAR_COLLAPSED_KEY = "OMEX_SIDEBAR_COLLAPSED";

const MainWrapper = ({
  title,
  headerActions = [],
  children = null,
  contentClass = "",
  isLoading = false,
}) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useContext(
    sidebarCollapsedContext
  );

  useEffect(() => {
    setSidebarCollapsed(
      !!JSON.parse(localStorage.getItem(SIDEBAR_COLLAPSED_KEY))
    );
  }, [setSidebarCollapsed]);

  const toggleSidebarCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    localStorage.setItem(SIDEBAR_COLLAPSED_KEY, (!sidebarCollapsed).toString());
  };

  return (
    <div id="wrapper">
      <Sidebar
        sidebarCollapsed={sidebarCollapsed}
        toggleSidebarCollapse={toggleSidebarCollapse}
      ></Sidebar>
      <div
        className={`content-wrapper ${
          sidebarCollapsed ? "sidebar-collapsed" : ""
        }`}
      >
        <Header
          title={title}
          headerActions={headerActions}
          sidebarCollapsed={sidebarCollapsed}
        ></Header>
        <div id="content" className="pt-4">
          <div id="content-inner" className={contentClass}>
            {children}
          </div>
          <Loader isLoading={isLoading} fixed={true} />
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default MainWrapper;
