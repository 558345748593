import { useMemo } from "react";
import { GrTrash } from "react-icons/gr";

import { PIPELINES } from "config";
import { IconButton, Table } from "../common";
import { useUser } from "hooks";
import ExperimentStatus from "./ExperimentStatus";
import { getDatetimeFormatted } from "utils";

export default function ExperimentsTable({
  data,
  onDelete,
  handleExperimentClicked,
  getExperiments,
  tableRef,
  pageCount,
  totalRowCount,
}) {
  const user = useUser();
  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Identifier",
        accessor: "identifier",
        id: "name",
      },
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Pipeline",
        accessor: "pipeline",
        Cell: ({ value }) => PIPELINES[value].config.displayName,
      },
      {
        Header: "Status",
        accessor: "status_pipeline",
        Cell: ({ row }) => (
          <ExperimentStatus experiment={row.original}></ExperimentStatus>
        ),
      },
      {
        Header: "Updated",
        accessor: "updated",
        Cell: ({ value }) => value && getDatetimeFormatted(value),
      },
    ];

    if (user.canWrite) {
      // @ts-ignore
      columns.push({
        Header: "",
        accessor: null,
        id: "actions",
        Cell: ({ row }) => {
          if (user.isAdmin || row.original.user === user.username) {
            return (
              <IconButton
                title="Delete"
                icon={<GrTrash />}
                onClick={() => onDelete(row.original)}
              ></IconButton>
            );
          }

          return null;
        },
      });
    }
    return columns;
  }, [onDelete, user]);

  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={data}
      getData={getExperiments}
      hover={true}
      enableRowClick={true}
      onRowClick={handleExperimentClicked}
      serverSideFeatures={true}
      pageCount={pageCount}
      totalRowCount={totalRowCount}
      defaultSortBy={[{ id: "updated", desc: true }]}
    />
  );
}
