const IntegrationDescrShort = () => {
  return (
    <p>
      This is a bioinformatics workflow used to integrate sequences from Bulk
      BCR, single-cell BCR, and low-throughput Sanger sequencing to identify
      antibodies with a higher success rate.
    </p>
  );
};

const IntegrationOverview = () => {
  return (
    <div>
      <IntegrationDescrShort />
      <p>
        The workflow processes airr-tsv files from bulk BCR, single-cell BCR,
        and/or low-throughput sequencing pipelines, converting them back to
        fasta file for downstream analysis. Clones that have sequences from
        different pipelines (bulk and single-cell BCR, or bulk and
        low-throughput) are selected, and heavy and light chains sequences in
        those clones are paired for all possible combinations, facilitating the
        user in identifying antibodies with a higher success rate.
      </p>
    </div>
  );
};

const IntegrationDescr = () => {
  return (
    <div>
      <IntegrationOverview />
      <div>
        <h4>Processing steps</h4>
        <ul>
          <li>
            <span>1</span>
            <div>
              <h5>Input</h5>
              <p>
                TSV files in airr format outputted from bulk BCR, single-cell
                BCR, and/or low-throughput sequencing pipelines. The TSV files
                are converted to fasta format and combined into a single FASTA
                file
              </p>
            </div>
          </li>
          <li>
            <span>2</span>
            <div>
              <h5>Alignment</h5>
            </div>
          </li>
          <li>
            <span>3</span>
            <div>
              <h5>Clonal Assignment</h5>
              <p>DefineClones from changeo</p>
            </div>
          </li>
          <li>
            <span>4</span>
            <div>
              <h5>Germline Reconstruction</h5>
              <p>Reconstruction of the germline V(D)J sequence</p>
            </div>
          </li>
          <li>
            <span>5</span>
            <div>
              <h5>Selection</h5>
              <p>Selection of clones with sequences from multiple platforms</p>
            </div>
          </li>
          <li>
            <span>6</span>
            <div>
              <h5>Heavy-light chain pairing</h5>
              <p>
                All possible combinations of heavy-light chain sequences are
                paired for each selected clone and plated in 96-well plates
              </p>
            </div>
          </li>
          <li>
            <span>7</span>
            <div>
              <h5>Amino Acid Properties</h5>
              <p>Physiochemical properties of Ig AA sequences</p>
            </div>
          </li>
          <li>
            <span>8</span>
            <div>
              <h5>Lineage Reconstruction</h5>
              <p>Maximum parsimony with PHYLIP package</p>
            </div>
          </li>
          <li>
            <span>9</span>
            <div>
              <h5>Phylogenetic Analysis</h5>
              <p>Build phylogenetic trees with IgPhyML</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { IntegrationDescr, IntegrationDescrShort, IntegrationOverview };
