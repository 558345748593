const getDatetimeFormatted = (value) => {
  return new Date(value).toLocaleString([], {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export { getDatetimeFormatted };
