import { references_species, skip_downstream_analysis } from "config/common";

const bulkAIRRConfig = {
  displayName: "Bulk BCR",
  params: [
    skip_downstream_analysis(
      `If “Yes” the pipeline will run post-processing steps which includes
      clonal assignment, germline reconstruction, amino acid properties
      analysis, lineage reconstruction and phylogenetic analysis. If “No”,
      pipeline will only run pre-processing steps.`
    ),
    {
      key: "preprocessing.coord",
      label: "Format of sequence identifier",
      type: "select",
      choices: [
        { value: "sra", name: "SRA" },
        { value: "illumina", name: "Illumina" },
      ],
      defaultValue: "illumina",
      description:
        "Format of sequence identifier which defines shared coordinate information across mate pairs",
    },
    references_species,
    {
      key: "postprocessing.create_lineage_trees.enabled",
      label: "Create Lineage Trees",
      type: "select",
      defaultValue: false,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description: "Whether to run Phylip dnapars lineage reconstruction.",
    },
    {
      key: "postprocessing.build_trees.enabled",
      label: "Build Trees",
      type: "select",
      defaultValue: false,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description:
        "Whether the pipeline will skip over IgPhyML phylogenetic tree construction.",
    },
    {
      key: "postprocessing.clonal_grouping.find_threshold",
      label: "Calculate Treshold",
      type: "select",
      defaultValue: false,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description:
        "Whether the pipeline will calculate the threshold for clonal assignment.",
    },
    {
      key: "postprocessing.repertoire_overlap.enabled",
      label: "Repertoire Overlap",
      type: "select",
      defaultValue: true,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description: "Whether to analyse the overlap between repertoires.",
    },
    {
      key: "preprocessing.filter.filter_quality",
      label: "Filter Quality",
      type: "range",
      min: 0,
      max: 60,
      defaultValue: 20,
      advanced: true,
      description: "Phred quality score to remove low quality reads.",
    },
    {
      key: "preprocessing.collapse.n_collapse",
      label: "N Collapse",
      type: "range",
      min: 0,
      max: 20,
      defaultValue: 20,
      advanced: true,
      description: `Maximum number of missing nucleotides to consider for collapsing sequences.
        A sequence will be considered undetermined if it contains too many missing nucleotides.
        Note, setting a value above 0 will consider ambiguous/missing nucleotides via a distance calculation,
        but is considerably more computationally expensive, especially on large data sets.`,
    },
    {
      key: "preprocessing.split_seq.max_count",
      label: "Dupcount",
      type: "range",
      min: 2,
      max: 10,
      defaultValue: 2,
      advanced: true,
      description: `Group sequences by dupcount value`,
    },
    {
      key: "preprocessing.masking_primers.score",
      label: "Masking",
      type: "select",
      advanced: true,
      defaultValue: true,
      choices: [
        { value: true, name: "Score" },
        { value: false, name: "Align" },
      ],
      description: `If score, mask primers with the score option,
        which finds primer matches by scoring primers at a fixed position.
        If align, uses the align option, which finds primer matches using pairwise local alignment and tends to be slower.`,
    },
    {
      key: "mask_mode",
      label: "Masking mode",
      type: "select",
      advanced: true,
      defaultValue: "cut",
      choices: [
        { value: "cut", name: "Cut" },
        { value: "trim", name: "Trim" },
        { value: "mask", name: "Mask" },
      ],
      description: `The cut mode will remove both the primer region and the preceding sequence.
        The mask mode will replace the primer region with Ns and remove the preceding sequence.
        The trim mode will remove the region preceding the primer, but leave the primer region intact.`,
    },
    {
      key: "single_chain",
      label: "Chain Type",
      type: "select",
      defaultValue: "heavy",
      choices: [
        { value: "light", name: "Light" },
        { value: "heavy", name: "Heavy" },
      ],
      description: `Whether heavy or light chains are present in the data.
        When set to heavy/light/both a set of special parameters (i.e. combine average asymmetric distances or use single linkage for hierarchical clustering) is used to define clones.`,
    },
    {
      key: "postprocessing.clonal_grouping.method",
      label: "Clones Method",
      type: "select",
      advanced: true,
      defaultValue: "define_clones",
      choices: [
        { value: "define_clones", name: "Define Clones" },
        { value: "spectral_clones", name: "Spectral Clones" },
      ],
      description: `Selects define clones or spectral clones for clonal assignment.`,
    },
    {
      key: "preprocessing.assembly.align",
      label: "Assembly",
      type: "select",
      advanced: true,
      defaultValue: true,
      choices: [
        { value: true, name: "Align" },
        { value: false, name: "Sequential" },
      ],
      description: `If align, assemble using the align option, which assembles pairs by aligning ends.
      If sequential, the sequential approach will be used which first attempts de novo paired-end assembly,
      if that fails it will attempt assembly guided by the V segment reference sequences.`,
    },
    {
      key: "postprocessing.add_vis.filter_cdr_1_2",
      label: "Filter CDRs",
      type: "select",
      advanced: true,
      defaultValue: true,
      choices: [
        { value: true, name: "Yes" },
        { value: false, name: "No" },
      ],
      description: `If enabled, CDR1, CDR2 and CDR3 columns will be filterd for NA's.
        If disabled only the CDR3 column will be filtered.
        Bovine tends to have NA's for CDR1 and CDR2 which could reduce the number of sequences for visualizations.`,
    },
    {
      key: "seq_type",
      hidden: true,
      defaultValue: "bulk",
    },
    {
      key: "receptor",
      hidden: true,
      defaultValue: "bcr",
    },
    {
      key: "preprocessing.datatype",
      hidden: true,
      defaultValue: "illumina",
    },
  ],
  samples: {
    allowedExtensions: ["fastq.gz"],
    allowedFields: ["lane", "readtype", "sample_id", "animal_id", "genotype"],
  },
  supportingFiles: [
    {
      param: "c_primer_fasta",
      label: "C_primer",
      allowedExtension: ".fasta",
      description:
        "A fasta file containing primer sequences that will match to R1 reads.",
    },
    {
      param: "v_primer_fasta",
      label: "V_primer",
      allowedExtension: ".fasta",
      description:
        "A fasta file containing primer sequences that will match to R2 reads.",
    },
  ],
  pipelineVersions: ["v1.1", "v1.0", "master"],
  populatesSequenceDb: true,
  extendedView: {
    detail: true,
    configure: true,
  },
};

export default bulkAIRRConfig;
