import { useEffect, useState, useCallback } from "react";
import { FormControl } from "react-bootstrap";

import { useStorageApi } from "apis";
import UploadFailed from "./UploadFailed";
import UploadProgress from "./UploadProgress";
import ConfirmOverwrite from "./ConfirmOverwrite";

const FIVE_GB = 5 * 1024 * 1024 * 1024;

function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default function Upload({
  variant = "primary",
  type,
  curPath,
  existingFiles,
  getItems,
}) {
  const storageApi = useStorageApi();
  const [uploads, setUploads] = useState({});
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [showUploadFailed, setShowUploadFailed] = useState(false);
  const [errorFile, setErrorFile] = useState(null);
  const [startAllUploads, setStartAllUploads] = useState(false);
  const [showConfirmOverwrite, setShowConfirmOverwrite] = useState(false);

  const handleUpload = (e) => {
    const files = Object.values(e.target.files);
    const newUploads = {};
    let willOverwrite = false;
    files.forEach((file) => {
      const key = createUUID();
      newUploads[key] = {
        file,
        progress: [0],
        started: false,
      };

      if (
        existingFiles.some(
          (existingFile) => existingFile.filename === file.name
        )
      ) {
        willOverwrite = true;
      }
    });
    setUploads(newUploads);
    // Reset upload input
    e.target.value = "";

    willOverwrite ? setShowConfirmOverwrite(true) : setStartAllUploads(true);
  };

  const updateUploadProgress = useCallback((key, progress, index = 0) => {
    setUploads((prevUploads) => {
      const upload = prevUploads[key];
      if (upload) {
        upload.progress[index] = progress;
        return { ...prevUploads, [key]: upload };
      }
      return prevUploads;
    });
  }, []);

  const updateMultiPartUpload = useCallback((key, complete) => {
    setUploads((prevUploads) => {
      const upload = prevUploads[key];
      if (upload) {
        upload.multi = true;
        upload.complete = complete;
        return { ...prevUploads, [key]: upload };
      }
      return prevUploads;
    });
  }, []);

  const request = useCallback(
    (method, url, key, data, callback, errorCallback, index) => {
      const req = new XMLHttpRequest();
      req.open(method, url, true);
      req.onload = () => callback(req.status, req.responseText);
      req.onerror = errorCallback;
      req.onabort = errorCallback;
      req.upload.addEventListener("progress", (e) => {
        updateUploadProgress(key, e.loaded, index);
      });
      req.send(data);
    },
    [updateUploadProgress]
  );

  const continueUpload = useCallback(
    (key, file, data) => {
      const promise = new Promise((resolve, reject) => {
        const formData = new FormData();

        if (data.url) {
          Object.keys(data.fields).forEach((key) => {
            formData.append(key, data.fields[key]);
          });
          formData.append("file", file);

          request(
            "POST",
            data.url,
            key,
            formData,
            (status) => {
              if (status !== 204) {
                reject();
              } else {
                resolve();
              }
            },
            () => {
              reject();
            },
            0
          );
        } else if (data.urls) {
          updateMultiPartUpload(key, false);
          const promises = data.urls.map((url, index) => {
            const promise = new Promise((resolve, reject) => {
              const start = FIVE_GB * index;
              const end = Math.min(start + FIVE_GB, file.size);
              const part = file.slice(start, end);

              updateUploadProgress(key, 0, index);

              request(
                "PUT",
                url,
                key,
                part,
                (status) => {
                  if (status === 200) {
                    resolve();
                  } else {
                    reject();
                  }
                },
                () => reject(),
                index
              );
            });

            return promise;
          });

          Promise.all(promises)
            .then(() =>
              storageApi.completeMultiPartUpload(
                type,
                curPath,
                file.name,
                data.upload_id
              )
            )
            .then(() => {
              updateMultiPartUpload(key, true);
              resolve();
            })
            .catch(() => {
              storageApi.delete(type, curPath, file.name);
              reject();
            });
        }
      });

      return promise;
    },
    [
      type,
      curPath,
      request,
      updateUploadProgress,
      updateMultiPartUpload,
      storageApi,
    ]
  );

  const startUpload = useCallback(
    (key, file) => {
      storageApi
        .startUpload({
          type: type,
          path: curPath,
          filename: file.name,
          size: file.size,
        })
        .then((response) => continueUpload(key, file, response.data))
        .catch(() => {
          setErrorFile(file.name);
          setShowUploadProgress(false);
          setShowUploadFailed(true);
          setUploads({});
        })
        .finally(getItems);
    },
    [type, continueUpload, getItems, curPath, storageApi]
  );

  useEffect(() => {
    if (!startAllUploads) {
      return;
    }
    setStartAllUploads(false);
    setShowUploadProgress(true);

    Object.keys(uploads)
      .filter((key) => !uploads[key].started)
      .forEach((key) => {
        const upload = uploads[key];
        upload.started = true;
        setUploads({ ...uploads, [key]: upload });
        startUpload(key, upload.file);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAllUploads]);

  return (
    <>
      <label className={`btn btn-${variant}`}>
        Upload
        <FormControl
          type="file"
          hidden
          multiple
          accept=".gz,.fastq.gz,.fasta,.csv,.ab1"
          onChange={handleUpload}
        ></FormControl>
      </label>

      {showUploadFailed && (
        <UploadFailed
          file={errorFile}
          onClose={() => {
            setShowUploadFailed(false);
            setErrorFile(null);
          }}
        ></UploadFailed>
      )}

      {showUploadProgress && (
        <UploadProgress
          uploads={Object.values(uploads)}
          onClose={() => {
            setShowUploadProgress(false);
            setUploads({});
          }}
        ></UploadProgress>
      )}

      {showConfirmOverwrite && (
        <ConfirmOverwrite
          existingFiles={existingFiles}
          uploads={uploads}
          onCancel={() => {
            setShowConfirmOverwrite(false);
            setUploads({});
          }}
          onConfirm={() => {
            setShowConfirmOverwrite(false);
            setStartAllUploads(true);
          }}
        ></ConfirmOverwrite>
      )}
    </>
  );
}
