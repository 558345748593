import { DefaultModal } from "components/common";

const UploadFailed = ({ file, extraMsg = null, onClose }) => {
  return (
    <DefaultModal
      hasCancel={false}
      title="Upload Failed"
      submitText="Close"
      onClose={onClose}
    >
      <div>
        <p className="mb-0">Not able to upload '{file}'</p>
        {!!extraMsg && <p>{extraMsg}</p>}
      </div>
    </DefaultModal>
  );
};

export default UploadFailed;
