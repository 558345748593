import { references_species } from "config/common";

const config = {
  displayName: "Integration",
  params: [
    {
      key: "sample_id",
      label: "Sample ID",
      type: "input",
      defaultValue: "",
      description: "Name the user wants to use to annotate the output files",
    },
    references_species,
    {
      key: "well_plate",
      label: "Well Plate",
      type: "select",
      defaultValue: 96,
      choices: [
        { value: 96, name: "96" },
        { value: 384, name: "384" },
      ],
      description:
        "Well plate layout to show heavy and light chain sequence combinations.",
    },
    {
      key: "seq_type",
      hidden: true,
      defaultValue: "integration",
    },
  ],
  samples: {
    allowedExtensions: ["tsv"],
    allowedFields: ["sample_id", "platform"],
  },
  supportingFiles: [],
  pipelineVersions: ["v1.1", "v1.0", "master"],
  extendedView: {
    detail: false,
    configure: false,
  },
  geneData: true,
};

export default config;
