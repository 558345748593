const ScAIRRDescrShort = () => {
  return (
    <p>
      Ligand utilizes the xPloration single cell sorting platform combined with
      plate based BCR sequencing to obtain natively paired heavy and light chain
      reads at high throughput.
      <br />
      Input will be raw FASTQ files from MiSeq with plate &amp; well barcoding.
    </p>
  );
};

const ScAIRROverview = () => {
  return (
    <div>
      <p>
        Ligand utilizes the xPloration single cell sorting platform combined
        with plate based BCR sequencing to obtain natively paired heavy and
        light chain reads at high throughput.
      </p>
      <p>
        Input will be raw FASTQ files from MiSeq with plate &amp; well
        barcoding.
      </p>
      <p>
        The results are made available via two reports, and the data is provided
        in the standardized AIRR format to perform downstream analyses. The
        pre-processing workflow processes the raw sequence data until the
        sequences are aligned against the IMGT germline reference. The
        post-processing workflow provides a set of analyses and matrices to
        provide basic characteristics and insights on the immune repertoire.
      </p>
    </div>
  );
};

const ScAIRRDescr = () => {
  return (
    <div>
      <div>
        <p>
          Ligand utilizes the xPloration single cell sorting platform combined
          with plate based BCR sequencing to obtain natively paired heavy and
          light chain reads at high throughput.
        </p>
        <p>
          Input will be raw FASTQ files from MiSeq with plate &amp; well
          barcoding.
        </p>
        <p>
          The results are made available via two reports, and the data is
          provided in the standardized AIRR format to perform downstream
          analyses. The pre-processing workflow processes the raw sequence data
          until the sequences are aligned against the IMGT germline reference.
          The post-processing workflow provides a set of analyses and matrices
          to provide basic characteristics and insights on the immune
          repertoire.
        </p>
      </div>
      <div>
        <h4>Processing steps</h4>
        <ul>
          <li>
            <span>1</span>
            <div>
              <h5>Input</h5>
              <p>
                Paired-end raw FASTQ files (compressed) R1 primer, R2 primer,
                v-gene, and constant region in fasta format, well layout csv,
                and adapter sequence file
              </p>
            </div>
          </li>
          <li>
            <span>2</span>
            <div>
              <h5>Sequence QC</h5>
              <p>Low-quality reads are discarded</p>
            </div>
          </li>
          <li>
            <span>3</span>
            <div>
              <h5>Primer Masking</h5>
              <p>The PCR primers are identified and masked</p>
            </div>
          </li>
          <li>
            <span>4</span>
            <div>
              <h5>Sequence Clustering</h5>
              <p>Cluster reads by barcode sequences</p>
            </div>
          </li>
          <li>
            <span>5</span>
            <div>
              <h5>Build Consensus</h5>
              <p>Builds a consensus sequence for each set of input sequences</p>
            </div>
          </li>
          <li>
            <span>6</span>
            <div>
              <h5>Assembly</h5>
              <p>Assembles paired-end reads into a single sequence</p>
            </div>
          </li>
          <li>
            <span>7</span>
            <div>
              <h5>Alignment</h5>
              <p>Assigns V(D)J gene annotations with IgBLAST</p>
            </div>
          </li>
          <li>
            <span>8</span>
            <div>
              <h5>Clonal Assignment</h5>
              <p>Unsupervised spectral clustering approach</p>
            </div>
          </li>
          <li>
            <span>9</span>
            <div>
              <h5>Germline Reconstruction</h5>
              <p>Reconstructs the germline V(D)J sequence</p>
            </div>
          </li>
          <li>
            <span>10</span>
            <div>
              <h5>Amino Acid Properties</h5>
              <p>
                Analyzes the physicochemical properties of Ig amino acid
                sequences
              </p>
            </div>
          </li>
          <li>
            <span>11</span>
            <div>
              <h5>Lineage Reconstruction</h5>
              <p>Maximum parsimony with PHYLIP package</p>
            </div>
          </li>
          <li>
            <span>12</span>
            <div>
              <h5>Phylogenetic Analysis</h5>
              <p>Builds phylogenetic trees with IgPhyML</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { ScAIRRDescrShort, ScAIRRDescr, ScAIRROverview };
