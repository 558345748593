import { forwardRef } from "react";
import TableAllFeatures from "./TableAllFeatures";
import TableWithoutPagination from "./TableWithoutPagination";
import TableServerSideFeatures from "./TableServerSideFeatures";
import NestedTable from "./NestedTable";

const Table = forwardRef((props, ref) => {
  if (!!props.children) {
    return <NestedTable {...props} ref={ref}></NestedTable>;
  } else if (props.noPagination) {
    return (
      <TableWithoutPagination {...props} ref={ref}></TableWithoutPagination>
    );
  } else if (props.serverSideFeatures) {
    return (
      <TableServerSideFeatures {...props} ref={ref}></TableServerSideFeatures>
    );
  } else {
    return <TableAllFeatures {...props} ref={ref}></TableAllFeatures>;
  }
});

export default Table;
