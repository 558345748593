const FpLtAIRRDescrShort = () => {
  return (
    <p>
      The First Pass low-throughput sequencing pipeline is a bioinformatics
      workflow used to process chromatogram files from Sanger sequencing.
    </p>
  );
};

const FpLtAIRROverview = () => {
  return (
    <div>
      <FpLtAIRRDescrShort />
      <p>
        The workflow processes raw data from chromatogram files and aligns the
        sequences to the germline database. The pre-processing workflow reads
        “.abi” chromatogram files, trims low-quality regions and aligns the
        sequences to the germline reference of choice. Sequences that fail
        during FilterSeq, Assemble, and MaskPrimers steps are outputted in a
        text file for upload to Genedata. Sequences that pass all criteria may
        then go through the Full Pass low-throughput sequencing pipeline for
        further downstream analyses.
      </p>
    </div>
  );
};

const FpLtAIRRDescr = () => {
  return (
    <div>
      <FpLtAIRROverview />
      <div>
        <h4>Processing steps</h4>
        <ul>
          <li>
            <span>1</span>
            <div>
              <h5>Input</h5>
              <p>
                Chromatogram files in the “.abi” format and number of primers
                used
              </p>
            </div>
          </li>
          <li>
            <span>2</span>
            <div>
              <h5>Sequence QC</h5>
              <p>Removal of low-quality reads</p>
            </div>
          </li>
          <li>
            <span>3</span>
            <div>
              <h5>Assembly</h5>
              <p>Assembles paired-end reads into a single sequence</p>
            </div>
          </li>
          <li>
            <span>4</span>
            <div>
              <h5>Primer Masking</h5>
              <p>The PCR primers are identified and masked</p>
            </div>
          </li>
          <li>
            <span>5</span>
            <div>
              <h5>Alignment</h5>
              <p>Assigns V(D)J gene annotations with IgBLAST</p>
            </div>
          </li>
          <li>
            <span>6</span>
            <div>
              <h5>Quality Score Calculation</h5>
              <p>
                Calculate quality scores for each heavy and light chain sequence
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { FpLtAIRRDescrShort, FpLtAIRRDescr, FpLtAIRROverview };
