import { forwardRef } from "react";
import { Button, Dropdown } from "react-bootstrap";

import "./Avatar.scss";

export default function Avatar({ dropdownEnabled, user, handleLogout }) {
  // @ts-ignore
  const AvatarToggle = forwardRef(({ onClick }, ref) => (
    <Button
      className="user-avatar"
      ref={ref}
      disabled={!dropdownEnabled}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {user.avatar ? (
        <img className="user-avatar" src={user.avatar} alt="avatar" />
      ) : (
        <div>
          {user.firstName
            ? user.firstName.charAt(0).toUpperCase()
            : user.email.charAt(0).toUpperCase()}
        </div>
      )}
    </Button>
  ));

  return (
    <Dropdown drop="end" className="dropdown-container">
      <Dropdown.Toggle as={AvatarToggle}></Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href="/"
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
          }}
        >
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
