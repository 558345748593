import { useMemo } from "react";
import useApi from "./useApi";

const useGenedataApi = () => {
  const api = useApi();

  return useMemo(
    () => ({
      post: (hitlist, species) => {
        return api.call({
          method: "post",
          url: "genedata/sequence_information/",
          data: {
            hitlist,
            species,
          },
        });
      },
      get: (identifier) =>
        api.call({
          method: "get",
          url: `genedata/sequence_information/${identifier}/`,
        }),
      getHitlist: () => api.call({ method: "get", url: "genedata/hitlist/" }),
      getProjects: () => api.call({ method: "get", url: "genedata/project/" }),
    }),
    [api]
  );
};

export default useGenedataApi;
