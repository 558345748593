import { Card, SimpleTable } from "../../../common";

const GeneDataHitlists = ({ experiment }) => {
  const hitlists = experiment.setup_params.hitlists || [];

  const columns = [
    {
      Header: "Project ID",
      accessor: "project_id",
    },
    {
      Header: "Project Name",
      accessor: "project_name",
    },
    {
      Header: "Hitlist ID",
      accessor: "hitlist_id",
    },
    {
      Header: "Hitlist Name",
      accessor: "hitlist_name",
    },
    {
      Header: "Platform",
      accessor: "platform",
    },
  ];

  return (
    <Card title="Genedata Hitlists" className="h-100">
      <SimpleTable columns={columns} data={hitlists} />
    </Card>
  );
};

export default GeneDataHitlists;
