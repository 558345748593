import { Card } from "react-bootstrap";

import Logo from "../../media/omniab.png";

import "./ErrorPage.scss";

const ErrorPage = () => {
  return (
    <div className="errorpage-backdrop">
      <Card className="errorpage-container">
        <Card.Body>
          <Card.Img variant="top" src={Logo} />
          <h3 className="mt-5 mb-4">Oops!</h3>
          <p className="mb-0">
            An error occured while trying to log in.
            <br />
            Please contact your administrator.
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ErrorPage;
