import { OverlayTrigger, Tooltip } from "react-bootstrap";
import csvDownload from "json-to-csv-export";

import { GrDocumentCsv } from "react-icons/gr";

export default function DownloadCSVButton({ data, filename }) {
  const dataToConvert = {
    data,
    filename,
    delimiter: ";",
    headers: Object.keys(data[0]),
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={() => csvDownload(dataToConvert)}
      className={`icon-btn-container position-relative`}
    >
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{"Download CSV"}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <span className="icon-btn" {...triggerHandler} ref={ref}>
            <GrDocumentCsv />
          </span>
        )}
      </OverlayTrigger>
    </a>
  );
}
