import { Fragment, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { DefaultModal } from "../common";

export default function UploadProgress({ uploads, onClose }) {
  const [isUploading, setIsUploading] = useState(true);

  const getProgress = (upload) => {
    const progress = upload.progress.reduce((acc, val) => acc + val, 0);
    let percentage = Math.round((progress / upload.file.size) * 100);
    return percentage > 100 ? 100 : percentage;
  };

  useEffect(() => {
    setIsUploading(
      !uploads.every((upload) => {
        if (upload.multi) {
          return upload.complete;
        } else {
          return getProgress(upload) === 100;
        }
      })
    );
  }, [uploads]);

  return (
    <DefaultModal
      title="Upload Progress"
      size="lg"
      onClose={onClose}
      scrollable
      isLoading={isUploading}
      submitText={isUploading ? "Uploading" : "Close"}
    >
      <h6 className="text-center mb-4 mt-2">
        Uploading files. Please do not close your browser until all files have
        finished uploading.
      </h6>
      {uploads.map((upload) => {
        const progress = getProgress(upload);

        return (
          <Fragment key={upload.file.name}>
            <dl className="row mb-1">
              <dt className="col-auto font-weight-normal my-auto">
                {upload.file.name}
              </dt>
              <dd className="col my-auto">
                <ProgressBar
                  now={progress}
                  label={`${progress}%`}
                ></ProgressBar>
              </dd>
            </dl>
          </Fragment>
        );
      })}
    </DefaultModal>
  );
}
