import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Card, Loader, SimpleTable } from "components/common";
import { PIPELINES } from "config";

const Samples = ({ experiment, samples, isLoading }) => {
  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Filename",
        accessor: "filename",
        id: "name",
        Cell: ({ row, value }) => (
          <OverlayTrigger
            placement="top"
            overlay={(props) => (
              <Tooltip {...props} className="description-tooltip">
                {row.original.path ? `${row.original.path}${value}` : value}
              </Tooltip>
            )}
          >
            <span>{value}</span>
          </OverlayTrigger>
        ),
      },
    ];

    const allowedFields =
      PIPELINES[experiment.pipeline].config.samples.allowedFields;

    if (experiment.pipeline === "integration") {
      columns.push({
        Header: "Experiment",
        accessor: "path",
        id: "experiment",
        Cell: ({ value }) => value.split("/")[0],
      });
      // @ts-ignore
      columns.push({
        Header: "Platform",
        accessor: "platform",
      });
    } else {
      if (allowedFields.includes("animal_id")) {
        // @ts-ignore
        columns.push({
          Header: "Animal ID",
          accessor: "animal_id",
        });
      }
      if (allowedFields.includes("genotype")) {
        // @ts-ignore
        columns.push({
          Header: "Genotype",
          accessor: "genotype",
        });
      }
      if (allowedFields.includes("sample_id")) {
        // @ts-ignore
        columns.push({
          Header: "Sample ID",
          accessor: "sample_id",
        });
      }
      if (allowedFields.includes("lane")) {
        // @ts-ignore
        columns.push({
          Header: "Lane",
          accessor: "lane",
        });
      }
      if (allowedFields.includes("readtype")) {
        // @ts-ignore
        columns.push({
          Header: "Read Type",
          accessor: "readtype",
        });
      }
    }

    return columns;
  }, [experiment.pipeline]);

  return (
    <Card title="Samples">
      <SimpleTable columns={columns} data={samples}></SimpleTable>
      <Loader isLoading={isLoading} />
    </Card>
  );
};

export default Samples;
