import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  GrCheckmark,
  GrDocument,
  GrFolder,
  GrStatusWarning,
} from "react-icons/gr";

import { Table } from "../../../common";

const AddFilesTable = ({
  data,
  setSelectedRows,
  tableRef,
  onNavigate,
  isAdded,
  isSelectable,
  enableMultiSelect,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "filename",
        Cell: ({ row }) =>
          row.original.is_folder ? (
            <>
              <GrFolder className="me-2" />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  onNavigate(`${row.original.path}${row.original.filename}`);
                }}
              >
                {row.original.filename}
              </a>
            </>
          ) : (
            <span>
              <GrDocument className="me-2" />
              {row.original.filename}
            </span>
          ),
      },
      {
        Header: "",
        accessor: "is_archived",
        disableSort: true,
        Cell: ({ value }) =>
          value ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>File is archived</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <span ref={ref} {...triggerHandler} className="text-warning">
                  <GrStatusWarning />
                </span>
              )}
            </OverlayTrigger>
          ) : null,
      },
      {
        Header: enableMultiSelect ? "Added" : "Selected",
        id: "added",
        accessor: null,
        Cell: ({ row }) => (isAdded(row.original) ? <GrCheckmark /> : null),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={useMemo(() => data, [data])}
      enableRowSelection={true}
      setSelectedRows={setSelectedRows}
      noPagination={true}
      enableConditionalSelect={true}
      isSelectable={isSelectable}
      enableMultiSelect={enableMultiSelect}
      enableSelectOnClick={true}
    />
  );
};

export default AddFilesTable;
