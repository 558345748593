import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import IconButton from "../IconButton/IconButton";

export default function TablePagination({
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  totalRowCount,
  gotoPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  nextPage,
}) {
  const [firstRow, setFirstRow] = useState(0);
  const [lastRow, setLastRow] = useState(0);

  const maxPageIndex = pageCount - 1;

  useEffect(() => {
    const first = pageSize * pageIndex + 1;
    let last = Math.min(first + pageSize - 1, totalRowCount);
    setFirstRow(first);
    setLastRow(last);
  }, [pageIndex, totalRowCount, pageSize]);

  return (
    <>
      {!!totalRowCount && (
        <Row className="table-pagination-container row-cols-1 row-cols-lg-2">
          <Col className="table-rows justify-content-center justify-content-lg-start d-flex">
            <label className="form-label">
              Rows per page:
              <Form.Select
                size="sm"
                name="row-amount-input"
                className="mx-2"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </label>
          </Col>

          <Col className="table-pagination justify-content-center justify-content-lg-end">
            <div className="me-3">
              <span>{`${firstRow}-${lastRow} of ${totalRowCount}`}</span>
            </div>
            <IconButton
              icon={<FaAngleDoubleLeft />}
              className={`me-3 ${!canPreviousPage && "disabled"}`}
              onClick={() => canPreviousPage && gotoPage(0)}
            ></IconButton>

            <IconButton
              icon={<FaAngleLeft />}
              className={`me-3 ${!canPreviousPage && "disabled"}`}
              onClick={() => canPreviousPage && previousPage()}
            ></IconButton>

            <IconButton
              icon={<FaAngleRight />}
              className={`me-3 ${!canNextPage && "disabled"}`}
              onClick={() => canNextPage && nextPage()}
            ></IconButton>

            <IconButton
              icon={<FaAngleDoubleRight />}
              className={`me-3 ${!canNextPage && "disabled"}`}
              onClick={() => canNextPage && gotoPage(maxPageIndex)}
            ></IconButton>
          </Col>
        </Row>
      )}
    </>
  );
}
