import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { graphRequest, loginRequest } from "authConfig";
import axios from "axios";
import { useEffect, useMemo } from "react";

const apiUrl = process.env.REACT_APP_API_URI;
axios.defaults.baseURL = apiUrl ? `${apiUrl}/api/` : "/api/";

const tokenRequest = (account) => {
  return {
    ...loginRequest,
    account: account,
  };
};

const msGraphRequest = (account) => {
  return {
    ...graphRequest,
    account: account,
  };
};

const useApi = () => {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    // Set api interceptors if they are not set yet
    if (axios.interceptors.response.handlers.length === 0) {
      axios.interceptors.response.use(null, (error) => {
        const genericError = {
          response: {
            data: {
              error: [
                "Something went wrong, please contact your administrator.",
              ],
            },
          },
        };

        if (!error?.response || error.response.status >= 500) {
          return Promise.reject(genericError);
        }

        if (
          error.response.status === 401 &&
          !error.config.url?.includes("graph.microsoft.com")
        ) {
          return instance
            .logoutRedirect({ account: accounts[0] })
            .catch(() => Promise.reject(genericError));
        }

        return Promise.reject(error);
      });
    }
  }, [instance, accounts]);

  return useMemo(
    () => ({
      call: (apidata) => {
        return instance
          .acquireTokenSilent(tokenRequest(accounts[0]))
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return instance.acquireTokenRedirect(tokenRequest(accounts[0]));
            }
          })
          .then((azureResponse) => {
            apidata.headers = {
              Authorization: `Bearer ${azureResponse.accessToken}`,
            };
            return axios(apidata);
          });
      },
      callMultiple: (apidataList) => {
        const promises = [];
        return instance
          .acquireTokenSilent(tokenRequest(accounts[0]))
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return instance.acquireTokenRedirect(tokenRequest(accounts[0]));
            }
            return Promise.reject(error);
          })
          .then((azureResponse) => {
            apidataList.forEach((apidata) => {
              apidata.headers = {
                Authorization: `Bearer ${azureResponse.accessToken}`,
              };
              promises.push(axios(apidata));
            });

            return Promise.all(promises);
          });
      },
      callMsGraph: (apidata) => {
        return instance
          .acquireTokenSilent(msGraphRequest(accounts[0]))
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return instance.acquireTokenRedirect(msGraphRequest(accounts[0]));
            }
          })
          .then((azureResponse) => {
            apidata.headers = {
              Authorization: `Bearer ${azureResponse.accessToken}`,
            };
            return axios(apidata);
          });
      },
    }),
    [instance, accounts]
  );
};

export default useApi;
