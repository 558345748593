import { RemovableBadge } from "components/common";
import { Row, Col, Button } from "react-bootstrap";
import { GrBarChart, GrClose } from "react-icons/gr";

const SelectedSamples = ({
  samples,
  onClick,
  onClearSelection,
  onRemoveSelectedSample,
}) => {
  return (
    <Row className="align-items-center">
      <Col>
        {samples.map((sample) => (
          <RemovableBadge
            key={`${sample.experiment.identifier} - ${sample.sample_id} - ${sample.lane}`}
            pill
            onRemove={() => onRemoveSelectedSample(sample)}
            className="me-2"
          >{`${sample.experiment.identifier} - ${sample.sample_id} ${
            sample.lane ? ` - ${sample.lane}` : ""
          }`}</RemovableBadge>
        ))}
      </Col>
      <Col className="align-items-center ps-0" xs="auto">
        <Button
          size="sm"
          variant="outline-secondary"
          className="d-inline-flex align-items-center visualize-btn"
          onClick={onClick}
        >
          <GrBarChart className="me-2" />
          Visualize
        </Button>
        <Button
          size="sm"
          className="ms-2 d-inline-flex align-items-center text-primary reset-selection-btn"
          variant="white"
          onClick={onClearSelection}
        >
          <GrClose className="me-2" />
          Clear Selection
        </Button>
      </Col>
    </Row>
  );
};

export default SelectedSamples;
