const { default: SelectionCheckbox } = require("./SelectionCheckbox");

const getConditionalSelectHeaderCheckboxProps = ({
  headerProps,
  checkIfRowIsSelectable,
}) => {
  const checkIfAllSelectableRowsSelected = (rows) =>
    rows
      .filter((row) => checkIfRowIsSelectable(row.original))
      .every((row) => row.isSelected);

  // For conditional select: The header checkbox should be:
  //   - checked if all selectable rows are selected
  //   - indeterminate if only some selectable rows are selected (but not all)
  const disabled =
    headerProps.rows.filter((row) => checkIfRowIsSelectable(row.original))
      .length === 0;
  const checked =
    !disabled && checkIfAllSelectableRowsSelected(headerProps.rows);
  const indeterminate =
    !checked && headerProps.rows.some((row) => row.isSelected);

  // For conditional select: This is where the magic happens
  const onChange = () => {
    if (checkIfAllSelectableRowsSelected(headerProps.rows)) {
      // If all selectable rows are already selected: deselect all rows
      headerProps.rows.forEach((row) => {
        headerProps.toggleRowSelected(row.id, false);
      });
    } else {
      // Otherwise, select every selectable row
      headerProps.rows.forEach((row) => {
        const checked = checkIfRowIsSelectable(row.original);
        headerProps.toggleRowSelected(row.id, checked);
      });
    }
  };

  // For conditional select: override checked, indeterminate and onChange - to enforce conditional select based on our business logic
  return {
    ...headerProps.getToggleAllRowsSelectedProps(),
    checked,
    indeterminate,
    onChange,
    disabled,
  };
};

const HeaderConditionalSelect = ({ headerProps, isSelectable }) => {
  const checkboxProps = getConditionalSelectHeaderCheckboxProps({
    headerProps: headerProps,
    checkIfRowIsSelectable: isSelectable,
  });
  return (
    <div>
      <SelectionCheckbox {...checkboxProps} />
    </div>
  );
};

const CellConditionalSelect = ({ row, isSelectable, toggleRowSelected }) => {
  const selectionEnabled = isSelectable(row.original);

  const props = {
    ...row.getToggleRowSelectedProps(),
    onChange: (e) => {
      toggleRowSelected(row, e.target.checked);
    },
  };

  return selectionEnabled ? (
    <div>
      <SelectionCheckbox {...props} />
    </div>
  ) : null;
};

export { HeaderConditionalSelect, CellConditionalSelect };
