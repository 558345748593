import { useMsal } from "@azure/msal-react";
import { graphConfig } from "authConfig";
import { useMemo } from "react";
import useApi from "./useApi";

const apiUrl = process.env.REACT_APP_API_URI;

const useUserApi = () => {
  const { instance, accounts } = useMsal();
  const api = useApi();

  return useMemo(
    () => ({
      list: () => {
        return api.call({ method: "get", url: "user/" });
      },
      get: () => {
        return api.call({ method: "get", url: "user/current/" });
      },
      getMetaData: () => {
        return api.callMsGraph({
          method: "get",
          url: graphConfig.graphMeEndpoint,
        });
      },
      getProfilePicture: () => {
        return api.callMsGraph({
          method: "get",
          url: `${graphConfig.graphMeEndpoint}/photos/48x48/$value`,
          responseType: "blob",
        });
      },
      logout: () => {
        return api
          .call({
            method: "post",
            baseURL: apiUrl ? `${apiUrl}/accounts/` : "/accounts/",
            url: "logout/",
            data: {},
          })
          .then(() => {
            instance.logoutRedirect({
              account: accounts[0],
              postLogoutRedirectUri: window.location.origin,
            });
          });
      },
    }),
    [api, accounts, instance]
  );
};

export default useUserApi;
