import React from "react";
import ReactDOM from "react-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";

import { InteractionType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

import App from "./components/App";

import "react-widgets/scss/styles.scss";
import "./index.scss";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
