import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import HC_brokenAxis from "highcharts/modules/broken-axis";
import highchartsAccessibility from "highcharts/modules/accessibility";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { useEffect, useState } from "react";

import "../charts.scss";

exporting(Highcharts);
HC_brokenAxis(Highcharts);
highchartsAccessibility(Highcharts);
NoDataToDisplay(Highcharts);

const StackedBarChart = ({ config }) => {
  const [options, setOptions] = useState({});

  const fetchBarChartData = () => {
    const options = {
      chart: {
        type: "column",
        ignoreHiddenSeries: true,
        zoomType: "xy",
      },
      title: {
        text: config.title,
      },
      legend: !!config.legend
        ? config.legend
        : {
            layout: "horizontal",
          },
      xAxis: !!config.xAxis
        ? config.xAxis
        : {
            showEmpty: false,
            categories: config.columns,
          },
      yAxis: !!config.yAxis
        ? config.yAxis
        : {
            labels: {
              format: "{text}%",
            },
            title: {
              text: "Percentage",
            },
          },
      tooltip: !!config.tooltip
        ? config.tooltip
        : {
            headerFormat: "<b>{point.x}:</b><br/>",
            pointFormat: "<b>{point.name}:</b> {point.y} %<br/>",
          },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
          grouping: false,
          pointPlacement: undefined,
        },
      },
      series: config.series ? config.series : [],
      credits: {
        enabled: false,
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    fetchBarChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      loader={true}
      containerProps={{ className: "full-width-chart-container" }}
    />
  );
};

export default StackedBarChart;
