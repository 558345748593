import { Table } from "react-bootstrap";
import { useTable, useRowSelect } from "react-table";

import "./SimpleTable.scss";

const SimpleTable = ({
  columns,
  data,
  emptyMsg = "No records available",
  enableSelectOnClick = false,
  onRowClick = null,
  size = null,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({ columns, data }, useRowSelect);

  return (
    <div className="simple-table">
      <Table
        responsive
        hover={enableSelectOnClick}
        className="table-centered"
        {...getTableProps()}
        size={size}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th {...column.getHeaderProps()} className={`${column.id}`}>
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {(rows.length &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() =>
                    enableSelectOnClick &&
                    !!onRowClick &&
                    onRowClick(row.original)
                  }
                  className={enableSelectOnClick ? "clickable" : ""}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={cell.column.id}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })) || (
            <tr>
              <td className="text-center nohover" colSpan={columns.length}>
                {emptyMsg}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default SimpleTable;
