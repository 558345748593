import { Card } from "components/common";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import DependencyWheelChart from "components/common/Charts/DependencyWheel/DependencyWheel";
import DownloadCSVButton from "components/common/DownloadCSVButton/DownloadCSVButton";

const CDRH3Overlap = ({
  sampleData,
  visualization_id,
  description,
  setIsLoading,
}) => {
  const [hcConfigRaw, setHcConfigRaw] = useState({});
  const [hcConfigNormalized, setHcConfigNormalized] = useState({});
  const [data, setData] = useState([]);

  const generateHighchartsConfig = () => {
    const rawConfig = {
      title: "Raw Counts",
      seriesData: data.map((m) => {
        return [m.from, m.to, m.rawValue];
      }),
    };
    const normalizedConfig = {
      title: "Normalized",
      seriesData: data.map((m) => {
        return [m.from, m.to, m.normalizedValue];
      }),
    };
    setHcConfigRaw(rawConfig);
    setHcConfigNormalized(normalizedConfig);
  };

  const fetchSamplesData = (overlapSamples) => {
    const sample_ids = Object.keys(overlapSamples);
    const overlapData = [];
    for (let fromSample of sample_ids) {
      for (let toSample of sample_ids) {
        if (fromSample !== toSample) {
          const totalFromCount = overlapSamples[fromSample].length;
          const absoluteValue = overlapSamples[fromSample].filter((value) =>
            overlapSamples[toSample].includes(value)
          ).length;
          overlapData.push({
            from: fromSample,
            to: toSample,
            total: totalFromCount,
            rawValue: absoluteValue,
            normalizedValue:
              Math.round(
                100000 * Math.log10(1 + absoluteValue / totalFromCount)
              ) / 100,
          });
        }
      }
    }
    setData(overlapData);
  };

  useEffect(() => {
    if (!!sampleData.length) {
      const allSamples = {};
      sampleData.forEach((sd) => {
        allSamples[sd.sample_id] = [
          ...new Set(sd.data.map((x) => x.junction_aa)),
        ];
      });
      fetchSamplesData(allSamples);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCSVData = () => {
    return data.map((m) => {
      return {
        from: m.from,
        to: m.to,
        raw_count: m.rawValue,
        normalized: m.normalizedValue,
      };
    });
  };

  useEffect(
    () => {
      generateHighchartsConfig();
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <Row>
      <Col lg="12" xxl="12">
        <Card
          title="CDRH3 Overlap"
          description={description}
          actions={
            !!data &&
            !!data.length && (
              <DownloadCSVButton
                data={getCSVData()}
                filename={`${visualization_id}_cdrh3overlap`}
              />
            )
          }
        >
          <Row>
            <Col lg="6" xxl="6">
              <DependencyWheelChart config={hcConfigRaw}></DependencyWheelChart>
            </Col>
            <Col lg="6" xxl="6">
              <DependencyWheelChart
                config={hcConfigNormalized}
              ></DependencyWheelChart>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CDRH3Overlap;
