import { Col, Form, Row } from "react-bootstrap";

export default function FilteringCondition({ curCondition, setCurCondition }) {
  return (
    <Row className="align-items-center">
      <Col xs="auto">
        <p className="mb-0">Filtering Condition:</p>
      </Col>
      <Col xs="auto">
        <Form.Select
          value={curCondition}
          className="select-condition"
          onChange={(e) => setCurCondition(e.target.value)}
        >
          <option key="and" value="and">
            AND
          </option>
          <option key="or" value="or">
            OR
          </option>
        </Form.Select>
      </Col>
    </Row>
  );
}
