import bulkAIRRConfig from "./bulkAIRR/config";
import scAIRRConfig from "./scAIRR/config";
import ltAIRRConfig from "./ltAIRR/config";
import FpLtAIRRConfig from "./fpltAIRR/config";
import integrationConfig from "./integration/config";

import {
  BulkAIRRDescr,
  BulkAIRRDescrShort,
  BulkAIRROverview,
} from "./bulkAIRR/info";
import { ScAIRRDescr, ScAIRRDescrShort, ScAIRROverview } from "./scAIRR/info";
import { LtAIRRDescr, LtAIRRDescrShort, LtAIRROverview } from "./ltAIRR/info";
import {
  FpLtAIRRDescr,
  FpLtAIRRDescrShort,
  FpLtAIRROverview,
} from "./fpltAIRR/info";
import {
  IntegrationDescr,
  IntegrationDescrShort,
  IntegrationOverview,
} from "./integration/info";

const PIPELINES = (() => {
  const pipelines = {
    bulkAIRR: {
      name: "bulkAIRR",
      config: bulkAIRRConfig,
      descr: <BulkAIRRDescr />,
      descrShort: <BulkAIRRDescrShort />,
      overview: <BulkAIRROverview />,
    },
    scAIRR: {
      name: "scAIRR",
      config: scAIRRConfig,
      descr: <ScAIRRDescr />,
      descrShort: <ScAIRRDescrShort />,
      overview: <ScAIRROverview />,
    },
    ltAIRR: {
      name: "ltAIRR",
      config: ltAIRRConfig,
      descr: <LtAIRRDescr />,
      descrShort: <LtAIRRDescrShort />,
      overview: <LtAIRROverview />,
    },
    fpltAIRR: {
      name: "fpltAIRR",
      config: FpLtAIRRConfig,
      descr: <FpLtAIRRDescr />,
      descrShort: <FpLtAIRRDescrShort />,
      overview: <FpLtAIRROverview />,
    },
    integration: {
      name: "integration",
      config: integrationConfig,
      descr: <IntegrationDescr />,
      descrShort: <IntegrationDescrShort />,
      overview: <IntegrationOverview />,
    },
  };

  return pipelines;
})();

const METADATA_FIELDS = [
  { label: "Client", key: "client" },
  { label: "Project", key: "project" },
  { label: "Target", key: "target" },
  { label: "Ab Clone Format", key: "ab_clone_format" },
  { label: "Animal Species", key: "animal_species" },
];

const CHARTS = {
  AASC: {
    path: "amino-acid-stack-columns",
    title: "Amino Acid Stack Columns",
    tsv_suffix: "imgt-bar-chart.tsv",
    description: "Stack columns visualize amino acid composition.",
  },
  CDR3RT: {
    path: "cdr3-radial-tree",
    title: "CDR3 Radial Tree",
    tsv_suffix: "cdr3_interactive_radial_tree.html",
    description:
      "Cladogram of CDR3 sequences in top 10 clones. Clones with clone size greater than 5 are highlighted, with different color representing clone size.",
  },
  CDR3RN: {
    path: "cdr3-radial-network",
    title: "CDR3 Radial Network",
    tsv_suffix: "cdr3_interactive_radial_networkx.html",
    description:
      "Radial network based on dissimilarity of CDR3 sequences. The center node (root) represents the consensus CDR3 sequence, and each cluster of nodes represent sequences within each clone. Distance between any two nodes is based on dissimilarity of the two CDR3 sequences.",
  },
  VJR: {
    path: "vj-recombination",
    title: "V-J Recombination",
    tsv_suffix: "vj-recombination.tsv",
    description: "Plot showing combinations of V and J genes.",
  },
  CDRH3O: {
    path: "cdrh3-overlap",
    title: "CDRH3 Overlap",
    tsv_suffix: "cdrh3-overlap.tsv",
    description:
      "This section visualizes overlap between files on CDRH3 amino acid level. Overlap is calculated as number of unique CDR3s common divided by number of total unique CDR3s. Scaling is performed on log10 of the (overlap +1), at permille scale (i.e. x1000). ",
    minimum_samples: 2,
  },
  RO: {
    path: "repertoire-overlap",
    title: "Repertoire Overlap",
    tsv_suffix: "repertoire-overlap.tsv",
    description: "This graph shows shared clonotypes per sample",
    minimum_samples: 2,
  },
};

const FILE_STATES = {
  AVAILABLE: 1,
  RESTORED: 2,
  RESTORE_ONGOING: 3,
  ARCHIVED: 4,
  ACCESS_DENIED: 5,
  DOES_NOT_EXIST: 6,
};

const FILE_ERROR_MESSAGES = {
  [FILE_STATES.ARCHIVED]: "File is archived",
  [FILE_STATES.DOES_NOT_EXIST]: "File no longer available",
  [FILE_STATES.RESTORE_ONGOING]: "File is being restored",
  [FILE_STATES.ACCESS_DENIED]: "You don't have permission to access this file",
};

export { PIPELINES, METADATA_FIELDS, CHARTS, FILE_STATES, FILE_ERROR_MESSAGES };
