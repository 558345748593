const LtAIRRDescrShort = () => {
  return (
    <p>
      The low-throughput sequencing pipeline is a bioinformatics workflow used
      to process chromatogram files from Sanger sequencing and characterize the
      complementary determining region (CDR) of B-cell receptors (BCRs). It
      provides an overview of the clonotype contents, amino acid properties, and
      gene usage.
    </p>
  );
};

const LtAIRROverview = () => {
  return (
    <div>
      <p>
        The low-throughput sequencing pipeline is a bioinformatics workflow used
        to process chromatogram files from Sanger sequencing and characterize
        the complementary determining region (CDR) of B-cell receptors (BCRs).
        It provides an overview of the clonotype contents, amino acid
        properties, and gene usage.
      </p>
      <p>
        The workflow processes raw data from chromatogram files, aligns the
        sequences to the germline database, and performs immuno-profiling of the
        samples. The pre-processing workflow reads “.ab1” chromatogram files,
        trims low-quality regions, builds consensus sequences, and aligns the
        sequences to the germline reference of choice. The post-processing
        workflow executes a set of analyses to provide basic characteristics and
        insights on the immune repertoire. Resulting data is provided in the
        standardized AIRR tsv format for further downstream analyses of
        interest.
      </p>
    </div>
  );
};

const LtAIRRDescr = () => {
  return (
    <div>
      <div>
        <p>
          The low-throughput sequencing pipeline is a bioinformatics workflow
          used to process chromatogram files from Sanger sequencing and
          characterize the complementary determining region (CDR) of B-cell
          receptors (BCRs). It provides an overview of the clonotype contents,
          amino acid properties, and gene usage.
        </p>
        <p>
          The workflow processes raw data from chromatogram files, aligns the
          sequences to the germline database, and performs immuno-profiling of
          the samples. The pre-processing workflow reads “.ab1” chromatogram
          files, trims low-quality regions, builds consensus sequences, and
          aligns the sequences to the germline reference of choice. The
          post-processing workflow executes a set of analyses to provide basic
          characteristics and insights on the immune repertoire. Resulting data
          is provided in the standardized AIRR tsv format for further downstream
          analyses of interest.
        </p>
      </div>
      <div>
        <h4>Processing steps</h4>
        <ul>
          <li>
            <span>1</span>
            <div>
              <h5>Input</h5>
              <p>Chromatogram files in the “.ab1” format</p>
            </div>
          </li>
          <li>
            <span>2</span>
            <div>
              <h5>Assembly</h5>
              <p>
                The cognate reads of the three files are assembled into a single
                sequence
              </p>
            </div>
          </li>
          <li>
            <span>3</span>
            <div>
              <h5>Primer Masking</h5>
              <p>The PRC primers are identified and masked</p>
            </div>
          </li>
          <li>
            <span>4</span>
            <div>
              <h5>Alignment</h5>
              <p>Aligns the sequences to the germline reference of choice</p>
            </div>
          </li>
          <li>
            <span>5</span>
            <div>
              <h5>Clonal Assignment</h5>
              <p>Unsupervised spectral clustering approach</p>
            </div>
          </li>
          <li>
            <span>6</span>
            <div>
              <h5>Germline Reconstruction</h5>
              <p>Reconstructs the germline V(D)J sequence</p>
            </div>
          </li>
          <li>
            <span>7</span>
            <div>
              <h5>Amino Acid Properties</h5>
              <p>
                Analyzes the physiochemical properties of Ig amino acid
                sequences
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LtAIRRDescrShort, LtAIRRDescr, LtAIRROverview };
