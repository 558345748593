import { useEffect, useMemo, useState } from "react";
import PrettyBytes from "pretty-bytes";
import { useStorageApi } from "apis";
import { useIsMounted } from "hooks";
import {
  BreadCrumbs,
  Card,
  IconButton,
  Loader,
  SimpleTable,
} from "components/common";
import { GrDocument, GrDownload, GrFolder, GrView } from "react-icons/gr";
import { getDatetimeFormatted } from "utils";

const OutputFiles = ({ experiment, setErrors }) => {
  const storageApi = useStorageApi();
  const isMounted = useIsMounted();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [curPath, setCurPath] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Filename",
        accessor: "filename",
        id: "name",
        Cell: ({ row }) =>
          row.original.is_folder ? (
            <>
              <GrFolder className="me-2" />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setCurPath(`${curPath}${row.original.filename}`);
                }}
              >
                {row.original.filename}
              </a>
            </>
          ) : (
            <span>
              <GrDocument className="me-2" />
              {row.original.filename}
            </span>
          ),
      },
      {
        Header: "Modified",
        accessor: "last_modified",
        Cell: ({ row, value }) =>
          row.original.is_folder ? null : value && getDatetimeFormatted(value),
      },
      {
        Header: "Size",
        accessor: "size",
        Cell: ({ value }) => (value ? PrettyBytes(value) : null),
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.filename.endsWith(".html") && (
                <IconButton
                  title="Preview"
                  icon={<GrView />}
                  className="preview-btn"
                  onClick={() =>
                    storageApi
                      .getPreviewUrl(row.original)
                      .then((response) =>
                        window.open(response.data.url, "_blank")
                      )
                      .catch(
                        (error) => isMounted() && setErrors(error.response.data)
                      )
                  }
                ></IconButton>
              )}
              {!row.original.is_folder && (
                <IconButton
                  title="Download"
                  icon={<GrDownload />}
                  onClick={() =>
                    storageApi
                      .getDownloadUrl(row.original)
                      .then((response) =>
                        window.open(response.data.url, "_blank")
                      )
                      .catch(
                        (error) => isMounted() && setErrors(error.response.data)
                      )
                  }
                ></IconButton>
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [curPath]
  );

  useEffect(() => {
    if (["ERROR", "FAILED", "COMPLETED"].includes(experiment.status_pipeline)) {
      setIsLoading(true);

      storageApi
        .list("output", `${experiment.identifier}/${curPath}`)
        .then((response) => isMounted() && setFiles(response.data))
        .catch((error) => isMounted() && setErrors(error.response.data))
        .finally(() => isMounted() && setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPath]);

  return (
    <Card title="Output Files">
      <div className="mt-2 mb-3">
        <BreadCrumbs path={curPath} onCrumbClicked={setCurPath}></BreadCrumbs>
      </div>
      <SimpleTable columns={columns} data={files}></SimpleTable>
      <Loader isLoading={isLoading} />
    </Card>
  );
};

export default OutputFiles;
