import { ListGroup } from "react-bootstrap";
import { get } from "lodash";

import { Card } from "components/common";
import { PIPELINES } from "config";

const Params = ({ experiment }) => {
  const params = PIPELINES[experiment.pipeline].config.params;

  return (
    <Card title="Setup" className="h-100">
      <ListGroup variant="flush">
        {params
          .filter((param) => !param.hidden)
          .map((param) => {
            let value = get(experiment.setup_params, param.key);

            if (typeof value === "boolean") {
              value = param.choices.find(
                (choice) => choice.value === value
              ).name;
              //  double '=' iso 3 makes sure 'undefined' also matches
            } else if (value == null) {
              value = "/";
            }
            return (
              <ListGroup.Item key={param.key} className="px-2">
                <h6 className="fw-bold">{param.label}</h6>
                <p className="mb-0">{value}</p>
              </ListGroup.Item>
            );
          })}
        <ListGroup.Item className="px-2">
          <h6 className="fw-bold">Pipeline Version</h6>
          <p className="mb-0">{experiment.pipeline_version}</p>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default Params;
