import { useVisualizationApi } from "apis";
import {
  Card,
  ConfirmDelete,
  ErrorModal,
  IconButton,
  Loader,
} from "components/common";
import MainWrapper from "components/Main";
import { useIsMounted, useUser } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { GrRefresh } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import VisualizationsGroupFilters from "./VisualizationsGroupFilters";
import VisualizationsGroupSubTables from "./VisualizationsGroupSubTable";
import VisualizationsGroupTable from "./VisualizationsGroupTable";

const Visualizations = () => {
  const visualizationsApi = useVisualizationApi();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const tableRef = useRef();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [visualizations, setVisualizations] = useState([]);
  const [visualizationToDelete, setVisualizationToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filters, setFilters] = useState({});

  const resetDeleteModal = () => {
    setShowConfirmDelete(false);
    setVisualizationToDelete(null);
    setDeleteErrors(null);
  };

  const deleteVisualization = () => {
    setIsDeleting(true);
    setDeleteErrors(null);
    visualizationsApi
      .delete(visualizationToDelete.identifier)
      .then(() => {
        resetDeleteModal();
        getVisualizations();
      })
      .catch((error) => setErrors(error.response.data))
      .finally(() => {
        getVisualizations();
        setIsDeleting(false);
      });
  };

  const getVisualizations = useCallback(
    (page, pageSize, sortBy) => {
      setIsLoading(true);
      if (!page || !pageSize || !sortBy) {
        page = tableRef.current.getPage();
        pageSize = tableRef.current.getPageSize();
        sortBy = tableRef.current.getSortBy();
      }

      visualizationsApi
        .listVisualizations(page, pageSize, filters, sortBy)
        .then((response) => {
          if (isMounted()) {
            setVisualizations(response.data.results);
            setPageCount(Math.ceil(response.data.count / pageSize));
            setTotalRowCount(response.data.count);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            tableRef.current.resetPage();
          } else {
            setErrors(error.response.data);
            setShowErrors(true);
          }
        })
        .finally(() => isMounted() && setIsLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visualizationsApi, isMounted, filters]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getVisualizations, [filters]);

  return (
    <MainWrapper title="Visualizations" contentClass="visualizations">
      <Row>
        <Col>
          <Card
            title="Visualizations"
            actions={
              <IconButton
                title="Refresh"
                icon={<GrRefresh />}
                onClick={getVisualizations}
              ></IconButton>
            }
          >
            <VisualizationsGroupFilters
              filters={filters}
              setFilters={setFilters}
              setErrors={(errors) => {
                setErrors(errors);
                setShowErrors(true);
              }}
            ></VisualizationsGroupFilters>

            <VisualizationsGroupTable
              data={visualizations}
              onDelete={(experiment) => {
                setVisualizationToDelete(experiment);
                setShowConfirmDelete(true);
              }}
              getVisualizations={getVisualizations}
              tableRef={tableRef}
              pageCount={pageCount}
              totalRowCount={totalRowCount}
              user={user}
            >
              <VisualizationsGroupSubTables
                data={visualizations}
                onNavigate={navigate}
              ></VisualizationsGroupSubTables>
            </VisualizationsGroupTable>
            <Loader isLoading={isLoading} topSpinner />
          </Card>
        </Col>
      </Row>

      {showConfirmDelete && (
        <ConfirmDelete
          name={visualizationToDelete.identifier}
          resourceType="Visualization"
          isLoading={isDeleting}
          errors={deleteErrors}
          onCancel={resetDeleteModal}
          onConfirm={(e) => {
            e.preventDefault();
            deleteVisualization();
          }}
        />
      )}

      {showErrors && (
        <ErrorModal
          errors={errors}
          onClose={() => {
            setErrors(null);
            setShowErrors(false);
          }}
        ></ErrorModal>
      )}
    </MainWrapper>
  );
};

export default Visualizations;
